import React, {Component} from 'react'
import {ReactComponent as ClipImg} from "../../assets/img/icon/clip-ico.svg";
import {ReactComponent as CommentImg} from "../../assets/img/icon/comment-ico.svg";
import Man from "../../assets/img/man.jpg";
import './ProjectCard.css';
import {NavLink} from "react-router-dom";
import Button from '@material-ui/core/Button';
import {FormattedMessage} from "react-intl";


class ProjectCard extends Component {

  state = {
    isOpenSubtask: false,
  }

  componentDidMount() {

  }

  handleOpenSubtask = () => {
    console.log(123);
    this.setState({
      isOpenSubtask: !this.state.isOpenSubtask,
    })
  }

  infoTask = () => {
    this.props.infoHandler(1)
  }

  doneTask = () => {
    let data = {
      new_status: 'COMPLETED'
    };
    this.props.performTask(this.props.item.id, data)
  }

  inActiveTask = () => {
    let data = {
      new_status: 'ACTIVE'
    };
    this.props.performTask(this.props.item.id, data)
  }


  render() {
    // this.state.nameTask, this.state.descTask, this.state.createTask, this.state.employeeTask, this.state.statusTask


    return (
      <div className="col-md-4">
        <div className="projects-card__i">
          <div className="projects-card__i-content">
            <span className="projects-card__i-name" onClick={this.infoTask}>
              {this.props.item.name}
            </span>
            <div className="projects-card__i-description">
              {this.props.item.limited_description}
            </div>
            <div className="projects-card__i-row">
              <div className="projects-card__i-row-time">
                <div className="projects-card__i-row-time-i">
                  <span><FormattedMessage id="app.taskSet"/>:</span> {this.props.item.task_start}
                </div>
                <div className="projects-card__i-row-time-i">
                  <span><FormattedMessage id="app.deadline"/>:</span> {this.props.item.task_end}
                </div>
              </div>


            </div>
            <div className="projects-card__i-footer">
              {this.props.completed ?
                <div className="projects-card__i-btn">
                  <Button variant="contained" color="primary" className={"projects-card__i-btn-done"}
                          onClick={this.inActiveTask}>
                    <FormattedMessage id="app.inActive"/>

                  </Button>
                </div>
                :
                null
              }
              {this.props.draft ?
                <div className="projects-card__i-btn">
                  <Button variant="contained" color="primary" className={"projects-card__i-btn-done"}
                          onClick={this.inActiveTask}>
                    <FormattedMessage id="app.inActive"/>

                  </Button>
                </div>
                :
                null
              }
              {this.props.active ?
                <div className="projects-card__i-btn">
                  <Button variant="contained" color="primary" className={"projects-card__i-btn-done"}
                          onClick={this.doneTask}>
                    <FormattedMessage id="app.performed"/>

                  </Button>
                </div>
                :
                null
              }
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default ProjectCard
