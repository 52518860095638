import {
  FETCH_DRAFTS_START,
  FETCH_DRAFTS_SUCCESS,
  FETCH_DRAFTS_ERROR
} from "../action/actionTypes";



const initialState = {
  drafts: [],
  loading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DRAFTS_START:
      return{
        ...state, loading: true
      }

    case FETCH_DRAFTS_SUCCESS:
      return {
        ...state, loading: false, drafts: action.drafts
      }

    case FETCH_DRAFTS_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    default: return state
  }
}
