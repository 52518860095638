import {
  FETCH_PROJECTS_ERROR,
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_COMPLETED_SUCCESS,
  FETCH_PROJECTS_SUCCESS
} from "../action/actionTypes";

const initialState = {
  projects: [],
  completed: [],
  loading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECTS_START:
      return{
        ...state, loading: true
      }

    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state, loading: false, projects: action.projects
      }


    case FETCH_PROJECTS_COMPLETED_SUCCESS:
      return {
        ...state, loading: false, completed: action.completed
      }

    case FETCH_PROJECTS_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    default: return state
  }
}
