import React, {Component} from 'react'
import './AddServicePopup.css'

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";
import {fetchClientCarsBrand} from "../../../store/action/clients";
import connect from "react-redux/es/connect/connect";
import {createMaster, createService, editService} from "../../../store/action/settings";
import {editMaster} from "../../../store/action/settings";
import {logout} from "../../../store/action/auth";
import {openMenu} from "../../../store/action/main";
import {fieldEmpty, fieldNumbers} from "../../../sevices/validationService";


class AddServicePopup extends Component {

  state = {
    name: '',
    price: '',

    validation: {
      name: false,
      price: false,
    }
  }


  handleClose = () => {
    this.props.handleClose();
  }

  handlerSaveForm = () => {

    let dataForm = {
      name: this.state.name,
      price: this.state.price,
    }

    let validation = fieldEmpty(dataForm);
    let validationNum = fieldNumbers(dataForm.price);

    if (!validation.isValidation || !validationNum) {

      if(!validationNum)
        validation.validation.price = true;

      this.setState({
        validation: validation.validation
      });
      return;
    }

    this.props.createService(dataForm).then((response) => {
      if (response.status == 200) {
        this.props.handleClose();
        this.props.getService();
      }
    });
  }

  handlerEditForm = () => {

    let dataForm = {
      name: this.refs.masterName.getElementsByTagName('input')[0].value,
      price: this.refs.masterPrice.getElementsByTagName('input')[0].value,
    }

    let validation = fieldEmpty(dataForm);
    let validationNum = fieldNumbers(dataForm.price);

    if (!validation.isValidation || !validationNum) {

      if(!validationNum)
        validation.validation.price = true;

      this.setState({
        validation: validation.validation
      });
      return;
    }

    this.props.editService(dataForm, this.props.service[this.props.index].id).then((response) => {
      if (response.status == 200) {
        this.props.handleClose();
        this.props.getService();
      }
    });
  }


  render() {

    return (


      <div className="modal-popup__window container">
        <div className="modal-popup__header">
                <span>
                  {this.props.isEdit ? <FormattedMessage id="app.edit"/> : <FormattedMessage id="app.worksType"/>}
                </span>
        </div>
        <div className="modal-popup__body">
          <div className="waybills-popup__inner">
            {this.props.isEdit ?
              <React.Fragment>


                <div className="row">
                  <div className="col-12">

                    <div className="row">
                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"}
                                   defaultValue={this.props.service[this.props.index].name}
                                   error={this.state.validation.name}
                                   inputProps={{'data-name': 'name'}}
                                   onChange={(event) => this.setState({name: event.target.value})}
                                   ref={'masterName'}
                                   label={<FormattedMessage id="app.name"/>}/>
                      </div>

                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"}
                                   defaultValue={this.props.service[this.props.index].price}
                                   error={this.state.validation.price}
                                   onChange={(event) => this.setState({price: event.target.value})}
                                   ref={'masterPrice'}
                                   label={<FormattedMessage id="app.price"/>}/>

                      </div>

                    </div>
                  </div>

                </div>


              </React.Fragment>

              :

              <React.Fragment>
                <div className="row">
                  <div className="col-12">

                    <div className="row">
                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"}
                                   value={this.state.name}
                                   error={this.state.validation.name}
                                   inputProps={{'data-name': 'name'}}
                                   onChange={(event) => this.setState({name: event.target.value})}
                                   label={<FormattedMessage id="app.name"/>}/>
                      </div>

                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"}
                                   value={this.state.price}
                                   error={this.state.validation.price}
                                   inputProps={{'data-name': 'lastName'}}
                                   onChange={(event) => this.setState({price: event.target.value})}
                                   label={<FormattedMessage id="app.price"/>}/>

                      </div>

                    </div>
                  </div>

                </div>

              </React.Fragment>
            }


          </div>

          <div className="modal-popup__footer">
            <div className="modal-popup__btn">
              <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={this.handleClose}>
                <FormattedMessage id="app.close"/>
              </Button>

              {this.props.isEdit ?
                <Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                        onClick={this.handlerEditForm}>
                  <FormattedMessage id="app.change"/>
                </Button>

                :

                < Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                         onClick={this.handlerSaveForm}>
                  <FormattedMessage id="app.save"/>
                </Button>
              }
            </div>
          </div>

        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    service: state.settings.service,
  }
}

function mapDispathToProps(dispath) {
  return {
    createService: (data) => dispath(createService(data)),
    editService: (data, id) => dispath(editService(data, id))
  }
}


export default connect(mapStateToProps, mapDispathToProps)(AddServicePopup);

