import {AUTH_LOGOUT, AUTH_SUCCESS, AUTH_USER_DATA, AUTH_USER_ID, FETCH_APPOINTMENT_ERROR,} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";
import axios from 'axios'

export function newPass(data, isLogin) {
  return async dispatch => {

    try {
      const response = await API.post('/password/reset', data );

      localStorage.setItem('token', response.data.token)

      const response2 = await API.get(`users/current/show`, {headers: { Authorization: `Bearer ${response.data.token}` }});

      authID(response2.data.id);
      userData(response2.data);
      dispatch(authSuccess(response.data.token))
      // return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function auth(email, password, isLogin) {
  return async dispatch => {

    const authData = {
      email, password
    }

    try {
      const response = await API.post('oauth/login', authData );

      localStorage.setItem('token', response.data.token)

      const response2 = await API.get(`users/current/show`, {headers: { Authorization: `Bearer ${response.data.token}` }});

      authID(response2.data.id);
      userData(response2.data);
      dispatch(authSuccess(response.data.token))
      // return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function getCurrentUser() {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.get(`users/current/show`, {headers: { Authorization: `Bearer ${token}` }});

      dispatch(userData(response.data));
    } catch (e) {

      console.log(fetchUserError(e));
      let err = fetchUserError(e);
      if(err.error.response.data.message === 'Unauthenticated.') {

        dispatch(logout());
      }
    }
  }
}

export function getUser() {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.get(`users/get-user`, {headers: { Authorization: `Bearer ${token}` }});
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  }
}

export function fetchUserError(e) {
  return {
    type: FETCH_APPOINTMENT_ERROR,
    error: e
  }
}


export function forgot(data) {
  return async dispatch => {
    let response;
    try {
      const token = localStorage.getItem('token');
      response = await API.post(`password/email`, data, {headers: { Authorization: `Bearer ${token}` }});

      return response;
    } catch (e) {
      console.log(e);
    }
  }
}

export function logout() {
  localStorage.removeItem('token')
  return{
    type: AUTH_LOGOUT
  }
}

export function autoLogin() {
  return dispatch => {
    const token = localStorage.getItem('token')
    if(!token) {
      dispatch(logout())
      return false
    } else {
      dispatch(authSuccess('token'))
      return true
    }
  }
}

export function authSuccess(token) {
  return{
    type: AUTH_SUCCESS,
    token
  }
}

export function authID(id) {
  return{
    type: AUTH_USER_ID,
    id
  }
}

export function userData(userData) {
  return{
    type: AUTH_USER_DATA,
    userData
  }
}
