
export function getRecipientType(recipientId) {

  switch (recipientId) {
    case 1:
      return 'Наличные'
    case 2:
      return 'Безнал'
    case 3:
      return 'Безнал'
  }

}

export function getRecipienName(recipients, recipientId) {

  let recipientsReturn = '';
  recipients.map((item) => {
    if (item.id === recipientId) {
      recipientsReturn = item.name
    }
  })

  return recipientsReturn;

}
