import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import {NavLink, withRouter} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import AddBillsItemPopup from "../../components/ModalPopups/AddBillsItemPopup/AddBillsItemPopup";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import WaybillsPopup from "../../components/ModalPopups/WaybillsPopup/WaybillsPopup";
import Autocomplete from '@material-ui/lab/Autocomplete';
import './Outfits.css'
import TableHeader from "../../components/Tables/TableHeader/TableHeader.js";
import {fetchClients} from "../../store/action/clients";
import connect from "react-redux/es/connect/connect";
import {
  deleteOutfits,
  fetchOutfits,
  fetchOutfitsCashPDF,
  fetchOutfitsPDF,
  performOutfits
} from "../../store/action/outfits";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import WaybillsPopupComplete from "../../components/ModalPopups/WaybillsPopup/WaybillsPopupComplete";



class Outfits extends Component {

  state = {
    isOpen: false,
    isOpenNoEdit: false,
    isEdit: false,
    perPage: 30,
    currentPage: 1,
    search: '',
    dateStart: '',
    dateEnd: '',
    editIndex: '',
    alert: false,
    alert2: false,
  }

  componentDidMount() {
    // this.props.fetchOutfits()
    this.fetchDataCreate();

    document.title = this.props.intl.formatMessage({
      id: 'app.outfits',
    });
  }

  reRender = () => {
    this.fetchDataCreate()
  }

  addBillsItemHandle = () => {
    this.setState({
      isOpen: true
    })
  }
  closePopupAddBillsItem = () => {
    this.setState({
      isOpen: false,
      isOpenNoEdit: false,
    })
    this.setState({isEdit: false})
  }

  handleDetail = (index) => {
    this.setState({editIndex: index})
    this.setState({isEdit: true})
    this.setState({isOpen: true})
  }

  handleDetailNoEdit = (index) => {
    this.setState({editIndex: index})
    this.setState({isEdit: true})
    this.setState({isOpenNoEdit: true})
  }

  handleRemove = (id) => {
    this.props.deleteOutfits(id).then((response) => {
      console.log(response);
      this.fetchDataCreate()
    });
  }

  handlePerform = (id) => {
    this.props.performOutfits(id).then((response) => {
      if(response.hasOwnProperty('data') && response.data.hasOwnProperty('error')){
        if(response.data.status === 'failed'){
          this.setState({alert2: true})
        } else if(response.data.status === 'Stock_Error') {
          this.setState({alert3: true})
        } else {
          this.setState({alert: true})
        }
        return;
      }
      console.log(response);
      this.fetchDataCreate();
    });
  }

  fetchDataCreate = (filter = false) => {
    let data = '',
      curPage = this.state.currentPage,
      perPage = this.state.perPage,
      dateStart = this.state.dateStart !== '' ? this.state.dateStart : null,
      dateEnd = this.state.dateEnd !== '' ? this.state.dateEnd : null,
      search = this.state.search !== '' ? this.state.search : null;

    if(filter) {
      curPage = 1;
      this.setState({currentPage: 1});
    }

    data = data + `?page=${curPage}&limit=${perPage}`;

    // between=created_at,2020-09-16:2020-09-16
    let date = '';

    if (dateStart !== null) date = date + `&between=data_start,${dateStart}`;

    if (dateEnd !== null){
      if(dateStart === null){
        date = date + `&between=data_start,:${dateEnd}`;
      } else {
        date = date + `:${dateEnd}`;
      }
    }

    data = data + date;

    if (search !== null) data = data + `&filterByRelation=first_name:${search}&filterByRelationCar=brand:${search}`;

    this.props.fetchOutfits(data);
  }

  filterReset = () => {
    this.setState({
      search: '',
      dateStart: '',
      dateEnd: ''
    })

    setTimeout(() => this.fetchDataCreate( true))
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.fetchDataCreate(true);
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ currentPage: newPage + 1 })
    setTimeout(() => this.fetchDataCreate())
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ perPage: parseInt(event.target.value, 10) })
    this.setState({ currentPage: 1 })
    setTimeout(() => this.fetchDataCreate())
  }


  handlePrintCash = (id) => {
    this.props.fetchOutfitsCashPDF(id).then((response) => {
      console.log(response);
    })
  }

  handlePrint = (id) => {
    this.props.fetchOutfitsPDF(id).then((response) => {
      console.log(response);
    })
  }

  render() {
    // console.log(this.props.match.params.id);
    const top100Films = [
      { title: 'Олег Побережный' },
      { title: 'The Godfather'},
      { title: 'The Godfather: Part II'},
      { title: 'The Dark Knight' },
    ];

    let headerTable = [
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.id"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.orderDate"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.orderExecutionDate"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.clientName"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.carBrand"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.status"/>
      },
      {
        colspan: '0',
        align: 'right',
        item: ''
      },
    ];

    let outfitsCount = 0;

    return (
      <div className="waybills">
        <div className="page-title">
           <span className={'header__name'}>
                <FormattedMessage id="app.outfits"/>
            </span>
        </div>

        <div>
          <Collapse in={this.state.alert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({alert: false})
                  }}
                >
                  <FormattedMessage id="app.close"/>
                </IconButton>
              }
            >

             Наряд можна закрывать только сегодняшним днем (измените дату)
            </Alert>
          </Collapse>
        </div>
        <div>
          <Collapse in={this.state.alert2}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({alert2: false})
                  }}
                >
                  <FormattedMessage id="app.close"/>
                </IconButton>
              }
            >

             Мастер добавленый в этот наряд уже удален, измените мастера
            </Alert>
          </Collapse>
        </div>
        <div>
          <Collapse in={this.state.alert3}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({alert3: false})
                  }}
                >
                  <FormattedMessage id="app.close"/>
                </IconButton>
              }
            >

             Невхатает одного из товаров на складе для закрития наряда
            </Alert>
          </Collapse>
        </div>

        <div className="filter-head">

          <div className="filter-head__left">
            <div className="site-filter">
              {/*<span><FormattedMessage id="app.selectDate"/></span>*/}
              <TextField
                type="date"
                value={this.state.dateStart}
                label={<FormattedMessage id="app.selectDateFrom"/>}
                onChange={(event)=>{ this.setState({dateStart: event.target.value})}}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type="date"
                label={<FormattedMessage id="app.selectDateFor"/>}
                value={this.state.dateEnd}
                onChange={(event)=>{ this.setState({dateEnd: event.target.value})}}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>


            <div className="filter-client-wrap">
              <TextField
                className="filter-client-select"
                value={this.state.search}
                onChange={(event) => this.setState({search: event.target.value})}
                size="small"
                label={<FormattedMessage id="app.search"/> }
                onKeyDown={this._handleKeyDown}
                variant="outlined"/>
            </div>

            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.filterReset}>
              <FormattedMessage id="app.reset"/>
            </Button>
            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={() => this.fetchDataCreate( true)}>
              <FormattedMessage id="app.search"/>
            </Button>
          </div>



          <Button variant="contained" className="yellow-btn recording__add-btn"  onClick={this.addBillsItemHandle}>
            <FormattedMessage id="app.addOutfits"/>
          </Button>

          {this.state.isOpen ?
            <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopupAddBillsItem}>
              <WaybillsPopup isEdit={this.state.isEdit} index={this.state.editIndex} reRender={this.reRender} />
            </PopupWrapper>
            : ''}

          {this.state.isOpenNoEdit ?
            <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopupAddBillsItem}>
              <WaybillsPopupComplete isEdit={this.state.isEdit} index={this.state.editIndex} reRender={this.reRender} />
            </PopupWrapper>
            : ''}
        </div>
        <div className="page-section">
          <div className="page-section__content">
            <TableContainer className={'recording-table' + (this.props.loading ? ' loading-table' : '')}>
              <Table size="small" aria-label="a dense table">
                <TableHeader rows={headerTable}/>
                <TableBody>

                  {
                    this.props.outfits.hasOwnProperty('data') ?
                    Object.keys(this.props.outfits.data).map((item, index) => {

                    if(this.props.outfits.data[item].client === null || this.props.outfits.data[item].state === 'DELETED') return;
                    outfitsCount = outfitsCount + 1;
                    return (
                      <TableRow className={'site-table-row'} key={index}>
                        <TableCell align="left"> { this.props.outfits.data[item].id } </TableCell>
                        <TableCell align="left"> { this.props.outfits.data[item].data_start.substr(0,this.props.outfits.data[item].data_start.indexOf("T")) } </TableCell>
                        <TableCell align="left"> { this.props.outfits.data[item].data_end.substr(0,this.props.outfits.data[item].data_end.indexOf("T")) } </TableCell>
                        <TableCell align="left"> { this.props.outfits.data[item].client.first_name } { this.props.outfits.data[item].client.last_name } </TableCell>
                        <TableCell align="left"> { this.props.outfits.data[item].car.brand } { this.props.outfits.data[item].car.model } </TableCell>
                        <TableCell align="left"> { this.props.outfits.data[item].state === "COMPLETED" ?  <FormattedMessage id="app.statusCompleted"/> : null }
                                                 { this.props.outfits.data[item].state === "DRAFT" ? <FormattedMessage id="app.statusDraft"/> : null }
                                                 { this.props.outfits.data[item].state === "DELETED" ? <FormattedMessage id="app.statusDeleted"/> : null }
                        </TableCell>
                        <TableCell align="right">
                          <React.Fragment>
                            {this.props.outfits.data[item].client.payment_type === 'CASH' ?
                              <Button variant="contained"  color="secondary" size="small" className={"modal-popup__btn-i outfit-remove-btn"}
                                      onClick={() => this.handlePrintCash(this.props.outfits.data[item].id)}>
                                <FormattedMessage id="app.print"/>
                              </Button>
                              :
                              <Button variant="contained" color="secondary" size="small" className={"modal-popup__btn-i outfit-remove-btn"}
                                      onClick={() => this.handlePrint(this.props.outfits.data[item].id)}>
                                <FormattedMessage id="app.print"/>
                              </Button>
                            }
                          </React.Fragment>

                          {this.props.outfits.data[item].state === 'DRAFT'?
                            <Button variant="contained" className={'outfit-remove-btn'} color="primary" size="small" onClick={() => {this.handlePerform(this.props.outfits.data[item].id)}}>
                              <FormattedMessage id="app.perform"/>
                            </Button>
                          :
                            null
                          }


                          {this.props.outfits.data[item].state === 'COMPLETED' ?
                            <Button variant="contained" color="primary" size="small" onClick={() => {
                              this.handleDetailNoEdit(index)
                            }}>
                              <FormattedMessage id="app.detail"/>
                            </Button>
                            :
                            <React.Fragment>
                              <Button variant="contained" className={'outfit-remove-btn'} color="primary" size="small" onClick={() => {this.handleRemove(this.props.outfits.data[item].id)}}>
                                <FormattedMessage id="app.remove"/>
                              </Button>
                              <Button variant="contained" color="primary" size="small" onClick={() => {
                                this.handleDetail(index)
                              }}>
                                <FormattedMessage id="app.detail"/>
                              </Button>
                            </React.Fragment>

                          }
                        </TableCell>
                      </TableRow>
                    )
                  })
                  :
                  null

                  }

                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 30, 50, 100]}
                      colSpan={8}
                      count={this.props.outfits.total}
                      rowsPerPage={this.state.perPage}
                      page={this.state.currentPage - 1}
                      labelRowsPerPage={<FormattedMessage id="app.rowsPerPage"/>}
                      SelectProps={{
                        inputProps: {'aria-label': 'rows per page'},
                        native: true,
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>

              </Table>
            </TableContainer>
          </div>
        </div>


      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    outfits: state.outfits.outfits,
    loading: state.outfits.loading,
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchOutfits: (data) => dispath(fetchOutfits(data)),
    deleteOutfits: (id) => dispath(deleteOutfits(id)),
    performOutfits: (id) => dispath(performOutfits(id)),
    fetchOutfitsCashPDF: (id) => dispath(fetchOutfitsCashPDF(id)),
    fetchOutfitsPDF: (id) => dispath(fetchOutfitsPDF(id)),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(withRouter(injectIntl(Outfits)));
