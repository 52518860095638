export const MAIN_ACTION = 'MAIN_ACTION'

export const SET_BREADCRUBMBS = 'SET_BREADCRUBMBS'
export const FETCH_BREADCRUBMBS_SUCCESS = 'FETCH_BREADCRUBMBS_SUCCESS'
export const FETCH_BREADCRUBMBS_ERROR = 'FETCH_BREADCRUBMBS_ERROR'

export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_USER_ID = 'AUTH_USER_ID'
export const AUTH_USER_DATA = 'AUTH_USER_DATA'

export const OPEN_MENU = 'OPEN_MENU'
export const CLOSE_MENU = 'CLOSE_MENU'


export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START'
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_COMPLETED_SUCCESS = 'FETCH_PROJECTS_COMPLETED_SUCCESS'
export const FETCH_PROJECTS_ERROR = 'FETCH_PROJECTS_ERROR'



export const FETCH_DRAFTS_START = 'FETCH_DRAFTS_START'
export const FETCH_DRAFTS_SUCCESS = 'FETCH_DRAFTS_SUCCESS'
export const FETCH_DRAFTS_ERROR = 'FETCH_DRAFTS_ERROR'


export const FETCH_EXPENSES_START = 'FETCH_EXPENSES_START'
export const FETCH_EXPENSES_SUCCESS = 'FETCH_EXPENSES_SUCCESS'
export const FETCH_EXPENSES_ERROR = 'FETCH_EXPENSES_ERROR'


export const FETCH_BILLS_START = 'FETCH_BILLS_START'
export const FETCH_BILLS_SUCCESS = 'FETCH_BILLS_SUCCESS'
export const FETCH_BILLS_ERROR = 'FETCH_BILLS_ERROR'



export const FETCH_SUPPLIER_START = 'FETCH_SUPPLIER_START'
export const FETCH_SUPPLIER_SUCCESS = 'FETCH_SUPPLIER_SUCCESS'
export const FETCH_SUPPLIER_ERROR = 'FETCH_SUPPLIER_ERROR'


export const FETCH_MASTERS_START = 'FETCH_MASTERS_START'
export const FETCH_MASTERS_SUCCESS = 'FETCH_MASTERS_SUCCESS'
export const FETCH_MASTERS_ERROR = 'FETCH_MASTERS_ERROR'

export const FETCH_MANAGERS_START = 'FETCH_MANAGERS_START'
export const FETCH_MANAGERS_SUCCESS = 'FETCH_MANAGERS_SUCCESS'
export const FETCH_MANAGERS_ERROR = 'FETCH_MANAGERS_ERROR'


export const FETCH_CLIENTS_START = 'FETCH_CLIENTS_START'
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS'
export const FETCH_CLIENTS_ERROR = 'FETCH_CLIENTS_ERROR'


export const FETCH_CLIENTS_CAR_BRAND = 'FETCH_CLIENTS_CAR_BRAND'
export const FETCH_CLIENTS_CAR_BRAND_ERROR = 'FETCH_CLIENTS_CAR_BRAND_ERROR'

export const FETCH_CLIENTS_CAR_MODELS = 'FETCH_CLIENTS_CAR_MODELS'
export const CLEAR_CLIENTS_CAR_MODELS = 'CLEAR_CLIENTS_CAR_MODELS'
export const FETCH_CLIENTS_CAR_MODELS_ERROR = 'FETCH_CLIENTS_CAR_MODELS_ERROR'



export const FETCH_APPOINTMENT_START = 'FETCH_APPOINTMENT_START'
export const FETCH_APPOINTMENT_SUCCESS = 'FETCH_APPOINTMENT_SUCCESS'
export const FETCH_APPOINTMENT_ERROR = 'FETCH_APPOINTMENT_ERROR'



export const FETCH_OUTFITS_START = 'FETCH_OUTFITS_START'
export const FETCH_OUTFITS_SUCCESS = 'FETCH_OUTFITS_SUCCESS'
export const FETCH_OUTFITS_ERROR = 'FETCH_OUTFITS_ERROR'



export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR'



export const FETCH_WORKERS_STATISTIC_START = 'FETCH_WORKERS_STATISTIC_START'
export const FETCH_WORKERS_STATISTIC_SUCCESS = 'FETCH_WORKERS_STATISTIC_SUCCESS'
export const FETCH_WORKERS_STATISTIC_ERROR = 'FETCH_WORKERS_STATISTIC_ERROR'


export const FETCH_DELIVERY_START = 'FETCH_DELIVERY_START'
export const FETCH_DELIVERY_SUCCESS = 'FETCH_DELIVERY_SUCCESS'

export const FETCH_PRODUCT_WAYBILLS_START = 'FETCH_PRODUCT_WAYBILLS_START'
export const FETCH_PRODUCT_WAYBILLS_SUCCESS = 'FETCH_PRODUCT_WAYBILLS_SUCCESS'
export const FETCH_PRODUCT_WAYBILLS_ERROR = 'FETCH_PRODUCT_WAYBILLS_ERROR'


export const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS'
export const FETCH_SERVCE_ERROR = 'FETCH_SERVCE_ERROR'

export const FETCH_SUPPLEIR_SUCCESS = 'FETCH_SUPPLEIR_SUCCESS'
export const FETCH_SUPPLEIR_ERROR = 'FETCH_SUPPLEIR_ERROR'

export const FETCH_EXPENSES_TYPE_SUCCESS = 'FETCH_EXPENSES_TYPE_SUCCESS'
export const FETCH_EXPENSES_TYPE_ERROR = 'FETCH_EXPENSES_TYPE_ERROR'

export const FETCH_AVATAR_SUCCESS = 'FETCH_AVATAR_SUCCESS'

export const FETCH_WORKERS_SUCCESS = 'FETCH_WORKERS_SUCCESS'

export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS'

export const FETCH_PRODUCTS_ALL_SUCCESS = 'FETCH_PRODUCTS_ALL_SUCCESS'


export const FETCH_WORK_DAYS_START = 'FETCH_WORK_DAYS_START'
export const FETCH_WORK_DAYS_SUCCESS = 'FETCH_WORK_DAYS_SUCCESS'
export const FETCH_WORK_DAYS_ERROR = 'FETCH_WORK_DAYS_ERROR'


export const FETCH_WORK_TODAY_SUCCESS = 'FETCH_WORK_TODAY_SUCCESS'
export const FETCH_WORK_TODAY_ERROR = 'FETCH_WORK_TODAY_ERROR'

export const FETCH_MASTERS_SALARY_SUCCESS = 'FETCH_MASTERS_SALARY_SUCCESS'
export const FETCH_MASTERS_SALARY_ERROR = 'FETCH_MASTERS_SALARY_ERROR'

export const FETCH_MASTERS_SALARY_INFO_SUCCESS = 'FETCH_MASTERS_SALARY_INFO_SUCCESS'
export const FETCH_MASTERS_SALARY_INFO_ERROR = 'FETCH_MASTERS_SALARY_INFO_ERROR'






