import {
  FETCH_MASTERS_START,
  FETCH_MASTERS_SUCCESS,
  FETCH_MASTERS_ERROR,
  FETCH_MANAGERS_START,
  FETCH_MANAGERS_SUCCESS,
  FETCH_MANAGERS_ERROR, FETCH_SERVICE_SUCCESS, FETCH_SERVCE_ERROR, FETCH_SUPPLEIR_ERROR, FETCH_SUPPLIER_SUCCESS,
  FETCH_EXPENSES_TYPE_ERROR, FETCH_EXPENSES_TYPE_SUCCESS,
  FETCH_WORKERS_SUCCESS,
  FETCH_AVATAR_SUCCESS
} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";


// export function createTask(data) {
//   return async dispatch => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await API.post('projects/create', data, {headers: { Authorization: `Bearer ${token}` }});
//       return response;
//     } catch (e) {
//       console.log(e);
//     }
//
//   }
//
// }

export function fetchWorkers() {
  return async dispatch => {
    dispatch(fetchMastersStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get('users/workers', {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchWorkersSuccess(response.data))
    } catch (e) {
      console.log(e);
    }
  }
}

export function fetchWorkersSuccess(workers) {
  return {
    type: FETCH_WORKERS_SUCCESS,
    workers
  }
}


export function fetchMasters() {
  return async dispatch => {
    dispatch(fetchMastersStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get('users/master', {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchMastersSuccess(response.data))
    } catch (e) {
      console.log(fetchMastersError(e));
    }
  }
}

export function fetchMastersStart() {
  return {
    type: FETCH_MASTERS_START
  }
}

export function fetchMastersSuccess(masters) {
  return {
    type: FETCH_MASTERS_SUCCESS,
    masters
  }
}

export function fetchMastersError(e) {
  return {
    type: FETCH_MASTERS_ERROR,
    error: e
  }
}


export function createMaster(data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post('users/store', data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
      return e.response.data
    }
  }

}

export function editMaster(data, id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`users/${id}/update`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
      return e.response.data
    }
  }

}

export function deleteMasters(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.delete(`users/${id}/delete`, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}



export function fetchManagers() {
  return async dispatch => {
    dispatch(fetchManagersStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get('users/manager', {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchManagersSuccess(response.data))
    } catch (e) {
      console.log(fetchManagersError(e));
    }
  }
}

export function fetchManagersStart() {
  return {
    type: FETCH_MANAGERS_START
  }
}

export function fetchManagersSuccess(managers) {
  return {
    type: FETCH_MANAGERS_SUCCESS,
    managers
  }
}

export function fetchManagersError(e) {
  return {
    type: FETCH_MANAGERS_ERROR,
    error: e
  }
}


export function createManager(data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post('users/store', data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
      return e.response.data
    }
  }

}

export function editManager(data, id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`users/${id}/update`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
      return e.response.data
    }
  }

}
export function deleteManager(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.delete(`users/${id}/delete`, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
      return e.response.data
    }
  }

}



export function fetchService() {
  return async dispatch => {
    dispatch(fetchManagersStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get('service/index', {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchServiceSuccess(response.data))
    } catch (e) {
      console.log(fetchServiceError(e));
    }
  }
}

export function fetchServiceSuccess(service) {
  return {
    type: FETCH_SERVICE_SUCCESS,
    service
  }
}

export function fetchServiceError(e) {
  return {
    type: FETCH_SERVCE_ERROR,
    error: e
  }
}


export function createService(data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post('service/store', data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}

export function editService(data, id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.put(`service/update/${id}`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}

export function deleteService(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.delete(`service/delete/${id}`, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}





export function fetchSupplier() {
  return async dispatch => {
    dispatch(fetchManagersStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get('supplier', {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchSupplierSuccess(response.data))
    } catch (e) {
      console.log(fetchSupplierError(e));
    }
  }
}

export function fetchSupplierSuccess(supplier) {
  return {
    type: FETCH_SUPPLIER_SUCCESS,
    supplier
  }
}

export function fetchSupplierError(e) {
  return {
    type: FETCH_SUPPLEIR_ERROR,
    error: e
  }
}


export function createSupplier(data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post('supplier/store', data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}

export function editSupplier(data, id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.put(`supplier/update/${id}`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}

export function deleteSupplier(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.delete(`supplier/delete/${id}`, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}





export function fetchExpensesType() {
  return async dispatch => {
    dispatch(fetchManagersStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get('expenses-type', {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchExpensesSuccess(response.data))
    } catch (e) {
      console.log(fetchExpensesError(e));
    }
  }
}

export function fetchExpensesSuccess(expenses) {
  return {
    type: FETCH_EXPENSES_TYPE_SUCCESS,
    expenses
  }
}

export function fetchExpensesError(e) {
  return {
    type: FETCH_EXPENSES_TYPE_ERROR,
    error: e
  }
}


export function createExpenses(data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post('expenses-type/store', data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}

export function editExpenses(data, id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.put(`expenses-type/${id}/update`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}

export function deleteExpenses(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.delete(`expenses/${id}/delete`, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}




export function editUser(data, id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`users/${id}/update`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }

}



export function showAvatar() {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.get(`avatar/show`, {headers: { Authorization: `Bearer ${token}` }});

      dispatch(fetchAvatarSuccess(response.data))
    } catch (e) {
      console.log(e);
    }
  }

}

export function fetchAvatarSuccess(avatar) {
  return {
    type: FETCH_AVATAR_SUCCESS,
    avatar
  }
}


export function storeAvatar(file) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`avatar/store`, file, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'multipart/form-data',
          'x-requested-with': 'XMLHttpRequest' ,
          Accept: '*/*'
        }
      });

      // let response = await fetch('http://auto-crm-back/api/avatar/store', {
      //   method: 'POST',
      //   body: file,
      //   headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
      // });

      dispatch(fetchAvatarSuccess(response.data))
    } catch (e) {
      console.log(e);
    }
  }

}
