import {
  FETCH_DASHBOARD_SUCCESS
} from "../action/actionTypes";

const initialState = {
  dashboard: {
  },
  loading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state, loading: false, dashboard: action.dashboard
      }

    default: return state
  }
}
