import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import Man from "../../assets/img/man.jpg";
import FormControl from '@material-ui/core/FormControl';
import './Drafts.css';


import Button from '@material-ui/core/Button';
import ProjectCard from "../ProjectCard/ProjectCard";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {fetchDrafts} from "../../store/action/drafts";
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import CreateTaskPopup from "../../components/ModalPopups/CreateTaskPopup/CreateTaskPopup";
import {performTask} from "../../store/action/projects";



class Drafts extends Component {

  state = {
    tabActive: 0,
    isOpenPopup: false,

    taskData: {
      nameTask: '',
      descTask: '',
      createTask: '',
      task_end: '',
      employeeTask: '',
      statusTask: '',
    },

    isEditTask: false

  }

  componentDidMount() {
    this.props.fetchDrafts();

    document.title = this.props.intl.formatMessage({
      id: 'app.bills',
    });
  }

  reRender = () => {
    this.props.fetchDrafts();
  }

  handleTabs = (index) => {
    this.setState({tabActive: index})
  }

  closeHandle = () => {
    this.setState({isOpenPopup: false})
  }

  openHandle = () => {
    this.setState({isEditTask: false})
    this.openPopup()
  }

  getInfoTask = (item) => {
    this.setState({isEditTask: true})
    this.setState({taskData: {
        indexTask: item.id,
        nameTask: item.name,
        descTask: item.description,
        createTask: item.created_at,
        task_end: item.task_end,
        employeeTask: item.user_id,
        statusTask: item.task_status,
      }})
    this.openPopup()
  }

  openPopup = () => {
    this.setState({isOpenPopup: true})
  }

  doneTask = () => {
    alert('done');
  }

  performTask = (id, data) => {
    this.props.performTask(id, data).then((response) => {
      console.log(response);
      this.props.fetchDrafts();
      this.setState({isEditTask: false})
      this.setState({isOpenPopup: false})
    })
  }


  render() {


    return (

      <div>
        <div className="page-title">
           <span className={'header__name'}>
             <FormattedMessage id="app.drafts"/>
            </span>
        </div>
        <div className={'projects-page'}>


          <div className="projects-page__top-nav top-nav">
            <div className="top-nav__head">
              <div className="top-nav__left">
                <div className="top-nav__tabs">
                  <div className={"top-nav__tabs-item " + (this.state.tabActive === 0 ? 'active' : '')}
                       onClick={() => this.handleTabs(0)}>
                    <FormattedMessage id="app.projects"/>
                  </div>
                </div>
              </div>
              <div className="top-nav__right">
                <NavLink to={'/projects'} className="top-nav__drafts">
                  <FormattedMessage id="app.tasks"/>
                </NavLink>
                <NavLink to={'/task/completed'} className="top-nav__drafts">
                  <FormattedMessage id="app.completed"/>
                </NavLink>
                <div className="top-nav__create">

                  <Button variant="contained" size="large" color="primary" onClick={this.openHandle} >
                    <FormattedMessage id="app.createTask"/>
                  </Button>
                </div>
              </div>
            </div>
          </div>




          <div className="view-result">
            <div className="view-result__inner">
              <div className="view-result__col">
                <div className="view-result__items-txt">
                  {/*<strong>8</strong> <FormattedMessage id="app.foundItems"/>*/}
                  <br/>
                </div>
              </div>
            </div>
          </div>

          <div className="projects-card">
            <div className="projects-card__content">
              <div className="row">

                { !this.props.loading ?
                  <React.Fragment>
                    {
                      this.props.drafts.map((item, index) => (
                        <ProjectCard key={index} item={item} draft={true} infoHandler={() => this.getInfoTask(item)} performTask={this.performTask} />
                      ))
                    }
                  </React.Fragment>
                  : null }

                <div className="col-md-4">
                  <div className="projects-card__i projects-card__i--create">
                    <div className="projects-card__i-content">
                      <div className="projects-card__i-create-task">
                        <div className="projects-card__i-create-ico">
                          +
                        </div>
                        <div className="projects-card__i-create-task-txt">
                          <FormattedMessage id="app.createTask"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          {
            !this.state.isEditTask
              ?
              this.state.isOpenPopup ?
                <PopupWrapper classPopup={'task-create'} closePopup={this.closeHandle}>
                  <CreateTaskPopup isEdit={this.state.isEditTask} reRender={this.reRender}/>
                </PopupWrapper>
                : null
              :
              this.state.isOpenPopup ?
                <PopupWrapper classPopup={'task-create'} closePopup={this.closeHandle}>
                  <CreateTaskPopup isEdit={this.state.isEditTask} dataTask={this.state.taskData} performTask={this.performTask} draft={true} reRender={this.reRender}/>
                </PopupWrapper>
                : null
          }


        </div>
      </div>


    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.drafts.loading,
    drafts: state.drafts.drafts
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchDrafts: () => dispath(fetchDrafts()),
    performTask: (id, data) => dispath(performTask(id, data)),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(Drafts));
