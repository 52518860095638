import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import './SellProductPopup.css';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import connect from "react-redux/es/connect/connect";
import {
  addArticleSaleDocument, completeSaleDocument,
  createSaleDocument, deleteArticleSaleDocument, deleteSaleDocument,
  fetchProducts, fetchProductsAll, fetchSalePDF, fetchSalePDFfetchSalePDFNoCash,
  updateArticleSaleDocument,
  updateClient, updateSaleDocument
} from "../../../store/action/products";
import {createSupplier} from "../../../store/action/settings";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import {fetchClients} from "../../../store/action/clients";
import {fetchRecipient} from "../../../store/action/bills";


class SellProductPopup extends Component {

  componentDidMount() {
    this.props.fetchAllClients('?filter=payment_type:NO_CASH');

    this.props.fetchRecipient().then((response) => {
      this.setState({
        recipient: response.data
      })
    });

    this.props.createSaleDocument().then((response) => {
      this.setState({saleDocument: response.data.id, saleDocumentData: response.data});


      this.props.fetchProductsAll().then(() => {
        this.props.addArticleSaleDocument(response.data.id).then((response) => {
          let product = [{
            id: this.props.products[this.props.sellIndex].id,
            idSellRow: response.data.id,
            name: this.props.products[this.props.sellIndex].name,
            article: this.props.products[this.props.sellIndex].article,
            stock: this.props.products[this.props.sellIndex].stock,
            stock_real: this.props.products[this.props.sellIndex].stock_real,
            amount: 1,
            price: this.props.products[this.props.sellIndex].price,
            sum: this.props.products[this.props.sellIndex].price,
          }];

          let productSend = product;
          productSend[0].article_id = this.props.products[this.props.sellIndex].id;

          this.props.updateArticleSaleDocument(response.data.id, this.state.saleDocument, productSend[0]).then((response) => {
            console.log(response);
          });

          this.setState({products: product});

        })
      })
    });
  }

  componentWillUnmount() {
    if (!this.state.create) {
      this.props.deleteSaleDocument(this.state.saleDocument);
    }
  }


  state = {
    payment_type: 'CASH',
    saleDocumentData: null,
    saleDocument: null,
    create: false,
    products: [
      {
        id: '',
        idSellRow: '',
        name: '',
        article: '',
        stock: '',
        stock_real: '',
        price: '',
        amount: '',
        sum: '',
      },
    ],

    visible: true,
    expenseFop: '2',
    recipient: [],
    fopChange: false,

    validation: {
      name: false,
      article: false,
      stock: false,
      price: false,
      amount: false,
      sum: false,
    }
  }

  handleClose = () => {
    this.props.handleClose();
  }

  handleCreate = () => {
    // this.props.handleClose();
    this.props.completeSaleDocument(this.state.saleDocument).then(() => {
      this.props.reRender();
      this.setState({success: true});
    });

    this.setState({create: true});

    // let data = this.state.saleDocumentData;
    // data.state =
    // this.props.updateSaleDocument(this.state.saleDocument);
    // this.props.handleClose();
  }

  addProductHandler = () => {
    let product = {
      id: '',
      idSellRow: '',
      name: '',
      article: '',
      // stock: '',
      stock_real: '',
      price: '',
      amount: '',
      sum: '',
    }

    this.props.addArticleSaleDocument(this.state.saleDocument).then((response) => {
      product.idSellRow = response.data.id;
    });

    let products = this.state.products;
    products.push(product);

    this.setState({
      products: products
    });


  }

  handleChangeProduct = (event, e, index) => {
    let newProducts = this.state.products;

    if (event === null) return;

    newProducts[index].id = event.id;
    newProducts[index].name = event.name;
    newProducts[index].article = event.article;
    newProducts[index].stock_real = event.stock_real;
    newProducts[index].price = event.price;
    newProducts[index].amount = 1;
    newProducts[index].sum = 1 * parseFloat(event.price);

    this.setState({products: newProducts})

    let productSend = newProducts[index];
    productSend.article_id = event.id;

    console.log(productSend);

    this.props.updateArticleSaleDocument(newProducts[index].idSellRow, this.state.saleDocument, productSend).then((response) => {
      console.log(response);
    })


  }

  handleBlurField = (event, key, elem) => {
    let newProducts = this.state.products;


    let value = event.target.value;
    if(value < 0){
      value = value * -1;
    }

    newProducts[key][elem] = value;
    newProducts[key].sum = parseFloat(parseInt(newProducts[key].amount) * parseFloat(newProducts[key].price)).toFixed(2);

    if (parseInt(newProducts[key].amount) > parseInt(newProducts[key].stock_real)) {
      newProducts[key].amount = parseInt(newProducts[key].stock_real);
      newProducts[key].sum = parseFloat(parseInt(newProducts[key].amount) * parseFloat(newProducts[key].price)).toFixed(2);
    }

    this.setState({products: newProducts})

    this.props.updateArticleSaleDocument(newProducts[key].idSellRow, this.state.saleDocument, newProducts[key]).then((response) => {
      console.log(response);
    })
  }

  handleChangeField = (event, key, elem) => {
    let newProducts = this.state.products;

    newProducts[key][elem] = event.target.value;
    newProducts[key].sum = parseFloat(parseInt(newProducts[key].amount) * parseFloat(newProducts[key].price)).toFixed(2);

    if (parseInt(newProducts[key].amount) > parseInt(newProducts[key].stock_real)) {
      newProducts[key].amount = parseInt(newProducts[key].stock_real);
      newProducts[key].sum = parseFloat(parseInt(newProducts[key].amount) * parseFloat(newProducts[key].price)).toFixed(2);
    }

    this.setState({products: newProducts})

  }

  sellProduct = () => {

  }

  removeFieldWorkAndServiceHandler = (index) => {

    this.props.deleteArticleSaleDocument(this.state.products[index].idSellRow, this.state.saleDocument).then((response) => {
      console.log(response);

      let newProducts = this.state.products;
      newProducts.splice(index, 1);
      this.setState({products: newProducts})
    });

    setTimeout(() => {
      if (index === 0) {
        document.getElementsByClassName('MuiAutocomplete-clearIndicatorDirty')[0].click();
      }

    }, 10)

  }

  handleChangePayment = (event) => {
    this.setState({payment_type: event.target.value})


    let data = this.state.saleDocumentData;

    if(event.target.value === 'NO_CASH'){
      this.setState({fopChange: true})
      data.recipient_id = '2';
    } else {
      this.setState({fopChange: false})
      data.recipient_id = '1';
    }

    data.payment_type = event.target.value;
    this.props.updateSaleDocument(this.state.saleDocument, data);
  }


  handleAutocompleteChangeInput = (newValue, event) => {
    let data = this.state.saleDocumentData;

    data.client_id = newValue.id;
    this.props.updateSaleDocument(this.state.saleDocument, data);
  }

  handlePrint = (id) => {
    this.props.fetchSalePDF(id).then((response) => {
      console.log(response);
    })
  }

  handlePrintNoCash = (id) => {
    this.props.fetchSalePDFfetchSalePDFNoCash(id).then((response) => {
      console.log(response);
    })
  }

  handleChangeFop = (event) => {
    this.setState({expenseFop: event.target.value})

    let data = this.state.saleDocumentData;
    data.recipient_id = event.target.value;
    this.props.updateSaleDocument(this.state.saleDocument, data);
  }

  render() {


    // console.log(document.);

    let productActive = [];

    if (this.props.products.length > 0) {
      this.state.products.map((i, key) => {
        this.props.products.map((item) => {
          if (item.id === i.id) {
            productActive.push(item);
          }
        })
      });

      if (productActive.length === 0) {
        productActive.push(this.props.products[0]);
      }
    }


    return (
      <div className="modal-popup__window sell-product-popup">
        <div className="modal-popup__header">
                <span>
                <FormattedMessage id="app.addWaybill"/> № {this.state.saleDocument}
                </span>
        </div>
        <div className="modal-popup__body">

          <div className="row">
            <div className={'waybills-popup__works col-12'}>
              <div className="waybills-popup__work-title">
                <FormattedMessage id="app.products"/>
                <Button variant="contained" color="primary" size="small" onClick={this.addProductHandler}>
                  <FormattedMessage id="app.add"/>
                </Button>
              </div>


              <div className="client-popup-radio">
                <FormLabel><FormattedMessage id="app.payments"/></FormLabel>
                <RadioGroup className={'radio-group'} name="gender1" value={this.state.payment_type}
                            onChange={this.handleChangePayment}>
                  <FormControlLabel value={'CASH'} control={<Radio/>} label={<FormattedMessage id="app.cash"/>}/>
                  <FormControlLabel value={'NO_CASH'} control={<Radio/>} label={<FormattedMessage id="app.noCash"/>}/>
                </RadioGroup>
              </div>


              {this.state.fopChange && this.state.recipient.length > 0 ?
                <React.Fragment>
                  <div className="client-popup-radio">
                    <FormLabel>Фоп</FormLabel>
                    <RadioGroup className={'radio-group radio-group-dashboard'} name="gender1"
                                defaultValue={this.state.expenseFop}
                                onChange={this.handleChangeFop}>
                      {
                        this.state.recipient.map((item, index) => {
                          return (
                            <FormControlLabel key={index} value={'' + item.id} control={<Radio size={'small'}/>}
                                              label={item.name}/>
                          )
                        })
                      }
                    </RadioGroup>
                  </div>
                </React.Fragment>

                :
                null
              }


              {this.state.payment_type === 'NO_CASH' ?
                <Autocomplete
                  freeSolo
                  options={this.props.clients}
                  getOptionLabel={(option) => option.first_name}
                  onChange={(event, newValue) => {
                    this.handleAutocompleteChangeInput(newValue, event)
                  }}
                  renderInput={(params) => <TextField {...params}
                                                      label={<FormattedMessage id="app.clientName"/>}
                                                      size={'small'}
                                                      inputProps={{
                                                        ...params.inputProps,
                                                        'data-name': 'first_name'
                                                      }}
                  />}
                />
                :
                null
              }

              {
                this.state.visible ?

                  this.state.products.map((item, key) => {

                    return (
                      <div className=" product-popup-row row" key={key}>

                        <div className="col-md-4">

                          {productActive.length > 0 ? <Autocomplete
                              freeSolo
                              options={this.props.products}
                              value={productActive[key]}
                              getOptionLabel={(option) => option.name + ' ' + option.article + (option.short_supplier_name !== '' ? '_' + option.short_supplier_name : '')}
                              onChange={(event, newValue) => {
                                this.handleChangeProduct(newValue, event, key)
                              }}
                              className={'product-name'}
                              renderInput={(params) => <TextField {...params}
                                                                  label={<FormattedMessage id="app.productsName"/>}
                                                                  size={'small'}
                                                                  inputProps={{
                                                                    ...params.inputProps,
                                                                  }}
                              />}
                            />
                            :
                            null
                          }

                        </div>

                        <div className="col-md-4">
                          <TextField className={"task-create__name-input"}
                                     value={this.state.products[key].amount}
                                     inputProps={{'data-key': key, 'data-field': 'amount'}}
                                     onChange={(event) => this.handleChangeField(event, key, 'amount')}
                                     onBlur={(event) => this.handleBlurField(event, key, 'amount')}
                                     label={<FormattedMessage id="app.count"/>}
                          />
                        </div>


                        <div className="col-md-4">
                          <TextField className={"task-create__name-input"}
                            // onKeyUp={this.handleChangeValueWorkAndService}
                                     value={this.state.products[key].article}
                                     onChange={this.handleChangeValueWorkAndService}
                                     inputProps={{'data-key': key, 'data-field': 'article'}}
                                     label={<FormattedMessage id="app.article"/>}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField className={"task-create__name-input"}
                            // onKeyUp={this.handleChangeValueWorkAndService}
                                     value={this.state.products[key].stock_real === '' ? '' : parseInt(this.state.products[key].stock_real)}
                                     inputProps={{'data-key': key, 'data-field': 'stock'}}
                            // onChange={this.handleChangeValueWorkAndService}
                                     label={<FormattedMessage id="app.stock"/>}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField className={"task-create__name-input"}
                            // onKeyUp={this.handleChangeValueWorkAndService}
                                     value={this.state.products[key].price}
                                     inputProps={{'data-key': key, 'data-field': 'price'}}
                                     onChange={(event) => this.handleChangeField(event, key, 'price')}
                                     onBlur={(event) => this.handleBlurField(event, key, 'price')}
                                     label={<FormattedMessage id="app.price"/>}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField className={"task-create__name-input"}
                            // onKeyUp={this.handleChangeValueWorkAndService}
                                     value={this.state.products[key].sum}
                                     inputProps={{'data-key': key, 'data-field': 'sum'}}
                            // onChange={this.handleChangeValueWorkAndService}
                                     label={<FormattedMessage id="app.amount"/>}
                          />
                        </div>
                        {this.state.products.length === 1 ? null :
                          <div className="col-md-3 d-flex justify-content-end align-items-end product-sell-remove">
                            <Button variant="contained" color="primary" size="small" data-key={key}
                                    onClick={() => this.removeFieldWorkAndServiceHandler(key)}>
                              <FormattedMessage id="app.remove"/>
                            </Button>
                          </div>
                        }

                      </div>
                    )
                  })

                  : null

              }


            </div>
          </div>

        </div>
        <div className="modal-popup__footer">
          <div className="modal-popup__btn">
            <Button variant="contained" color="primary" className={"modal-popup__btn-i"} onClick={this.handleCreate}>
              <FormattedMessage id="app.sell"/>
            </Button>
            <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={this.handleClose}>
              <FormattedMessage id="app.close"/>
            </Button>
          </div>
        </div>

        {this.state.success ?
          <div className="sell-product-success-popup">
            <div className="modal-popup__header">
            <span>
              Товар продан, накладная № {this.state.saleDocument}
            </span>
            </div>
            <div className="modal-popup__footer">
              <div className="modal-popup__btn">
                {this.state.payment_type !== "CASH" ?
                  <Button variant="contained" size={'small'} color="primary" className={"modal-popup__btn-i"}
                          onClick={() => this.handlePrint(this.state.saleDocument)}>
                    <FormattedMessage id="app.print"/>
                  </Button>

                  :
                  <Button variant="contained" size={'small'} color="primary" className={"modal-popup__btn-i"}
                          onClick={() => this.handlePrintNoCash(this.state.saleDocument)}>
                    <FormattedMessage id="app.print"/>
                  </Button>
                }

                <Button variant="contained" color="secondary" className={"modal-popup__btn-i"}
                        onClick={this.handleClose}>
                  <FormattedMessage id="app.close"/>
                </Button>
              </div>
            </div>
          </div>
          :
          null
        }
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    loading: state.products.loading,
    products: state.products.productsAll,
    clients: state.clients.clients,
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchRecipient: () => dispath(fetchRecipient()),
    createSaleDocument: () => dispath(createSaleDocument()),
    fetchProductsAll: () => dispath(fetchProductsAll()),
    fetchAllClients: (data) => dispath(fetchClients(data)),
    addArticleSaleDocument: (id) => dispath(addArticleSaleDocument(id)),
    updateArticleSaleDocument: (id, idDocument, data) => dispath(updateArticleSaleDocument(id, idDocument, data)),
    deleteSaleDocument: (id) => dispath(deleteSaleDocument(id)),
    updateSaleDocument: (id, data) => dispath(updateSaleDocument(id, data)),
    deleteArticleSaleDocument: (id, idDocument) => dispath(deleteArticleSaleDocument(id, idDocument)),
    completeSaleDocument: (id) => dispath(completeSaleDocument(id)),
    fetchSalePDF: (id) => dispath(fetchSalePDF(id)),
    fetchSalePDFfetchSalePDFNoCash: (id) => dispath(fetchSalePDFfetchSalePDFNoCash(id)),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(SellProductPopup);
