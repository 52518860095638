import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import './WaybillsPopup.css'

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import OutsideAlerter from "../../../hoc/OutsideCliker/OutsideCliker";
import {FormattedMessage, injectIntl} from "react-intl";
import {
  createOutfits, createOutfitsParts, createOutfitsService, deleteOutfits, deleteOutfitsParts, deleteOutfitsService,
  fetchOutfits, fetchOutfitsCashPDF, fetchOutfitsPDF,
  updateOutfits,
  updateOutfitsParts,
  updateOutfitsService
} from "../../../store/action/outfits";
import connect from "react-redux/es/connect/connect";
import {fetchClients} from "../../../store/action/clients";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {fetchMasters, fetchService, fetchWorkers} from "../../../store/action/settings";
import {fetchProducts, fetchProductsInStock} from "../../../store/action/products";
import {fetchRecipient} from "../../../store/action/bills";


class WaybillsPopup extends Component {

  state = {
    client: 1,
    create: false,
    work_order_id: -1,
    open: false,
    noOrder: false,
    max_data: false,

    productStockCount: false,

    data: {
      first_name: '',
      last_name: '',
      phone: '',
      address: '',
      brand: '',
      model: '',
      year: '',
      registered_id: '',
      car_body: '',
      engine: '',
      chasse: '',
    },

    recipient: [],

    dataUpdate: {
      user_id: '',
      client_id: 1,
      car_id: 1,
      data_start: '',
      data_end: '',
      machine_mileage: '',
      description: '',
      master: '',
    },

    fieldsWorkAndService: [
      {
        work_order_service_id: null,
        service_id: null,
        workName: '',
        workData: '',
        amount: '',
        price: '',
        sum: ''
      }
    ],

    fieldsUsedParts: [
      {
        work_order_part_id: null,
        article_id: null,
        sparePartsName: '',
        amount: '',
        price: '',
        sum: '',
        article: '',
      }
    ],

    validation: {
      data_start: false,
      user_id: false,
      data_end: false,
      first_name: false,
      table: false,
    }

  }

  componentDidMount() {
    this.props.fetchAllClients();
    this.props.fetchService();
    this.props.fetchProducts();
    this.props.fetchMasters();

    this.props.fetchRecipient().then((response) => {
      this.setState({
        recipient: response.data
      })
    });

    let that = this;

    if (!this.props.isEdit) {
      this.props.createOutfits().then((response) => {
        if (response.data.hasOwnProperty('error')) {
          this.setState({noOrder: true})
          return;
        }

        that.setState({work_order_id: response.data.id});

        let patrs = that.state.fieldsUsedParts;
        patrs[0].work_order_part_id = response.data.parts[0].id;

        that.setState({fieldsUsedParts: patrs});

        let services = that.state.fieldsWorkAndService;
        services[0].work_order_service_id = response.data.services[0].id;

        that.setState({fieldsWorkAndService: services});
      })
    } else {

      this.setState({
        create: true
      });

      let editService = this.props.outfits.data[this.props.index].services;
      let editParts = this.props.outfits.data[this.props.index].parts;

      let dataUpdate = {
        data_start: this.props.outfits.data[this.props.index].data_start,
        data_end: this.props.outfits.data[this.props.index].data_end,
        user_id: this.props.outfits.data[this.props.index].user_id,
        client_id: this.props.outfits.data[this.props.index].client_id,
        car_id: this.props.outfits.data[this.props.index].car_id,
        machine_mileage: this.props.outfits.data[this.props.index].machine_mileage,
        description: this.props.outfits.data[this.props.index].description,
        master: this.props.outfits.data[this.props.index].master,
        recipient_id: this.props.outfits.data[this.props.index].recipient_id,
      }

      console.log(dataUpdate);

      let data = this.state.data;
      data.first_name = this.props.outfits.data[this.props.index].client.first_name;
      data.last_name = this.props.outfits.data[this.props.index].client.last_name;
      data.phone = this.props.outfits.data[this.props.index].client.phone;
      data.address = this.props.outfits.data[this.props.index].client.address;
      data.brand = this.props.outfits.data[this.props.index].client.car.brand;
      data.model = this.props.outfits.data[this.props.index].client.car.model;
      data.year = this.props.outfits.data[this.props.index].client.car.year;
      data.registered_id = this.props.outfits.data[this.props.index].client.car.registered_id;
      data.car_body = this.props.outfits.data[this.props.index].client.car.car_body;
      data.engine = this.props.outfits.data[this.props.index].client.car.engine;
      data.chasse = this.props.outfits.data[this.props.index].client.car.chasse;
      data.payment_type = this.props.outfits.data[this.props.index].client.payment_type;


      let editServiceItem = [];
      editService.map((item) => {
        if (item.service_id !== null) {
          editServiceItem.push({
            work_order_service_id: item.id,
            service_id: item.service.id,
            workName: item.service.name,
            workData: item.service,
            amount: item.amount,
            price: item.price,
            sum: parseInt(item.amount) * parseInt(item.price)
          });
        }
      });
      // console.log(123);
      // console.log(editServiceItem);

      let editPartsItem = [];
      editParts.map((item) => {
        if (item.article_id !== null) {
          editPartsItem.push({
            work_order_part_id: item.id,
            article_id: item.article.id,
            sparePartsName: item.article.name,
            amount: item.amount,
            price: item.price,
            sum: parseInt(item.amount) * parseInt(item.price),
            article: item.article
          });
        }
      });

      this.setState({
        fieldsWorkAndService: editServiceItem,
        fieldsUsedParts: editPartsItem,
        work_order_id: this.props.outfits.data[this.props.index].id,
        dataUpdate: dataUpdate,
        data: data
      });


    }

    let day = new Date().getDate() + 1 < 10 ? '0' + parseInt(new Date().getDate()) : new Date().getDate();
    let dayMonth = new Date().getMonth() + 1 < 10 ? '0' + parseInt(new Date().getMonth() + 1) : new Date().getMonth() + 1;
    let dayYear = new Date().getFullYear();
    let today = dayYear + '-' + dayMonth + '-' + day;


    let newVal = this.state.dataUpdate;
    newVal.data_start = today;
    this.setState({max_data: today});
  }

  componentWillUnmount() {
    if (!this.state.create) {
      this.props.deleteOutfits(this.state.work_order_id);
      this.props.handleClose();
      setTimeout(() => {
        this.props.reRender();
      }, 500)
    }
  }

  handleClose = () => {
    this.setState({
      create: false
    });
    this.props.handleClose();
  }

  handleCloseOnly = () => {
    this.setState({
      create: true
    });
    this.props.handleClose();
  }


  handlePrintCash = () => {
    this.props.fetchOutfitsCashPDF(this.state.work_order_id).then((response) => {
      console.log(response);
    })
  }

  handlePrint = () => {
    this.props.fetchOutfitsPDF(this.state.work_order_id).then((response) => {
      console.log(response);
    })
  }


  render() {
    const placeholderDesc = this.props.intl.formatMessage({
      id: 'app.description',
    });

    let that = this;

    let editElement = '';
    if (this.props.isEdit) {
      editElement = this.props.outfits.data[this.props.index];
    }

    let clients = this.props.clients;

    let serviceEdit = [];
    if (that.props.service.length > 0) {
      that.state.fieldsWorkAndService.map((i, key) => {
        that.props.service.map((item) => {
          if (item.id === i.service_id) {
            serviceEdit.push(item);
          }
        })
      })
    }
    let partsEdit = [];
    if (that.props.products.length > 0) {
      that.state.fieldsUsedParts.map((i, key) => {
        that.props.products.map((item) => {
          if (item.id === i.article_id) {
            partsEdit.push(item);
          }
        })
      })

    }


    return (

      <div className="modal-popup__window container">
        <div className="modal-popup__header">
                <span>
                  <FormattedMessage id="app.waybill"/>
                </span>
        </div>
        <div className="modal-popup__body">

          <React.Fragment>
            <div className="waybills-popup__inner">

              <React.Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className={'col-md-6'}>
                        <TextField
                          type="datetime-local"
                          value={this.state.dataUpdate.data_start}
                          error={this.state.validation.data_start}
                          className={'modal-popup__input--time'}
                          InputProps={{
                            inputProps: {
                              readOnly: Boolean(true),
                              // max: this.state.max_data,
                              'data-name': 'data_start'
                            }
                          }}
                          label=<FormattedMessage id="app.orderDate"/>
                        onChange={(event) => {
                        this.createChangeHandler(event)
                      }}
                        />
                      </div>

                      <div className={'col-md-6'}>
                        <TextField
                          type="datetime-local"
                          value={this.state.dataUpdate.data_end}
                          error={this.state.validation.data_end}
                          InputProps={{
                            inputProps: {
                              readOnly: Boolean(true),
                              // max: this.state.max_data,
                              'data-name': 'data_end'
                            }
                          }}
                          className={'modal-popup__input--time'}
                          label={<FormattedMessage id="app.orderExecutionDate"/>}
                          InputLabelProps={{
                            shrink: true,
                          }}

                          onChange={(event) => {
                            this.createChangeHandler(event)
                          }}
                        />
                      </div>
                    </div>

                    <div className="row mb15">
                      <div className={'col-md-6'}>
                        <TextField className={"task-create__name-input"} value={this.state.dataUpdate.master.name}
                                   label={<FormattedMessage id="app.responsible"/>} inputProps={{
                          readOnly: Boolean(true),
                        }}/>

                      </div>

                      <div className={'col-md-6'}>
                        <TextField className={"task-create__name-input"} value={this.state.data.first_name}
                                   label={<FormattedMessage id="app.clientName"/>} inputProps={{
                          readOnly: Boolean(true),
                        }}/>

                      </div>


                    </div>


                    <div className="row mb15">
                      <div className={'col-md-3'}>
                        <TextField className={"task-create__name-input"} value={this.state.data.brand}
                                   label={<FormattedMessage id="app.carBrand"/>} inputProps={{
                          readOnly: Boolean(true),
                        }}/>

                      </div>
                      <div className={'col-md-3'}>
                        <TextField className={"task-create__name-input"} value={this.state.data.model}
                                   label={<FormattedMessage id="app.сarModel"/>} inputProps={{
                          readOnly: Boolean(true),
                        }}/>

                      </div>

                      <div className={'col-md-3'}>
                        <TextField className={"task-create__name-input"} value={this.state.data.year}
                                   label={<FormattedMessage id="app.yearOfIssue"/>} inputProps={{
                          readOnly: Boolean(true),
                        }}/>
                      </div>

                      <div className={'col-md-3'}>
                        <TextField className={"task-create__name-input"} value={this.state.data.registered_id}
                                   label={<FormattedMessage id="app.stateRegisterNumber"/>} inputProps={{
                          readOnly: Boolean(true),
                        }}/>

                      </div>


                    </div>

                    {/*<div className="row mb15">*/}
                    {/**/}
                    {/*</div>*/}


                    <div className="row mb15">
                      <div className={'col-md-3'}>
                        <TextField className={"task-create__name-input"} value={this.state.data.car_body}
                                   label={<FormattedMessage id="app.bodyType"/>} inputProps={{
                          readOnly: Boolean(true),
                        }}/>
                      </div>

                      <div className={'col-md-3'}>
                        <TextField className={"task-create__name-input"} value={this.state.data.engine}
                                   label={<FormattedMessage id="app.engine"/>} inputProps={{
                          readOnly: Boolean(true),
                        }}/>

                      </div>

                      <div className={'col-md-3'}>
                        <TextField className={"task-create__name-input"} value={this.state.data.chasse}
                                   label={<FormattedMessage id="app.shasi"/>} inputProps={{
                          readOnly: Boolean(true),
                        }}/>
                      </div>
                      <div className={'col-md-3'}>
                        <TextField className={"task-create__name-input"} value={this.state.data.phone}
                                   label={<FormattedMessage id="app.phone"/>} inputProps={{
                          readOnly: Boolean(true),
                        }}/>

                      </div>
                    </div>

                    {/*<div className="row mb15">*/}
                    {/**/}

                    {/*</div>*/}
                    <div className="row mb15">


                      <div className={'col-md-3'}>
                        <TextField className={"task-create__name-input"} value={this.state.data.address}
                                   label={<FormattedMessage id="app.address"/>}
                                   inputProps={{
                                     readOnly: Boolean(true),
                                   }}/>

                      </div>

                      <div className={'col-md-3'}>
                        <TextField className={"task-create__name-input"}
                                   value={this.state.dataUpdate.machine_mileage}
                                   label={<FormattedMessage id="app.machineMileage"/>}
                                   inputProps={{
                                     readOnly: Boolean(true),
                                     'data-name': "machine_mileage"
                                   }}/>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-12">
                            <textarea name="" placeholder={placeholderDesc} className={'waybills-popup__desc'}
                                      defaultValue={editElement.description} readOnly={true}>
                            </textarea>

                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.data.payment_type === 'NO_CASH' ?
                      <div className="row mb15">
                        <div className="col-md-12">

                          {this.state.recipient.length > 0 ?
                            <React.Fragment>
                              {
                                this.state.recipient.map((item, index) => {
                                  if (item.id === this.state.dataUpdate.recipient_id) {
                                    return (
                                      <TextField key={index} className={"task-create__name-input"}
                                                 value={item.name}
                                                 label={'Фоп'}
                                                 inputProps={{
                                                   readOnly: Boolean(true)
                                                 }}/>
                                    )
                                  }

                                })
                              }
                            </React.Fragment>
                            :
                            null
                          }
                        </div>

                      </div>
                      :
                      null
                    }
                  </div>

                </div>

                <div className="row">
                  <div className={'waybills-popup__works col-12'}>
                    <div className="waybills-popup__work-title">
                      <FormattedMessage id="app.completedWorksAndServices"/>
                      {/*<Button variant="contained" color="primary" size="small"*/}
                      {/*onClick={that.addFieldWorkAndServiceHandler}>*/}
                      {/*<FormattedMessage id="app.add"/>*/}
                      {/*</Button>*/}
                    </div>

                    {this.state.fieldsWorkAndService.map(function (item, key) {
                      return (
                        <div className="waybills-popup__work-name row" key={key}>
                          <div className="col-md-3">
                            <TextField className={"task-create__name-input"}
                                       value={item.workData.name}
                                       inputProps={{
                                         'data-key': key, 'data-field': 'amount',
                                         readOnly: Boolean(true)
                                       }}
                                       onChange={that.handleChangeValueWorkAndService}
                                       label={<FormattedMessage id="app.workName"/>}
                            />

                          </div>
                          <div className="col-md-3">
                            <TextField className={"task-create__name-input"}
                              // onKeyUp={that.handleChangeValueWorkAndService}
                                       value={that.state.fieldsWorkAndService[key].amount}
                                       inputProps={{
                                         'data-key': key, 'data-field': 'amount',
                                         readOnly: Boolean(true)
                                       }}
                                       onChange={that.handleChangeValueWorkAndService}
                                       label={<FormattedMessage id="app.count"/>}
                            />
                          </div>


                          <div className="col-md-2">
                            <TextField className={"task-create__name-input"}
                                       value={that.state.fieldsWorkAndService[key].price}
                                       onChange={that.handleChangeValueWorkAndService}
                                       inputProps={{
                                         'data-key': key, 'data-field': 'price',
                                         readOnly: Boolean(true)
                                       }}
                                       label={<FormattedMessage id="app.price"/>}
                            />
                          </div>
                          <div className="col-md-2">
                            <TextField className={"task-create__name-input"}
                                       value={that.state.fieldsWorkAndService[key].sum}
                                       inputProps={{
                                         'data-key': key, 'data-field': 'sum',
                                         readOnly: Boolean(true)
                                       }}
                                       onChange={that.handleChangeValueWorkAndService}
                                       label={<FormattedMessage id="app.amount"/>}
                            />
                          </div>
                          <div className="col-md-2 d-flex justify-content-end align-items-end">
                            {/*<Button variant="contained" color="primary" size="small" data-key={key}*/}
                            {/*onClick={() => that.removeFieldWorkAndServiceHandler(key)}>*/}
                            {/*<FormattedMessage id="app.remove"/>*/}
                            {/*</Button>*/}
                          </div>
                        </div>
                      )
                    })}


                  </div>
                </div>

                <div className="row">
                  <div className={'waybills-popup__spare-parts col-12'}>
                    <div className="waybills-popup__spare-parts-title">
                      <FormattedMessage id="app.usedSpareParts"/>
                      {/*<Button variant="contained" color="primary" size="small"*/}
                      {/*onClick={that.addFieldUsedPartsHandler}>*/}
                      {/*<FormattedMessage id="app.add"/>*/}
                      {/*</Button>*/}
                    </div>


                    {this.state.fieldsUsedParts.map(function (item, key) {

                      return (
                        <div className="waybills-popup__spare-parts-name row" key={key}>

                          <div className="col-md-3">

                            <TextField className={"task-create__name-input"}
                                       value={item.article.name}
                                       inputProps={{
                                         'data-key': key, 'data-field': 'amount',
                                         readOnly: Boolean(true)
                                       }}
                                       label={<FormattedMessage id="app.sparePartsName"/>}
                            />
                          </div>

                          <div className="col-md-3">
                            <TextField className={"task-create__name-input"}
                                       value={that.state.fieldsUsedParts[key].amount}
                                       onChange={that.handleChangeValueUsedParts}
                                       inputProps={{
                                         'data-key': key, 'data-field': 'amount',
                                         readOnly: Boolean(true)
                                       }}
                                       label={<FormattedMessage id="app.count"/>}
                            />

                          </div>
                          <div className="col-md-2">
                            <TextField className={"task-create__name-input"}
                                       value={that.state.fieldsUsedParts[key].price}
                                       onChange={that.handleChangeValueUsedParts}
                                       inputProps={{
                                         'data-key': key, 'data-field': 'price',
                                         readOnly: Boolean(true)
                                       }}
                                       label={<FormattedMessage id="app.price"/>}
                            />
                          </div>
                          <div className="col-md-2">
                            <TextField className={"task-create__name-input"}
                                       value={that.state.fieldsUsedParts[key].sum}
                                       onChange={that.handleChangeValueUsedParts}
                                       inputProps={{
                                         'data-key': key, 'data-field': 'sum',
                                         readOnly: Boolean(true)
                                       }}
                                       label={<FormattedMessage id="app.amount"/>}
                            />
                          </div>
                          <div className="col-md-2 d-flex justify-content-end align-items-end">

                          </div>
                        </div>

                      )
                    })}

                  </div>
                </div>

              </React.Fragment>

            </div>

            <div className="modal-popup__footer">
              <div className="modal-popup__btn">

                <React.Fragment>
                  {editElement.client.payment_type === 'CASH' ?
                    <Button variant="contained" color="secondary" className={"modal-popup__btn-i"}
                            onClick={this.handlePrintCash}>
                      <FormattedMessage id="app.print"/>
                    </Button>
                    :
                    <Button variant="contained" color="secondary" className={"modal-popup__btn-i"}
                            onClick={this.handlePrint}>
                      <FormattedMessage id="app.print"/>
                    </Button>
                  }
                </React.Fragment>


                <Button variant="contained" color="secondary" className={"modal-popup__btn-i"}
                        onClick={this.handleCloseOnly}>
                  <FormattedMessage id="app.close"/>
                </Button>


              </div>
            </div>
          </React.Fragment>


        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    outfits: state.outfits.outfits,
    clients: state.clients.clients,
    service: state.settings.service,
    products: state.products.products,
    masters: state.settings.masters,
    loadingProducts: state.products.loading,
    loadingService: state.settings.loading,
  }

}

function mapDispathToProps(dispath) {
  return {
    // fetchOutfits: () => dispath(fetchOutfits())
    fetchAllClients: () => dispath(fetchClients()),
    fetchRecipient: () => dispath(fetchRecipient()),
    createOutfits: () => dispath(createOutfits()),
    updateOutfits: (id, data) => dispath(updateOutfits(id, data)),
    fetchService: () => dispath(fetchService()),
    fetchProducts: () => dispath(fetchProducts()),
    createOutfitsService: (idOrder) => dispath(createOutfitsService(idOrder)),
    createOutfitsParts: (idOrder) => dispath(createOutfitsParts(idOrder)),
    updateOutfitsService: (idOrder, idOrderService, data) => dispath(updateOutfitsService(idOrder, idOrderService, data)),
    updateOutfitsParts: (idOrder, idOrderPart, data) => dispath(updateOutfitsParts(idOrder, idOrderPart, data)),
    deleteOutfitsService: (idOrder, idOrderService) => dispath(deleteOutfitsService(idOrder, idOrderService)),
    deleteOutfitsParts: (idOrder, idOrderPart) => dispath(deleteOutfitsParts(idOrder, idOrderPart)),
    deleteOutfits: (id) => dispath(deleteOutfits(id)),
    fetchOutfitsCashPDF: (id) => dispath(fetchOutfitsCashPDF(id)),
    fetchOutfitsPDF: (id) => dispath(fetchOutfitsPDF(id)),
    fetchMasters: () => dispath(fetchMasters()),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(WaybillsPopup));
