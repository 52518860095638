import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import {NavLink, withRouter} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import AddBillsItemPopup from "../../components/ModalPopups/AddBillsItemPopup/AddBillsItemPopup";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import AddProductWaybillsPopup from "../../components/ModalPopups/AddProductWaybillsPopup/AddProductWaybillsPopup";
import Autocomplete from '@material-ui/lab/Autocomplete';
import './WorkersStatistic.css'
import TableRows from "../../components/Tables/TableRows/TableRows";
import TableHeader from "../../components/Tables/TableHeader/TableHeader";

import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {fetchDelivery, fetchProducts} from "../../store/action/products";
import connect from "react-redux/es/connect/connect";
import {fetchProductWaybills} from "../../store/action/productWaybills";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableRowsDelivery from "../../components/Tables/TableRows/TableRowsDelivery";
import {fetchWorkersStatistic} from "../../store/action/workersStatistic";
import TableRowsWorkersStatistic from "../../components/Tables/TableRows/TableRowsWorkersStatistic";
import TableRowsWorkersStatisticMaster from "../../components/Tables/TableRows/TableRowsWorkersStatisticMaster";


class WorkersStatistic extends Component {

  state = {
    isOpen: false,
    activeTab: 0,

    perPage: 30,
    currentPage: 1,
    perPageDelivery: 30,
    currentPageDelivery: 1,
    search: '',
    dateStart: '',
    dateStartDef: '',
    dateEnd: '',
  }

  componentDidMount() {

    let day = new Date().getDate() < 10 ? '0' + parseInt(new Date().getDate()) : new Date().getDate();
    let dayMonth = new Date().getMonth() + 1 < 10 ? '0' + parseInt(new Date().getMonth() + 1) : new Date().getMonth() + 1;
    let dayYear = new Date().getFullYear();
    let lastMounth = dayYear + '-' + dayMonth + '-' + day;
    let lastMounthDef = day + '-' + dayMonth + '-' + dayYear;

    // this.setState({dateStart: lastMounth, dateStartDef: lastMounthDef})

    setTimeout(() => {
      this.fetchDataCreate();
    })

    document.title = this.props.intl.formatMessage({
      id: 'app.overhead',
    });
  }

  reRender = () => {
    this.fetchDataCreate();
  }

  addProductWaybillsHandle = () => {
    this.setState({
      isOpen: true
    })
  }
  closePopupProductWaybills = () => {
    this.setState({
      isOpen: false
    })
  }

  handleChangeTabs = (event, newValue) => {
    // console.log(newValue);

    this.setState({activeTab: newValue})
    //
    // setTimeout(() => {
    //   this.fetchDataCreate();
    // })

  }


  fetchDataCreate = (filter = false) => {
    let data = '',
      curPage = this.state.currentPage,
      perPage = this.state.perPage,
      dateStart = this.state.dateStart !== '' ? this.state.dateStart : null,
      dateEnd = this.state.dateEnd !== '' ? this.state.dateEnd : null,
      search = this.state.search !== '' ? this.state.search : null;

    if(filter) {
      curPage = 1;
      this.setState({currentPage: 1});
    }

    let tableDate = '?between=data_start';
    // if (this.state.activeTab === 0) {
    //   curPage = this.state.currentPageDelivery;
    //   perPage = this.state.perPageDelivery;
    //   tableDate = '&between=delivery_date';
    // }

    // data = data + `?page=${curPage}&limit=${perPage}`;

    // between=created_at,2020-09-16:2020-09-16
    let date = '';

    if (dateStart !== null) date = date + `${tableDate},${dateStart}`;

    if (dateEnd !== null) {
      if (dateStart === null) {
        date = date + `${tableDate},:${dateEnd}`;
      } else {
        date = date + `:${dateEnd}`;
      }
    }

    data = data + date;
    if(data !== '') {
      if (search !== null) data = data + `&filter=id:${search}&master=${search}`;
    } else {
      if (search !== null) data = data + `?filter=id:${search}&master=${search}`;
    }


    this.props.fetchWorkersStatistic(data);

    // if (this.state.activeTab === 0) {
    //   this.props.fetchWorkersStatistic(data);
    // } else {
    //   this.props.fetchProductWaybills(data);
    // }
  }

  filterReset = () => {
    this.setState({
      search: '',
      dateStart: '',
      dateEnd: ''
    })

    setTimeout(() => this.fetchDataCreate(true))
  }

  handleChangePage = (event, newPage) => {
    this.setState({currentPage: newPage + 1})
    setTimeout(() => this.fetchDataCreate())
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({perPage: parseInt(event.target.value, 10)})
    this.setState({currentPage: 0})
    setTimeout(() => this.fetchDataCreate())
  }


  handleChangePageDelivery = (event, newPage) => {
    this.setState({currentPageDelivery: newPage + 1})
    setTimeout(() => this.fetchDataCreate())
  }

  handleChangeRowsPerPageDelivery = (event) => {

    this.setState({perPageDelivery: parseInt(event.target.value, 10)})
    this.setState({currentPageDelivery: 0})
    setTimeout(() => this.fetchDataCreate())
  }

  render() {


    let headerDelivery = [
      {
        colspan: '0',
        align: 'left',
        item: ''
      },
      {
        colspan: '0',
        align: 'left',
        item: 'id'
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.orderDate"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.orderExecutionDate"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.master"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.masterSalary"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.income"/>
      }
    ];

    let headerUnderTableAll = [
      {
        colspan: '0',
        align: 'left',
        item: 'id'
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.name"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.count"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.price"/>
      },
    ];

    return (
      <div className="waybills">
        <div className="page-title">
           <span className={'header__name'}>
                <FormattedMessage id="app.works"/>
            </span>
        </div>

        <div className="filter-head">

          <div className="filter-head__left">
            <div className="site-filter">
              <TextField
                type="date"
                value={this.state.dateStart}
                defaultValue={this.state.dateStartDef}
                label={<FormattedMessage id="app.selectDateFrom"/>}
                onChange={(event) => {
                  this.setState({dateStart: event.target.value})
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type="date"
                label={<FormattedMessage id="app.selectDateFor"/>}
                value={this.state.dateEnd}
                onChange={(event) => {
                  this.setState({dateEnd: event.target.value})
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="filter-client-wrap">
              <TextField
                className="filter-client-select"
                value={this.state.search}
                onChange={(event) => this.setState({search: event.target.value})}
                size="small"
                onKeyDown={this._handleKeyDown}
                label={<FormattedMessage id="app.search"/>}
                variant="outlined"/>
            </div>


            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.filterReset}>
              <FormattedMessage id="app.reset"/>
            </Button>
            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={() => this.fetchDataCreate( true)}>
              <FormattedMessage id="app.search"/>
            </Button>
          </div>


          {/*<Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.addProductWaybillsHandle}>*/}
            {/*<FormattedMessage id="app.addNote"/>*/}
          {/*</Button>*/}

          {/*{this.state.isOpen ?*/}
            {/*<PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopupProductWaybills}>*/}
              {/*<AddProductWaybillsPopup reRender={this.reRender}/>*/}
            {/*</PopupWrapper>*/}
            {/*: ''}*/}
        </div>

        <Paper>
          <Tabs
            value={this.state.activeTab}
            onChange={(event, newValue) => this.handleChangeTabs(event, newValue)}
            indicatorColor="primary"
            textColor="primary"
            style={{padding: '0 20px'}}
          >
            <Tab label={<FormattedMessage id="app.all"/>}/>
            {
              this.props.workersStatistic.hasOwnProperty('all') ?
                Object.keys(this.props.workersStatistic.masters).map((row, key) => {
                  return (
                    <Tab value={row} label={row} key={key}/>
                  )
                })
                : null
            }

          </Tabs>
        </Paper>


        {this.state.activeTab === 0 ?
          <div className="page-section">
            <div className="page-section__content">
              <TableContainer className={'clients-table' + (this.props.loading ? ' loading-table' : '')}>
                <Table size="small" aria-label="a dense table">
                  <TableHeader rows={headerDelivery}/>

                  <TableBody>
                    {
                      this.props.workersStatistic.hasOwnProperty('all') ?
                        Object.keys(this.props.workersStatistic.all).map((row, key) => {
                          return (

                            <TableRowsWorkersStatistic reRender={this.reRender} key={key}
                                                       row={this.props.workersStatistic.all[row]}
                                                       button={true}
                                                       undertable={true} headerUnderTable={headerUnderTableAll}/>
                          )
                        })
                        : null
                    }
                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          </div>

          :

          null
        }

        {
          this.props.workersStatistic.hasOwnProperty('masters') ?
            Object.keys(this.props.workersStatistic.masters).map((master, key) => {
              if (this.state.activeTab === master) {
                return (
                  <div className="page-section" key={key}>
                    <div className="page-section__content">

                      <TableContainer className={'clients-table' + (this.props.loading ? ' loading-table' : '')}>
                        <Table size="small" aria-label="a dense table">
                          <TableHeader rows={headerDelivery}/>

                          <TableBody>
                            {
                              this.props.workersStatistic.masters[master].length > 0 ?
                                this.props.workersStatistic.masters[master].map((row, key) => {
                                  return (
                                    <TableRowsWorkersStatistic reRender={this.reRender} key={key}
                                                               row={row}
                                                               button={true}
                                                               undertable={true}
                                                               headerUnderTable={headerUnderTableAll}/>
                                  )
                                })
                                :
                                null
                            }

                          </TableBody>


                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )
              }
            })
            : null
        }


      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    loading: state.workersStatistic.loading,
    productWaybills: state.productWaybills.productWaybills,
    delivery: state.products.delivery,
    workersStatistic: state.workersStatistic.workersStatistic,
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchWorkersStatistic: (data) => dispath(fetchWorkersStatistic(data)),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(withRouter(injectIntl(WorkersStatistic)));
