

export function fieldEmpty(field, emptyFields = []) {

  let validation = [];
  let isValidation = true;
  Object.keys(field).map((item) => {

    if(emptyFields.indexOf(item) === -1){
      if (field[item] !== '' && field[item] !== null) {
        validation[item] = false;
      } else {
        validation[item] = true;
        isValidation = false;
      }
    }
    if((item ==='price') && (!/^(?:\d+|\d{1,3})(?:\.\d+)?$/.test(field[item]))){
      validation[item] = true;
      isValidation = false;
    }
  });

  return {validation, isValidation};

}

export function fieldEmail(fieldEmail) {
  let reg = /^[\w\.\d-_]+@[\w\.\d-_]+\.\w{2,4}$/i;

  return reg.test(fieldEmail);
}

export function fieldNumbers(field) {
  var reg = /^\d+$/;

  return reg.test(field);
}

export function fieldPassword(fieldPassword) {
  if(fieldPassword.length >= 4 ){
    return true;
  } else {
    return false;
  }
}
