import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {NavLink} from "react-router-dom";
import Button from "@material-ui/core/Button";
import TableHeader from "../TableHeader/TableHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {fetchProductWaybills} from "../../../store/action/productWaybills";
import connect from "react-redux/es/connect/connect";
import {
  completeSaleDocument,
  fetchSalePDF,
  fetchSalePDFfetchSalePDFNoCash,
  removeSaleDocument
} from "../../../store/action/products";
import {fetchOutfitsPDF} from "../../../store/action/outfits";


class TableRows extends Component {


  state = {
    open: false,
  }


  render() {
    let summForDay = 0;

    // console.log(masterSumm);
    return (

      <React.Fragment>

        <TableRow>

          <TableCell align={'left'}>

          </TableCell>
          <TableCell align={'left'}>
            {this.props.row.date}
          </TableCell>

          {
            Object.keys(this.props.masters).map((master, index) => {
              summForDay = 0;
              this.props.row.working_day_master.map((workDay, i) => {
                if (this.props.masters[master] === workDay.user_id) {
                  summForDay = parseFloat(summForDay + parseFloat(workDay.salary)).toFixed(2);
                  summForDay = parseFloat(summForDay);
                }
              })
              return (
                <TableCell key={index} align={'left'}>{summForDay} UAH</TableCell>
              )

            })
          }


          {/*<TableCell align={'left'}>*/}
          {/*{this.props.row.master_income}*/}
          {/*</TableCell>*/}

          {/*<TableCell align={'left'}>*/}
          {/*{this.props.row.master_income}*/}
          {/*</TableCell>*/}

          {/*<TableCell align={'left'}>*/}
          {/*{this.props.row.service_income}*/}
          {/*</TableCell>*/}

          {/*<TableCell align={'left'}>*/}
          {/*{this.props.row.state}*/}
          {/*</TableCell>*/}

        </TableRow>

      </React.Fragment>


    )
  }
}


function mapDispathToProps(dispath) {
  return {
    completeSaleDocument: (id) => dispath(completeSaleDocument(id)),
    removeSaleDocument: (id) => dispath(removeSaleDocument(id)),
    fetchSalePDF: (id) => dispath(fetchSalePDF(id)),
    fetchSalePDFfetchSalePDFNoCash: (id) => dispath(fetchSalePDFfetchSalePDFNoCash(id)),
  }
}


export default connect(null, mapDispathToProps)(TableRows);
