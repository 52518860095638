import axios from 'axios'

// export const API = axios.create({
//   baseURL: 'http://auto-crm-back/api/'
// })

// export const API = axios.create({
//   baseURL: 'https://back.newlifeauto.com.ua/api/'
// })

export const API = axios.create({
  baseURL: 'https://dev-back.newlifeauto.com.ua/api/'
})
