import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {NavLink} from "react-router-dom";
import Button from "@material-ui/core/Button";
import TableHeader from "../TableHeader/TableHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {fetchProductWaybills} from "../../../store/action/productWaybills";
import connect from "react-redux/es/connect/connect";
import {
  completeSaleDocument,
  fetchSalePDF,
  fetchSalePDFfetchSalePDFNoCash,
  removeSaleDocument
} from "../../../store/action/products";
import {fetchOutfitsPDF} from "../../../store/action/outfits";


class TableRows extends Component {


  state = {
    open: false,
  }

  handleToggleSubtable = () => {
    this.setState({
      open: !this.state.open
    })
  }

  handlePerform = (id) => {
    this.props.completeSaleDocument(id).then((response) => {
      console.log(response);
      this.props.reRender();
    })
  }

  handleRemove = (id) => {
    this.props.removeSaleDocument(id).then((response) => {
      console.log(response);
      this.props.reRender();
    })
  }

  handlePrint = (id) => {
    this.props.fetchSalePDF(id).then((response) => {
      console.log(response);
    })
  }

  handlePrintNoCash = (id) => {
    this.props.fetchSalePDFfetchSalePDFNoCash(id).then((response) => {
      console.log(response);
    })
  }


  render() {
    let that = this;
    // console.log(this.props.row.details);
    return (

      <React.Fragment>

        <TableRow>


          {this.props.undertable ?
            <TableCell style={{width: 50}}>
              <IconButton aria-label="expand row" size="small" onClick={() => that.handleToggleSubtable()}>
                {that.state.open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              </IconButton>
            </TableCell>
            :
            null
          }

          {/*{this.props.row.map(function (col, key2) {*/}
          {/*return (*/}
          {/*<TableCell key={key2}*/}
          {/*align={key2 !== that.props.row.length - 1 || that.props.button ? 'left' : 'right'} >*/}
          {/*{col.item}*/}
          {/*</TableCell>*/}
          {/*)*/}
          {/*})}*/}


          {/*{this.props.button ?*/}
          {/*<TableCell align="right">*/}
          {/*<NavLink to="/waybills">*/}
          {/*<Button variant="contained" color="primary" size="small">*/}
          {/*<FormattedMessage id="app.history"/>*/}
          {/*</Button>*/}
          {/*</NavLink>*/}
          {/*</TableCell>*/}
          {/*:*/}
          {/*null*/}
          {/*}*/}

          <TableCell align={'left'}>
            {this.props.row.id}
          </TableCell>

          <TableCell align={'left'}>
            {this.props.row.created_at}
          </TableCell>

          <TableCell align={'left'}>
            {this.props.row.payment_type === "CASH" ? <FormattedMessage id="app.statusCash"/> : <FormattedMessage id="app.noCash"/>}
          </TableCell>

          {/*<TableCell align={'left'}>*/}
            {/*{this.props.row.supplier.name}*/}
          {/*</TableCell>*/}

          <TableCell align={'left'}>
            {this.props.row.state === "COMPLETED" ? <FormattedMessage id="app.statusCompleted"/> : null}
            {this.props.row.state === "DRAFT" ? <FormattedMessage id="app.statusDraft"/> : null}
            {this.props.row.state === "DELETED" ? <FormattedMessage id="app.statusDeleted"/> : null}
          </TableCell>

          <TableCell align={'right'}>

            {this.props.row.state !== 'DELETED' ?
              <Button variant="contained" className={'outfit-remove-btn'} color="primary" size="small" onClick={() => {
                this.handleRemove(this.props.row.id)
              }}>
                <FormattedMessage id="app.remove"/>
              </Button>
              :
              null
            }


            {this.props.row.state === 'DRAFT'?
              <Button variant="contained" className={'outfit-remove-btn'} color="primary" size="small" onClick={() => {this.handlePerform(this.props.row.id)}}>
                <FormattedMessage id="app.perform"/>
              </Button>
              :
              null
            }

            {this.props.row.payment_type !== "CASH" ?


              <Button variant="contained" size={'small'} color="secondary" className={"modal-popup__btn-i"}
                      onClick={() => this.handlePrint(this.props.row.id)}>
                <FormattedMessage id="app.print"/>
              </Button>

              :
              <Button variant="contained" size={'small'} color="secondary" className={"modal-popup__btn-i"}
                      onClick={() => this.handlePrintNoCash(this.props.row.id)}>
                <FormattedMessage id="app.print"/>
              </Button>
            }

          </TableCell>

        </TableRow>

        {this.props.undertable ?
          <TableRow>
            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
              <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Typography variant="h6" gutterBottom component="div">
                    <FormattedMessage id="app.products"/>
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHeader rows={this.props.headerUnderTable}/>
                    <TableBody>
                      {/*{console.log(this.props.row.details)}*/}
                      {this.props.row.details.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell  component="th" scope="row">
                              {item.id}
                            </TableCell>

                            <TableCell  component="th" scope="row">
                              {item.article !== null ? item.article.article : ''}
                            </TableCell>

                            <TableCell  component="th" scope="row">
                              {item.article !== null ? item.article.name : ''}
                            </TableCell>

                            <TableCell  component="th" scope="row">
                              {item.amount}
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {item.article !== null ? item.price : ''}
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {item.article !== null ? parseInt(item.article.stock) : ''}
                            </TableCell>
                          </TableRow>
                        )
                      })}


                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          :
          null
        }

      </React.Fragment>


    )
  }
}


function mapDispathToProps(dispath) {
  return {
    completeSaleDocument: (id) => dispath(completeSaleDocument(id)),
    removeSaleDocument: (id) => dispath(removeSaleDocument(id)),
    fetchSalePDF: (id) => dispath(fetchSalePDF(id)),
    fetchSalePDFfetchSalePDFNoCash: (id) => dispath(fetchSalePDFfetchSalePDFNoCash(id)),
  }
}


export default connect(null, mapDispathToProps)(TableRows);
