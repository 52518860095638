import {FETCH_APPOINTMENT_START,
  FETCH_APPOINTMENT_SUCCESS,
  FETCH_APPOINTMENT_ERROR} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";


// export function createTask(data) {
//   return async dispatch => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await API.post('projects/create', data, {headers: { Authorization: `Bearer ${token}` }});
//       return response;
//     } catch (e) {
//       console.log(e);
//     }
//
//   }
//
// }

export function fetchAppointment() {
  return async dispatch => {
    dispatch(fetchAppointmentStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get('appointment/index', {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchAppointmentSuccess(response.data))
    } catch (e) {
      console.log(fetchAppointmentError(e));
    }
  }
}

export function fetchAppointmentList(data = '') {
  return async dispatch => {
    dispatch(fetchAppointmentStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`appointment/list${data}`, {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchAppointmentSuccess(response.data))
    } catch (e) {
      console.log(fetchAppointmentError(e));
    }
  }
}

export function fetchAppointmentStart() {
  return {
    type: FETCH_APPOINTMENT_START
  }
}

export function fetchAppointmentSuccess(appointment) {
  return {
    type: FETCH_APPOINTMENT_SUCCESS,
    appointment
  }
}

export function fetchAppointmentError(e) {
  return {
    type: FETCH_APPOINTMENT_ERROR,
    error: e
  }
}

export function createAppointment(data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post('appointment/store', data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }

}

export function updateAppointment(id, data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.put(`appointment/update/${id}`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }
  }

}
