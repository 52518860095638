import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import './ShowBillsItemPopup.css';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage, injectIntl} from "react-intl";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHeader from "../../Tables/TableHeader/TableHeader";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import connect from "react-redux/es/connect/connect";
import {fetchRecipient} from "../../../store/action/bills";
import {getRecipienName} from "../../../sevices/recipientHelper";


class ShowBillsItemPopup extends Component {

  state = {
    recipient: [],
  }


  componentDidMount() {
    this.props.fetchRecipient().then((response) => {
      this.setState({
        recipient: response.data
      })
    });
  }

  handleClose = () => {
    this.props.handleClose();
  }

  render() {

    let headerWorkOrder = [
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.id"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.date"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.date"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.description"/>
      },

    ];

    let headerSpareParts = [
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.id"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.name"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.article"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.count"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.purchasePrice"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.sellingPrice"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.income"/>
      },

    ];

    let headerService = [
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.id"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.count"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.sellingPrice"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.income"/>
      },

    ];

    let spareParts = [];
    let service = [];

    // this.props.item.details.map((item) => {
    //   if (item.article_id !== null) {
    //     spareParts.push(item);
    //   } else {
    //     service.push(item);
    //   }
    // });

    if(this.props.item.work_order !== null){
      this.props.item.work_order.parts.map((item) => {
        spareParts.push(item);
      });
      this.props.item.work_order.services.map((item) => {
        service.push(item);
      });
    } else if (this.props.item.sale_document) {
      this.props.item.sale_document.details.map((item) => {
        spareParts.push(item);
      });
    }

    let payment_type = '';

    // if (this.props.item.client !== null && this.props.item.work_order.client.payment_type !== "CASH") {
    //   payment_type = this.props.intl.formatMessage({
    //     id: 'app.noCash',
    //   });
    // }

    if(this.state.recipient.length > 0){
      payment_type = getRecipienName(this.state.recipient, this.props.item.recipient_id)
    }

    console.log(payment_type);
    // if (this.props.item.client === null && this.props.item.sale_document.payment_type !== "CASH") {
    //   payment_type = this.props.intl.formatMessage({
    //     id: 'app.noCash',
    //   });
    // }

    return (
      <div className="modal-popup__window">
        <div className="modal-popup__header">
                <span>
                 Запись
                </span>
        </div>
        <div className="modal-popup__body">
          <div className="row">
            {this.props.item.client_id !== null ?
              <React.Fragment>
                <div className="col-md-4">
                  <div className="modal-popup__row">
                    <TextField
                      label=<FormattedMessage id="app.clientName"/>
                    value={this.props.item.work_order !== null ? this.props.item.work_order.client.first_name : this.props.item.sale_document.client.first_name}
                    className={"modal-popup__input"}
                    fullWidth
                    margin="normal"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="modal-popup__row">
                    <TextField
                      label=<FormattedMessage id="app.payments"/>
                    value={payment_type}
                    className={"modal-popup__input"}
                    fullWidth
                    margin="normal"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="modal-popup__row">
                    <TextField
                      label=<FormattedMessage id="app.phone"/>
                    value={this.props.item.work_order !== null ? this.props.item.work_order.client.phone : this.props.item.sale_document.client.phone}
                    className={"modal-popup__input"}
                    fullWidth
                    margin="normal"
                    />
                  </div>
                </div>
              </React.Fragment>
              :
              null
            }



            {this.props.item.work_order !== null ?
              <div className="col-md-12">
                <div className="bills-info-title">
                  <FormattedMessage id="app.outfit"/>
                </div>
                <TableContainer className={'bills-info'}>
                  <Table size="small" aria-label="a dense table">
                    <TableHeader rows={headerWorkOrder}/>
                    <TableBody>
                      <TableRow className={'site-table-row'}>
                        <TableCell align="left">{this.props.item.work_order.id}</TableCell>
                        <TableCell align="left">{this.props.item.work_order.data_start.substr(0, 10)}</TableCell>
                        <TableCell align="left">{this.props.item.work_order.data_end.substr(0, 10)}</TableCell>
                        <TableCell align="left">{this.props.item.work_order.description}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              :
              null
            }


            {spareParts.length > 0 ?
              <div className="col-md-12">
                <div className="bills-info-title">
                  <FormattedMessage id="app.usedSpareParts"/>
                </div>

                <TableContainer className={'bills-info'}>
                  <Table size="small" aria-label="a dense table">
                    <TableHeader rows={headerSpareParts}/>
                    <TableBody>
                      {spareParts.map((item, index) => {
                        return (
                          <TableRow className={'site-table-row'}>
                            <TableCell align="left">{item.article_id}</TableCell>
                            <TableCell align="left">{item.article.article.name}</TableCell>
                            <TableCell align="left">{item.article.article}</TableCell>
                            <TableCell align="left">{parseInt(item.amount)}</TableCell>
                            <TableCell align="left">{parseFloat(item.net_price).toFixed(2)}</TableCell>
                            <TableCell align="left">{parseFloat(item.price).toFixed(2)}</TableCell>
                            <TableCell align="left">{
                              parseFloat((parseFloat(item.price) - parseFloat(item.net_price)) * parseInt(item.amount)).toFixed(2)
                            }</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

              </div>
              :
              null
            }

            {service.length > 0 ?
              <div className="col-md-12">
                <div className="bills-info-title">
                  <FormattedMessage id="app.completedWorksAndServices"/>
                </div>

                <TableContainer className={'bills-info'}>
                  <Table size="small" aria-label="a dense table">
                    <TableHeader rows={headerService}/>
                    <TableBody>
                      {service.map((item, index) => {
                        return (
                          <TableRow className={'site-table-row'}>
                            <TableCell align="left">{item.service_id}</TableCell>
                            <TableCell align="left">{item.amount}</TableCell>
                            <TableCell align="left">{parseFloat(item.price).toFixed(2)}</TableCell>
                            <TableCell align="left">
                              {parseFloat(parseFloat(item.price) * parseInt(item.amount)).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

              </div>
              :
              null
            }
          </div>

          <br/>
          <div className="bills-info-title">
            Доход {parseFloat(this.props.item.invoice_income).toFixed(2)}
          </div>
          <div className="bills-info-title">
            Сумма {parseFloat(this.props.item.invoice_total).toFixed(2)}
          </div>

        </div>
        <div className="modal-popup__footer">
          <div className="modal-popup__btn">
            <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={this.handleClose}>
              <FormattedMessage id="app.close"/>
            </Button>
            {/*<Button variant="contained" color="primary" className={"modal-popup__btn-i"}>*/}
            {/*<FormattedMessage id="app.save"/>*/}
            {/*</Button>*/}
          </div>
        </div>
      </div>
    )
  }
}

function mapDispathToProps(dispath) {
  return {
    fetchRecipient: () => dispath(fetchRecipient()),
  }
}

export default connect(null, mapDispathToProps)(injectIntl(ShowBillsItemPopup))
