import {
  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  FETCH_DELIVERY_SUCCESS,
  FETCH_PRODUCTS_ALL_SUCCESS,
  FETCH_DELIVERY_START
} from "../action/actionTypes";

const initialState = {
  products: {},
  productsAll: {},
  delivery: [],
  loading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS_START:
      return{
        ...state, loading: true, products: []
      }

    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state, loading: false, products: action.products
      }
    case FETCH_PRODUCTS_ALL_SUCCESS:
      return {
        ...state, loading: false, productsAll: action.productsAll
      }

    case FETCH_PRODUCTS_ERROR:
      return {
        ...state, loading: false, error: action.error
      }


    case FETCH_DELIVERY_START:
      return {
        ...state, loading: true,
      }


    case FETCH_DELIVERY_SUCCESS:
      return {
        ...state, loading: false, delivery: action.delivery
      }

    default: return state
  }
}
