import {
  FETCH_OUTFITS_START, FETCH_OUTFITS_SUCCESS, FETCH_OUTFITS_ERROR
} from "../action/actionTypes";

const initialState = {
  outfits: {},
  loading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_OUTFITS_START:
      return{
        ...state, loading: true
      }

    case FETCH_OUTFITS_SUCCESS:
      return {
        ...state, loading: false, outfits: action.outfits
      }

    case FETCH_OUTFITS_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    default: return state
  }
}
