import {FETCH_DRAFTS_START, FETCH_DRAFTS_SUCCESS, FETCH_DRAFTS_ERROR} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";



export function fetchDrafts() {
  return async dispatch => {
    dispatch(fetchDraftsStart())
    try {
      const token = localStorage.getItem('token');
      const response = await API.get('task/draft', {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchDraftsSuccess(response.data))
    } catch (e) {
      console.log(fetchDraftsError(e));
    }
  }
}

export function fetchDraftsStart() {
  return {
    type: FETCH_DRAFTS_START
  }
}

export function fetchDraftsSuccess(drafts) {
  return {
    type: FETCH_DRAFTS_SUCCESS,
    drafts
  }
}

export function fetchDraftsError(e) {
  return {
    type: FETCH_DRAFTS_ERROR,
    error: e
  }
}
