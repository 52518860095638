import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";

import './Clients.css'
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import {NavLink} from "react-router-dom";
import TableHeader from "../../components/Tables/TableHeader/TableHeader.js";
import connect from "react-redux/es/connect/connect";
import {fetchClients} from "../../store/action/clients";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import AddClientPopup from "../../components/ModalPopups/AddClientPopup/AddClientPopup";
import TextField from "@material-ui/core/TextField";

class Clients extends Component {

  state = {
    isOpen: false,
    perPage: 30,
    currentPage: 1,
    search: '',
    isEdit: false,
    indexEdit: null,
    isHistoryClient: false
  }

  componentDidMount() {
    this.fetchDataCreate();

    document.title = this.props.intl.formatMessage({
      id: 'app.clients',
    });
  }

  addRecordingHandle = () => {
    this.setState({ isOpen: true })
  }

  closePopupAddRecording = () => {
    this.setState({ isOpen: false, isEdit: false, })
  }

  handleChangePage = (event, newPage) => {
    this.setState({ currentPage: newPage + 1 })
    setTimeout(() => this.fetchDataCreate())
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ perPage: parseInt(event.target.value, 10) })
    this.setState({ currentPage: 1 })
    setTimeout(() => this.fetchDataCreate())
  }

  reRender = () => {
    this.fetchDataCreate();
  }

  editClient = (index) =>{
    this.setState({
      isOpen: true,
      isEdit: true,
      indexEdit: index
    })
  }

  handleSearch = () => {
    this.fetchDataCreate();
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.fetchDataCreate(true);
    }
  }

  filterReset = () => {
    this.setState({
      search: '',
    })

    setTimeout(() => this.fetchDataCreate(true))
  }

  fetchDataCreate = (filter = false) => {

    let data = '',
      curPage = this.state.currentPage,
      perPage = this.state.perPage,
      search = this.state.search !== '' ? this.state.search : null;

    if(filter) {
      curPage = 1;
      this.setState({currentPage: 1});
    }

    // data = data + `?paginate=${curPage},${perPage}`;
    data = data + `?page=${curPage}&limit=${perPage}`;

    if(search !== null) {
      search = search.replace("+", "")
    }
    if (search !== null) data = data + `&filter=first_name:${search},phone:${search}&registered_id=${search}&model=${search}&brand=${search}`;

    this.props.fetchClients(data)
  }

  render() {

    let headerTable = [
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.clientName"/>
      },
      // {
      //   colspan: '0',
      //   align: 'left',
      //   item: <FormattedMessage id="app.lastName"/>
      // },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.clientPhone"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.carBrand"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.сarModel"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.yearOfIssue"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.stateRegisterNumber"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: ''
      },
    ];

    let that = this;

    return (
      <div className="clients">
        <div className="page-title">
           <span className={'header__name'}>
              <FormattedMessage id="app.clients"/>
            </span>
        </div>

        <div className="filter-head">

          <div className="filter-head__left">
            <div className="filter-client-wrap">
              <TextField className="filter-client-select" onChange={(event) => this.setState({search: event.target.value})}
                         size="small"
                         value={this.state.search}
                         label={<FormattedMessage id="app.search"/> }
                         onKeyDown={this._handleKeyDown}
                         variant="outlined"/>
            </div>
            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.filterReset}>
              <FormattedMessage id="app.reset"/>
            </Button>
            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={() => this.fetchDataCreate( true)}>
              <FormattedMessage id="app.search"/>
            </Button>
          </div>

          <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.addRecordingHandle}>
            <FormattedMessage id="app.addNote"/>
          </Button>
        </div>


        {this.state.isOpen ?
          <PopupWrapper classPopup={'add-recording'} closePopup={this.closePopupAddRecording}>
            <AddClientPopup reRender={this.reRender} isEdit={this.state.isEdit} index={this.state.indexEdit}/>
          </PopupWrapper>
          : ''}

        <TableContainer className={'clients-table' + (this.props.loading ? ' loading-table' : '')}>
          <Table size="small" aria-label="a dense table">
            <TableHeader rows={headerTable}/>
              <TableBody>
                {
                  this.props.clients.hasOwnProperty('data') ?
                    Object.keys(this.props.clients.data).map((item, index) => {

                      return (
                        <TableRow key={index} className={'site-table-row'}>
                          <TableCell align="left"> { this.props.clients.data[item].first_name  } </TableCell>
                          <TableCell align="left"> { this.props.clients.data[item].phone } </TableCell>
                          <TableCell align="left"> { this.props.clients.data[item].car.brand } </TableCell>
                          <TableCell align="left"> { this.props.clients.data[item].car.model } </TableCell>
                          <TableCell align="left"> { this.props.clients.data[item].car.year } </TableCell>
                          <TableCell align="left"> { this.props.clients.data[item].car.registered_id } </TableCell>
                          <TableCell align="right">
                            <Button className={'client-edit-btn'} variant="contained" color="primary" size="small" onClick={() => that.editClient(index)}>
                              <FormattedMessage id="app.change"/>
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    null
                }


              </TableBody>



            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 30, 50, 100]}
                  colSpan={8}
                  count={this.props.clients.total}
                  rowsPerPage={this.state.perPage}
                  page={this.state.currentPage - 1}
                  labelRowsPerPage={<FormattedMessage id="app.rowsPerPage"/>}
                  SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>


          </Table>
        </TableContainer>

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.clients.loading,
    clients: state.clients.clients
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchClients: (page, limit) => dispath(fetchClients(page, limit))
  }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(Clients));

