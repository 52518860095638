import React, {Component} from 'react'


class Footer extends Component {

  render() {

    return (
      <footer className={'footer'}>

      </footer>
    )
  }
}

export default Footer
