import {
  FETCH_EXPENSES_START,
  FETCH_EXPENSES_SUCCESS,
  FETCH_EXPENSES_ERROR
} from "../action/actionTypes";



const initialState = {
  expenses: {},
  loading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EXPENSES_START:
      return{
        ...state, loading: true
      }

    case FETCH_EXPENSES_SUCCESS:
      return {
        ...state, loading: false, expenses: action.expenses
      }

    case FETCH_EXPENSES_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    default: return state
  }
}
