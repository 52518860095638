import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import './AddClientPopup.css';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage, injectIntl} from "react-intl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  clearClientCarModels,
  createClient, editClientSend,
  fetchClientCarModels,
  fetchClientCarsBrand
} from "../../../store/action/clients";
import connect from "react-redux/es/connect/connect";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../../sevices/validationService";
import InputMask from 'react-input-mask';


class AddClientPopup extends Component {

  state = {
    isEditingBrand: false,
    editingTimeOut: false,
    data: {
      first_name: '',
      last_name: ' ',
      phone: '',
      address: '',
      brand: '',
      model: '',
      year: '',
      registered_id: '',
      car_body: '',
      engine: '',
      chasse: '',
      payment_type: 'CASH',
      payer: '',
      reason: '',
      recipient: ''
    },

    validation: {
      first_name: false,
      last_name: false,
      phone: false,
      address: false,
      brand: false,
      model: false,
      year: false,
      registered_id: false,
      car_body: false,
      engine: false,
      chasse: false,
    }
  }

  componentDidMount() {
    this.props.fetchClientCarsBrand()
    if (this.props.isEdit) {
      this.props.fetchClientCarModels(this.props.clients.data[this.props.index].car.brand)

      let newData = {
        first_name: this.props.clients.data[this.props.index].first_name,
        last_name: ' ',
        phone: this.props.clients.data[this.props.index].phone,
        address: this.props.clients.data[this.props.index].address,
        brand: this.props.clients.data[this.props.index].car.brand,
        model: this.props.clients.data[this.props.index].car.model,
        year: '' + this.props.clients.data[this.props.index].car.year,
        registered_id: this.props.clients.data[this.props.index].car.registered_id,
        car_body: this.props.clients.data[this.props.index].car.car_body,
        engine: this.props.clients.data[this.props.index].car.engine,
        chasse: this.props.clients.data[this.props.index].car.chasse,
        payment_type: this.props.clients.data[this.props.index].payment_type,
        payer: this.props.clients.data[this.props.index].payer,
        reason: this.props.clients.data[this.props.index].reason,
        recipient: this.props.clients.data[this.props.index].recipient
      };

      this.setState({
        data: newData,
      })


    }

  }


  handleChangeInput = (event) => {
    let newVal = this.state.data;
    let field = event.target.dataset.name;
    newVal[field] = event.target.value;
    this.setState({
      data: newVal
    });
  }

  handleAutocompleteChangeInput = (event) => {

    if (event === null) return;

    if (event.brand) {
      this.props.fetchClientCarModels(event.brand);

      let newVal = this.state.data;
      newVal.brand = event.brand;
      newVal.model = '';
      this.setState({
        data: newVal
      });
      setTimeout(() => {
        this.refs[`autocomplete-model`].getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click()
        this.refs[`autocomplete-brand`].getElementsByClassName('MuiInputBase-input')[0].click()

      }, 1);
    }

    if (event.model) {
      let newVal = this.state.data;
      newVal.model = event.model;
      this.setState({
        data: newVal
      });
    }

    if (event.year) {
      let newVal = this.state.data;
      newVal.year = event.year;
      this.setState({
        data: newVal
      });
    }
    if (event.body) {
      let newVal = this.state.data;
      newVal.car_body = event.body;
      this.setState({
        data: newVal
      });
    }

  }

  handleAutocompleteChangeInputOnKeyUp = (event, text) => {

    if (event === null) return;

    if (event.target.dataset.name === 'brand') {
      let newVal = this.state.data;
      newVal.brand = text;
      newVal.model = '';
      this.setState({
        data: newVal
      });
      setTimeout(() => {
        this.refs[`autocomplete-model`].getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click()
        this.refs[`autocomplete-brand`].getElementsByClassName('MuiInputBase-input')[0].click()

      }, 1);
    }

    if (event.target.dataset.name === 'model') {

      let newVal = this.state.data;
      newVal.model = text;
      this.setState({
        data: newVal
      });
    }

  }

  handleClose = () => {
    this.props.handleClose();
  }

  createClientHandler = () => {
    let dataForm = this.state.data;

    let validation = fieldEmpty(dataForm, ['payer', 'reason', 'recipient']);


    if (!validation.isValidation) {
      this.setState({
        validation: validation.validation
      });
      return;
    }

    dataForm.phone = dataForm.phone.replace('(', '');
    dataForm.phone = dataForm.phone.replace(')', '');
    dataForm.phone = dataForm.phone.replace('+', '');

    this.props.createClient(this.state.data).then((response) => {

      if (response.status === 200) {
        this.props.handleClose();
        this.props.reRender();
      }
    });
  }

  editClientHandler = () => {
    let dataForm = this.state.data;

    // let validation = fieldEmpty(dataForm);
    let validation = fieldEmpty(dataForm, ['payer', 'reason', 'recipient']);

    if (!validation.isValidation) {
      this.setState({
        validation: validation.validation
      });
      return;
    }
    this.props.editClientSend(this.props.clients.data[this.props.index].id, this.state.data).then((response) => {
      if (response.status === 200) {
        this.props.handleClose();
        this.props.reRender();
      }
    });
  }

  handleChangePayment = (event) => {
    let newVal = this.state.data;
    newVal.payment_type = event.target.value;

    this.setState({
      data: newVal
    })
  }

  render() {

    const fio = this.props.intl.formatMessage({
      id: 'app.fio',
    });
    const fop = this.props.intl.formatMessage({
      id: 'app.fop',
    });

    let carsBrand = this.props.carsBrand;
    let carModels = this.props.carModels;


    const years = [
      {year: '1980'}, {year: '1981'}, {year: '1982'},
      {year: '1983'}, {year: '1984'}, {year: '1985'},
      {year: '1986'}, {year: '1987'}, {year: '1988'},
      {year: '1989'}, {year: '1990'}, {year: '1991'},
      {year: '1992'}, {year: '1993'}, {year: '1994'},
      {year: '1995'}, {year: '1996'}, {year: '1997'},
      {year: '1998'}, {year: '1999'}, {year: '2000'},
      {year: '2001'}, {year: '2002'}, {year: '2003'},
      {year: '2004'}, {year: '2005'}, {year: '2006'},
      {year: '2007'}, {year: '2008'}, {year: '2009'},
      {year: '2010'}, {year: '2011'}, {year: '2012'},
      {year: '2013'}, {year: '2014'}, {year: '2015'},
      {year: '2016'}, {year: '2017'}, {year: '2018'},
      {year: '2019'}, {year: '2020'}
    ];

    const carBody = [
      {body: 'грузовой'},
      {body: 'седан'},
      {body: 'купе'},
      {body: 'хетчбэк'},
      {body: 'универсал'},
      {body: 'кросовер'},
      {body: 'кабриолет'},
      {body: 'пикап'},
      {body: 'лифтбэк'},
      {body: 'лимузин'},
    ];

    let editItem;
    let brandEdit;
    let modelEdit;
    let carYearEdit;
    let carBodyEdit;

    if (this.props.isEdit) {
      editItem = this.props.clients.data[this.props.index];


      if (this.props.carsBrand.length > 0) {
        this.props.carsBrand.map((item) => {
          if (item.brand === editItem.car.brand) {
            brandEdit = item;
          }
        });
      }


      if (this.props.carModels.length > 0) {
        this.props.carModels.map((item) => {
          if (item.model === editItem.car.model) {
            modelEdit = item;
          }
        });
      }

      years.map((item) => {
        if (parseInt(item.year) === parseInt(editItem.car.year)) {
          carYearEdit = item;
        }
      });

      carBody.map((item) => {
        if (item.body === editItem.car.car_body) {
          carBodyEdit = item;
        }
      });
    }


    return (
      <div className="modal-popup__window">
        <div className="modal-popup__header">
          <span>
            {this.props.isEdit ?
              <FormattedMessage id="app.edit"/>
              :
              <FormattedMessage id="app.addNote"/>
            }
          </span>
        </div>
        <div className="modal-popup__body">

          {this.props.isEdit ?
            <div className="row">
              <div className="col-md-12">
                <div className="modal-popup__row">

                  <TextField
                    defaultValue={editItem.first_name}
                    error={this.state.validation.first_name}
                    label={this.state.data.payment_type === 'CASH' ? fio : fop}
                    className={"modal-popup__input modal-field"}
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="normal"
                    inputProps={{'data-name': 'first_name'}}
                    onKeyUp={this.handleChangeInput}
                  />
                </div>
              </div>

              {this.state.data.payment_type !== 'CASH' ?
                <React.Fragment>
                  <div className="col-md-12">
                    <div className="modal-popup__row">
                      <TextField
                        defaultValue={editItem.payer}
                        label={<FormattedMessage id="app.payer"/>}
                        className={"modal-popup__input modal-field"}
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="normal"
                        inputProps={{'data-name': 'payer'}}
                        onKeyUp={this.handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-popup__row">
                      <TextField
                        defaultValue={editItem.reason}
                        label={<FormattedMessage id="app.reason"/>}
                        className={"modal-popup__input modal-field"}
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="normal"
                        inputProps={{'data-name': 'reason'}}
                        onKeyUp={this.handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-popup__row">
                      <TextField
                        defaultValue={editItem.recipient}
                        label={<FormattedMessage id="app.recipient"/>}
                        className={"modal-popup__input modal-field"}
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="normal"
                        inputProps={{'data-name': 'recipient'}}
                        onKeyUp={this.handleChangeInput}
                      />
                    </div>
                  </div>
                </React.Fragment>
                :
                null
              }


              <div className="col-md-6">
                <div className="modal-popup__row">
                  <InputMask
                    mask="+380(99)9999999"
                    disabled={false}
                    maskChar="_"
                    defaultValue={editItem.phone}
                    onChange={(event) => this.handleChangeInput(event)}
                    inputProps={{'data-name': 'phone'}}
                  >
                    {() => <TextField
                      className={"modal-popup__input modal-field"}
                      fullWidth
                      variant="outlined"
                      size="small"
                      margin="normal"
                      inputProps={{'data-name': 'phone'}}
                      label={<FormattedMessage id="app.clientPhone"/>}
                      error={this.state.validation.phone}/>
                    }
                  </InputMask>
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <TextField
                    defaultValue={editItem.address}
                    error={this.state.validation.address}
                    label={<FormattedMessage id="app.address"/>}
                    className={"modal-popup__input modal-field"}
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="normal"
                    inputProps={{'data-name': 'address'}}
                    onKeyUp={this.handleChangeInput}
                  />


                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  {this.props.carsBrand.length > 0 ?
                    <Autocomplete
                      freeSolo
                      className="filter-client-select"
                      value={brandEdit}
                      ref={`autocomplete-brand`}
                      options={carsBrand}
                      getOptionLabel={(option) => option.brand}
                      onChange={(event, newValue) => {
                        this.handleAutocompleteChangeInput(newValue, event)
                      }}
                      onInputChange={(event, newValue) => {
                        this.handleAutocompleteChangeInputOnKeyUp(event, newValue)
                      }}
                      renderInput={(params) => <TextField {...params}
                                                          label={<FormattedMessage id="app.carBrand"/>}
                                                          variant="outlined"
                                                          className={"modal-popup__input--autocomplete"}
                                                          error={this.state.validation.brand}
                                                          inputProps={{
                                                            ...params.inputProps,
                                                            'data-name': 'brand'
                                                          }}
                      />}
                    />
                    :
                    null
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  {this.props.carModels.length > 0 ?
                    <Autocomplete
                      freeSolo
                      value={modelEdit}
                      className="filter-client-select"
                      ref={`autocomplete-model`}
                      id="filter-client"
                      options={carModels}
                      getOptionLabel={(option) => option.model}
                      onChange={(event, newValue) => {
                        this.handleAutocompleteChangeInput(newValue, event)
                      }}
                      onInputChange={(event, newValue) => {
                        this.handleAutocompleteChangeInputOnKeyUp(event, newValue)
                      }}
                      renderInput={(params) => <TextField {...params}
                                                          label={<FormattedMessage id="app.сarModel"/>}
                                                          variant="outlined"
                                                          className={"modal-popup__input--autocomplete"}
                                                          error={this.state.validation.model}
                                                          inputProps={{
                                                            ...params.inputProps,
                                                            'data-name': 'model'
                                                          }}
                      />}
                    />
                    :
                    null
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">


                  <Autocomplete
                    freeSolo
                    defaultValue={carYearEdit}
                    className="filter-client-select"
                    id="filter-client"
                    options={years}
                    getOptionLabel={(option) => option.year}
                    onChange={(event, newValue) => {
                      this.handleAutocompleteChangeInput(newValue, event)
                    }}
                    renderInput={(params) => <TextField {...params}
                                                        label={<FormattedMessage id="app.yearOfIssue"/>}
                                                        variant="outlined"
                                                        className={"modal-popup__input--autocomplete"}
                                                        error={this.state.validation.year}
                                                        inputProps={{
                                                          ...params.inputProps,
                                                          'data-name': 'year'
                                                        }}
                    />}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <TextField
                    defaultValue={editItem.car.registered_id}
                    error={this.state.validation.registered_id}
                    className={'modal-field'}
                    label={<FormattedMessage id="app.stateRegisterNumber"/>}
                    variant="outlined"
                    size="small"
                    inputProps={{'data-name': 'registered_id'}}
                    onKeyUp={this.handleChangeInput}
                  />

                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <Autocomplete
                    freeSolo
                    defaultValue={carBodyEdit}
                    className="filter-client-select"
                    id="filter-client"
                    options={carBody}
                    getOptionLabel={(option) => option.body}
                    onChange={(event, newValue) => {
                      this.handleAutocompleteChangeInput(newValue, event)
                    }}
                    renderInput={(params) => <TextField {...params}
                                                        label={<FormattedMessage id="app.bodyType"/>}
                                                        variant="outlined"
                                                        className={"modal-popup__input--autocomplete"}
                                                        error={this.state.validation.car_body}
                                                        inputProps={{
                                                          ...params.inputProps,
                                                          'data-name': 'car_body'
                                                        }}
                    />}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <TextField
                  defaultValue={editItem.car.engine}
                  error={this.state.validation.engine}
                  className={'modal-field'}
                  label={<FormattedMessage id="app.engine"/>}
                  variant="outlined"
                  size="small"
                  inputProps={{'data-name': 'engine'}}
                  onKeyUp={this.handleChangeInput}
                />

              </div>
              <div className="col-md-6">
                <TextField
                  defaultValue={editItem.car.chasse}
                  error={this.state.validation.chasse}
                  className={'modal-field'}
                  label={'Шаси'}
                  variant="outlined"
                  size="small"
                  inputProps={{'data-name': 'chasse'}}
                  onKeyUp={this.handleChangeInput}
                />

              </div>
              <div className="col-md-6 client-popup-radio">

                <FormLabel><FormattedMessage id="app.payments"/></FormLabel>
                <RadioGroup className={'radio-group'} name="gender1" defaultValue={editItem.payment_type}
                            onChange={this.handleChangePayment}>
                  <FormControlLabel value={'CASH'} control={<Radio/>} label={<FormattedMessage id="app.cash"/>}/>
                  <FormControlLabel value={'NO_CASH'} control={<Radio/>} label={<FormattedMessage id="app.noCash"/>}/>
                </RadioGroup>
              </div>
            </div>
            :
            <div className="row">
              <div className="col-md-12">
                <div className="modal-popup__row">
                  <TextField
                    label={this.state.data.payment_type === 'CASH' ? fio : fop}
                    className={"modal-popup__input modal-field"}
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="normal"
                    inputProps={{'data-name': 'first_name'}}
                    onKeyUp={this.handleChangeInput}
                    error={this.state.validation.first_name}
                  />
                </div>
              </div>

              {this.state.data.payment_type !== 'CASH' ?
                <React.Fragment>
                  <div className="col-md-12">
                    <div className="modal-popup__row">
                      <TextField
                        id="standard-multiline-flexible"
                        label={<FormattedMessage id="app.payer"/>}
                        className={"modal-popup__input modal-field"}
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="normal"
                        inputProps={{'data-name': 'payer'}}
                        onKeyUp={this.handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-popup__row">
                      <TextField
                        id="standard-multiline-flexible"
                        label={<FormattedMessage id="app.reason"/>}
                        className={"modal-popup__input modal-field"}
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="normal"
                        inputProps={{'data-name': 'reason'}}
                        onKeyUp={this.handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-popup__row">
                      <TextField
                        id="standard-multiline-flexible"
                        label={<FormattedMessage id="app.recipient"/>}
                        className={"modal-popup__input modal-field"}
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="normal"
                        inputProps={{'data-name': 'recipient'}}
                        onKeyUp={this.handleChangeInput}
                      />
                    </div>
                  </div>
                </React.Fragment>
                :
                null
              }


              <div className="col-md-6">
                <div className="modal-popup__row">
                  <InputMask
                    mask="+380(99)9999999"
                    disabled={false}
                    maskChar="_"
                    onChange={(event) => this.handleChangeInput(event)}
                  >
                    {() => <TextField
                      className={"modal-popup__input modal-field"}
                      fullWidth
                      variant="outlined"
                      size="small"
                      margin="normal"
                      inputProps={{'data-name': 'phone'}}
                      label={<FormattedMessage id="app.clientPhone"/>}
                      error={this.state.validation.phone}/>
                    }
                  </InputMask>
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <TextField
                    id="standard-multiline-flexible"
                    label={<FormattedMessage id="app.address"/>}
                    className={"modal-popup__input modal-field"}
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="normal"
                    inputProps={{'data-name': 'address'}}
                    onKeyUp={this.handleChangeInput}
                    error={this.state.validation.address}
                  />


                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <Autocomplete
                    freeSolo
                    className="filter-client-select"
                    id="filter-client"
                    ref={`autocomplete-brand`}
                    options={carsBrand}
                    getOptionLabel={(option) => option.brand}
                    onChange={(event, newValue) => {
                      this.handleAutocompleteChangeInput(newValue, event)
                    }}
                    onInputChange={(event, newValue) => {
                      this.handleAutocompleteChangeInputOnKeyUp(event, newValue)
                    }}
                    renderInput={(params) => <TextField {...params}
                                                        label={<FormattedMessage id="app.carBrand"/>}
                                                        variant="outlined"
                                                        className={"modal-popup__input--autocomplete"}
                                                        error={this.state.validation.brand}
                                                        inputProps={{
                                                          ...params.inputProps,
                                                          'data-name': 'brand'
                                                        }}
                    />}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <Autocomplete
                    freeSolo
                    className="filter-client-select"
                    ref={`autocomplete-model`}
                    id="filter-client"
                    options={carModels}
                    getOptionLabel={(option) => option.model}
                    onChange={(event, newValue) => {
                      this.handleAutocompleteChangeInput(newValue, event)
                    }}
                    onInputChange={(event, newValue) => {
                      this.handleAutocompleteChangeInputOnKeyUp(event, newValue)
                    }}
                    renderInput={(params) => <TextField {...params}
                                                        label={<FormattedMessage id="app.сarModel"/>}
                                                        variant="outlined"
                                                        className={"modal-popup__input--autocomplete"}
                                                        error={this.state.validation.model}
                                                        inputProps={{
                                                          ...params.inputProps,
                                                          'data-name': 'model'
                                                        }}
                    />}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <Autocomplete
                    freeSolo
                    className="filter-client-select"
                    id="filter-client"
                    options={years}
                    getOptionLabel={(option) => option.year}
                    onChange={(event, newValue) => {
                      this.handleAutocompleteChangeInput(newValue, event)
                    }}
                    renderInput={(params) => <TextField {...params}
                                                        label={<FormattedMessage id="app.yearOfIssue"/>}
                                                        variant="outlined"
                                                        className={"modal-popup__input--autocomplete"}
                                                        error={this.state.validation.year}
                                                        inputProps={{
                                                          ...params.inputProps,
                                                          'data-name': 'year'
                                                        }}
                    />}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <TextField
                    className={'modal-field'}
                    label={<FormattedMessage id="app.stateRegisterNumber"/>}
                    defaultValue=""
                    variant="outlined"
                    size="small"
                    inputProps={{'data-name': 'registered_id'}}
                    onKeyUp={this.handleChangeInput}
                    error={this.state.validation.registered_id}
                  />

                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <Autocomplete
                    freeSolo
                    className="filter-client-select"
                    id="filter-client"
                    options={carBody}
                    getOptionLabel={(option) => option.body}
                    onChange={(event, newValue) => {
                      this.handleAutocompleteChangeInput(newValue, event)
                    }}
                    renderInput={(params) => <TextField {...params}
                                                        label={<FormattedMessage id="app.bodyType"/>}
                                                        variant="outlined"
                                                        className={"modal-popup__input--autocomplete"}
                                                        error={this.state.validation.car_body}
                                                        inputProps={{
                                                          ...params.inputProps,
                                                          'data-name': 'car_body'
                                                        }}
                    />}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <TextField
                  className={'modal-field'}
                  label={<FormattedMessage id="app.engine"/>}
                  defaultValue=""
                  variant="outlined"
                  size="small"
                  inputProps={{'data-name': 'engine'}}
                  onKeyUp={this.handleChangeInput}
                  error={this.state.validation.engine}
                />

              </div>
              <div className="col-md-6">
                <TextField
                  className={'modal-field'}
                  label={'Шаси'}
                  defaultValue=""
                  variant="outlined"
                  size="small"
                  inputProps={{'data-name': 'chasse'}}
                  onKeyUp={this.handleChangeInput}
                  error={this.state.validation.chasse}
                />

              </div>
              <div className="col-md-6 client-popup-radio">


                <FormLabel><FormattedMessage id="app.payments"/></FormLabel>
                <RadioGroup className={'radio-group'} name="gender1" value={this.state.data.payment_type}
                            onChange={this.handleChangePayment}>
                  <FormControlLabel value={'CASH'} control={<Radio/>} label={<FormattedMessage id="app.cash"/>}/>
                  <FormControlLabel value={'NO_CASH'} control={<Radio/>} label={<FormattedMessage id="app.noCash"/>}/>
                </RadioGroup>
              </div>
            </div>
          }


        </div>
        <div className="modal-popup__footer">
          <div className="modal-popup__btn">
            <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={this.handleClose}>
              <FormattedMessage id="app.close"/>
            </Button>
            {this.props.isEdit ?
              <Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                      onClick={this.editClientHandler}>
                <FormattedMessage id="app.change"/>
              </Button>
              :
              <Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                      onClick={this.createClientHandler}>
                <FormattedMessage id="app.save"/>
              </Button>
            }
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {

  return {
    carsBrand: state.clients.carsBrand,
    carModels: state.clients.carModels,
    clients: state.clients.clients
  }
}


function mapDispathToProps(dispath) {
  return {
    fetchClientCarsBrand: () => dispath(fetchClientCarsBrand()),
    fetchClientCarModels: (brand) => dispath(fetchClientCarModels(brand)),
    createClient: (data) => dispath(createClient(data)),
    editClientSend: (id, data) => dispath(editClientSend(id, data)),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(AddClientPopup));

