import {
  FETCH_WORKERS_STATISTIC_START,
  FETCH_WORKERS_STATISTIC_SUCCESS,
  FETCH_WORKERS_STATISTIC_ERROR,
  FETCH_WORK_DAYS_SUCCESS,
  FETCH_WORK_DAYS_ERROR,
  FETCH_WORK_TODAY_SUCCESS,
  FETCH_WORK_TODAY_ERROR,
  FETCH_MASTERS_SALARY_SUCCESS,
  FETCH_MASTERS_SALARY_ERROR,
  FETCH_MASTERS_SALARY_INFO_SUCCESS, FETCH_MASTERS_SALARY_INFO_ERROR, FETCH_WORK_DAYS_START
} from "../action/actionTypes";



const initialState = {
  workersStatistic: {},
  workDays: [],
  workingDay: {},
  paymentsSalary: {},
  mastersSalary: [],
  loading: false,
  workDaysLoading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKERS_STATISTIC_START:
      return{
        ...state, loading: true
      }

    case FETCH_WORKERS_STATISTIC_SUCCESS:
      return {
        ...state, loading: false, workersStatistic: action.workersStatistic
      }
    case FETCH_WORKERS_STATISTIC_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    case FETCH_WORK_DAYS_START:
      return {
        ...state, workDaysLoading: true
      }

    case FETCH_WORK_DAYS_SUCCESS:
      return {
        ...state, workDaysLoading: false, workDays: action.workDays
      }

    case FETCH_WORK_DAYS_ERROR:
      return {
        ...state, loading: false, error: action.error
      }


    case FETCH_WORK_TODAY_SUCCESS:
      return {
        ...state, loading: false, workingDay: action.workingDay
      }

    case FETCH_WORK_TODAY_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    case FETCH_MASTERS_SALARY_SUCCESS:
      return {
        ...state, loading: false, paymentsSalary: action.paymentsSalary
      }

    case FETCH_MASTERS_SALARY_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    case FETCH_MASTERS_SALARY_INFO_SUCCESS:
      return {
        ...state, loading: false, mastersSalary: action.mastersSalary
      }

    case FETCH_MASTERS_SALARY_INFO_ERROR:
      return {
        ...state, loading: false, error: action.error
      }
    default: return state
  }
}
