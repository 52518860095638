import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import './Bills.css'
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import TableHeader from "../../components/Tables/TableHeader/TableHeader.js";
import connect from "react-redux/es/connect/connect";
import {fetchBills, fetchRecipient} from "../../store/action/bills";
import ShowBillsItemPopup from "../../components/ModalPopups/ShowBillsItemPopup/ShowBillsItemPopup";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {getRecipienName, getRecipientType} from "../../sevices/recipientHelper";

class Bills extends Component {

  state = {
    isOpen: false,
    perPage: 30,
    currentPage: 1,
    dateStart: '',
    dateEnd: '',
    recipient: [],
    item: ''
  }

  componentDidMount() {
    // this.props.fetchBills();
    this.fetchDataCreate();

    this.props.fetchRecipient().then((response) => {
      this.setState({
        recipient: response.data
      })
    });


    document.title = this.props.intl.formatMessage({
      id: 'app.bills',
    });
  }

  addBillsItemHandle = () => {
    this.setState({
      isOpen: true
    })
  }
  closePopupAddBillsItem = () => {
    this.setState({
      isOpen: false
    })
  }

  showBillHandle = (item) => {
    this.setState({
      item: item,
      isOpen: true
    })
  }

  fetchDataCreate = (filter = false) => {
    let data = '',
      curPage = this.state.currentPage,
      perPage = this.state.perPage,
      dateStart = this.state.dateStart !== '' ? this.state.dateStart : null,
      dateEnd = this.state.dateEnd !== '' ? this.state.dateEnd : null;

    if(filter) {
      curPage = 1;
      this.setState({currentPage: 1});
    }

    data = data + `?page=${curPage}&limit=${perPage}`;

    // between=created_at,2020-09-16:2020-09-16
    let date = '';

    if (dateStart !== null) date = date + `&between=updated_at,${dateStart}`;

    if (dateEnd !== null){
      if(dateStart === null){
        date = date + `&between=updated_at,:${dateEnd}`;
      } else {
        date = date + `:${dateEnd}`;
      }
    }

    data = data + date;

    // if (search !== null) data = data + `&filter=first_name:${search}`;

    this.props.fetchBills(data);
  }

  filterReset = () => {
    this.setState({
      search: '',
      dateStart: '',
      dateEnd: ''
    })

    setTimeout(() => this.fetchDataCreate(true))
  }

  handleChangePage = (event, newPage) => {
    this.setState({currentPage: newPage + 1})
    setTimeout(() => this.fetchDataCreate())
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({perPage: parseInt(event.target.value, 10)})
    this.setState({currentPage: 1})
    setTimeout(() => this.fetchDataCreate())
  }


  render() {

    let headerTable = [
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.id"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.date"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.type"/>
      },
      // {
      //   colspan: '0',
      //   align: 'left',
      //   item: <FormattedMessage id="app.article"/>
      // },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.carBrand"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.payments"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.income"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.cassa"/>
      },
      {
        colspan: '0',
        align: 'right',
        item:''
      },
    ];



    return (
      <div className="recording">
        <div className="page-title">
           <span className={'header__name'}>
              <FormattedMessage id="app.bills"/>
            </span>
        </div>
        <div className="filter-head">
          <div className="filter-head__left">
            <div className="site-filter">

              <TextField
                type="date"
                value={this.state.dateStart}
                label={<FormattedMessage id="app.selectDateFrom"/>}
                onChange={(event) => {
                  this.setState({dateStart: event.target.value})
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type="date"
                label={<FormattedMessage id="app.selectDateFor"/>}
                value={this.state.dateEnd}
                onChange={(event) => {
                  this.setState({dateEnd: event.target.value})
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.filterReset}>
              <FormattedMessage id="app.reset"/>
            </Button>
            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={() => this.fetchDataCreate( true)}>
              <FormattedMessage id="app.search"/>
            </Button>
          </div>

          {this.state.isOpen ?
            <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopupAddBillsItem}>
              <ShowBillsItemPopup item={this.state.item} />
            </PopupWrapper>
            : ''}
        </div>

        <div className="page-section">
          <div className="page-section__content">
            <TableContainer className={'recording-table' + (this.props.loading ? ' loading-table' : '')}>
              <Table size="small" aria-label="a dense table">
                <TableHeader rows={headerTable}/>
                <TableBody>
                  {/*{*/}
                    {/*// this.props.bills.map((item, index) => {*/}
                    {/*this.props.bills.hasOwnProperty('data') ?*/}
                      {/*Object.keys(this.props.bills.data).map((item, index) => {*/}
                        {/*if(item === 'total_invoice_income' || item === 'total_invoice_total') return;*/}
                        {/*return (*/}
                          {/*<TableRow className={'site-table-row'} key={index}>*/}
                            {/*<TableCell align="left">{this.props.bills.data[item].id}</TableCell>*/}
                            {/*<TableCell align="left">{this.props.bills.data[item].updated_date}</TableCell>*/}
                            {/*<TableCell align="left">*/}
                              {/*{*/}
                                {/*this.props.bills.data[item].client_id === null && this.props.bills.data[item].hasOwnProperty('sale_document') ?*/}
                                  {/*<FormattedMessage id="app.waybillsSellingPriceItem"/> :*/}
                                  {/*null*/}
                              {/*}*/}
                              {/*{*/}
                                {/*this.props.bills.data[item].client_id !== null ?*/}
                                  {/*<FormattedMessage id="app.outfit"/> :*/}
                                  {/*null*/}
                              {/*}*/}
                              {/*{*/}
                                {/*this.props.bills.data[item].client_id === null && !this.props.bills.data[item].hasOwnProperty('sale_document') ?*/}
                                  {/*this.props.bills.data[item].description :*/}
                                  {/*null*/}
                              {/*}*/}
                            {/*</TableCell>*/}
                            {/*/!*<TableCell align="left">{this.props.bills.data[item].client === null ? this.props.bills.data[item].details[0].article.article : null}</TableCell>*!/*/}
                            {/*<TableCell align="left">*/}
                              {/*{this.props.bills.data[item].state === "NEW" ? <FormattedMessage id="app.statusNew"/> : null}*/}
                              {/*{this.props.bills.data[item].state === "DELETED" ?  <FormattedMessage id="app.statusDeleted"/> : null}*/}
                            {/*</TableCell>*/}
                            {/*<TableCell align="left">*/}
                              {/*{this.props.bills.data[item].client_id !== null || this.props.bills.data[item].hasOwnProperty('sale_document') ?*/}
                                {/*<React.Fragment>*/}
                                  {/*{*/}
                                    {/*this.props.bills.data[item].client_id !== null && this.props.bills.data[item].client !== null ?*/}
                                      {/*this.props.bills.data[item].client_id !== null && this.props.bills.data[item].client.payment_type === "CASH" ?*/}
                                        {/*<FormattedMessage id="app.statusCash"/> : <FormattedMessage id="app.noCash"/>*/}
                                      {/*: this.props.bills.data[item].client_id !== null && this.props.bills.data[item].sale_document.payment_type === "CASH" ?*/}
                                      {/*<FormattedMessage id="app.statusCash"/> : <FormattedMessage id="app.noCash"/>*/}
                                  {/*}*/}
                                {/*</React.Fragment>*/}
                                {/*:*/}
                                {/*'Ручной ввод'*/}
                              {/*}*/}
                            {/*</TableCell>*/}
                            {/*/!*<TableCell align="left">{parseFloat(item.invoice_income).toFixed(2)}</TableCell>*!/*/}
                            {/*<TableCell align="left">{parseFloat(this.props.bills.data[item].invoice_total).toFixed(2)}</TableCell>*/}
                            {/*<TableCell align="right">*/}
                              {/*{this.props.bills.data[item].client_id !== null || this.props.bills.data[item].hasOwnProperty('sale_document') ?*/}
                                {/*<Button variant="contained" size="small" color="primary" onClick={() => this.showBillHandle(this.props.bills.data[item])}>*/}
                                  {/*<FormattedMessage id="app.detail"/>*/}
                                {/*</Button>*/}
                                {/*:*/}
                                {/*null*/}
                              {/*}*/}
                            {/*</TableCell>*/}
                          {/*</TableRow>*/}
                        {/*)*/}
                      {/*}) : null*/}
                  {/*}*/}

                  {/*<TableRow className={'site-table-row'}>*/}
                    {/*<TableCell colSpan={5} align="left"><strong><FormattedMessage id="app.total"/></strong></TableCell>*/}

                    {/*<TableCell align="left"><strong>{ this.props.bills.hasOwnProperty('data') ? this.props.bills.data.total_invoice_total : null } UAH</strong></TableCell>*/}
                  {/*</TableRow>*/}
                {/*</TableBody>*/}



                  {
                    this.props.bills.hasOwnProperty('data') ?
                      // this.props.bills.map((item, index) => {
                      Object.keys(this.props.bills.data).map((item, index) => {
                        if (item === 'total_invoice_income' || item === 'total_invoice_total') return;
                        return (
                          <TableRow className={'site-table-row'} key={index}>
                            <TableCell align="left"
                                       className={'rep-1'}>
                              {this.props.bills.data[item].id}
                            </TableCell>
                            <TableCell align="left"
                                       className={'rep-2'}>
                              {this.props.bills.data[item].updated_at}
                            </TableCell>
                            <TableCell align="left" className={'rep-3'}>
                              {
                                this.props.bills.data[item].hasOwnProperty('sale_document') && this.props.bills.data[item].sale_document !== null ?
                                  <FormattedMessage id="app.waybillsSellingPriceItem"/> :
                                  null
                              }
                              {
                                this.props.bills.data[item].work_order !== null && this.props.bills.data[item].hasOwnProperty('work_order') ?
                                  <FormattedMessage id="app.outfit"/> :
                                  null
                              }
                              {
                                this.props.bills.data[item].sale_document === null && this.props.bills.data[item].work_order === null ?
                                  this.props.bills.data[item].doc_payment_type :
                                  null
                              }
                            </TableCell>

                            <TableCell align="left" className={'rep-4'}>
                              {this.props.bills.data[item].brand !== null ?
                                this.props.bills.data[item].brand
                                : null}

                            </TableCell>

                            <TableCell align="left" className={'rep-5'}>
                              {getRecipientType(this.props.bills.data[item].recipient_id)} &nbsp;
                              {
                                this.state.recipient.length > 0 ?
                                  getRecipienName(this.state.recipient, this.props.bills.data[item].recipient_id)
                                  :
                                  null
                              }
                            </TableCell>

                            <TableCell align="left"
                                       className={'rep-6'}>{parseFloat(this.props.bills.data[item].invoice_income).toFixed(2)}</TableCell>
                            <TableCell align="left"
                                       className={'rep-8'}>{parseFloat(this.props.bills.data[item].invoice_total).toFixed(2)}</TableCell>
                            <TableCell align="right" className={'rep-9'}>
                              {this.props.bills.data[item].client_id !== null || this.props.bills.data[item].hasOwnProperty('sale_document') ?
                                <Button variant="contained" size="small" color="primary"
                                        onClick={() => this.showBillHandle(this.props.bills.data[item])}>
                                  <FormattedMessage id="app.detail"/>
                                </Button>
                                :
                                null
                              }
                            </TableCell>
                          </TableRow>
                        )
                      }) : null}

                  <TableRow className={'site-table-row'}>
                    <TableCell colSpan={5} align="left"><strong><FormattedMessage id="app.total"/></strong></TableCell>
                    <TableCell
                      align="left"><strong>{this.props.bills.hasOwnProperty('data') ? parseFloat(this.props.bills.data.total_invoice_income).toFixed(2) : null} UAH</strong>
                    </TableCell>
                    <TableCell
                      align="left"><strong>{this.props.bills.hasOwnProperty('data') ?  parseFloat(this.props.bills.data.total_invoice_total).toFixed(2) : null} UAH</strong>
                    </TableCell>
                    <TableCell align="left"> </TableCell>
                    {/*<TableCell align="left"> </TableCell>*/}
                  </TableRow>

                </TableBody>

                  <TableFooter>
                  <TableRow>
                  <TablePagination
                  rowsPerPageOptions={[10, 30, 50, 100]}
                  colSpan={8}
                  count={this.props.bills.total}
                  rowsPerPage={this.state.perPage}
                  page={this.state.currentPage - 1}
                  labelRowsPerPage={<FormattedMessage id="app.rowsPerPage"/>}
                  SelectProps={{
                  inputProps: {'aria-caption': 'rows per page'},
                  native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                  </TableRow>
                  </TableFooter>

              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.bills.loading,
    bills: state.bills.bills,
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchBills: (data) => dispath(fetchBills(data)),
    fetchRecipient: () => dispath(fetchRecipient()),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(Bills));
