import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";


class TableHeader extends Component {


  render() {
    return (
      <TableHead>
        <TableRow className={'clients-table-head'}>
          { this.props.rows.map(function (index, key) {
            return(
              <TableCell key={key} colSpan={index.colspan} align={index.align}>{index.item}</TableCell>
            )
          }) }
        </TableRow>
      </TableHead>
    )
  }
}

export default TableHeader
