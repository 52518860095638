import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import './AddProductPopup.css';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";
import Autocomplete from '@material-ui/lab/Autocomplete';


class AddProductPopup extends Component {

  componentDidMount() {

  }

  handleClose = () => {
    this.props.handleClose();
  }

  render() {

    const top100Films = [
      { title: 'Олег Побережный' },
      { title: 'The Godfather'},
      { title: 'The Godfather: Part II'},
      { title: 'The Dark Knight' },
    ];

    return (
      <div className="modal-popup__window">
        <div className="modal-popup__header">
                <span>
                <FormattedMessage id="app.addWaybill"/>
                </span>
        </div>
        <div className="modal-popup__body">
          <div className="row">
            <div className="col-md-4">
              <div className="modal-popup__row">
                <TextField
                  id="standard-multiline-flexible"
                  label={<FormattedMessage id="app.invoiceNumber"/>}
                className={"modal-popup__input"}
                fullWidth
                margin="normal"
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="modal-popup__row">
                <Autocomplete
                  className="filter-client-select"
                  id="filter-client"
                  options={top100Films}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => <TextField {...params}
                                                      label={<FormattedMessage id="app.provider"/> }
                                                      variant="outlined"
                                                      className={"modal-popup__input--autocomplete"}
                                            />}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="modal-popup__row">
                <TextField
                  id="standard-multiline-flexible"
                  label={<FormattedMessage id="app.productsName"/>}
                  className={"modal-popup__input"}
                  fullWidth
                  margin="normal"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="modal-popup__row">
                <TextField
                  id="standard-multiline-flexible"
                  label={<FormattedMessage id="app.vendorCode"/>}
                className={"modal-popup__input"}
                margin="normal"
                fullWidth
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="modal-popup__row">
                <TextField
                  id="standard-multiline-flexible"
                  label={<FormattedMessage id="app.count"/>}
                  className={"modal-popup__input"}
                  margin="normal"
                  fullWidth
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="modal-popup__row">
                <TextField
                  id="standard-multiline-flexible"
                  label={<FormattedMessage id="app.purchasePrice"/>}
                  className={"modal-popup__input"}
                  margin="normal"
                  fullWidth
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="modal-popup__row">
                <TextField
                  id="standard-multiline-flexible"
                  label={<FormattedMessage id="app.sellingPrice"/>}
                  className={"modal-popup__input"}
                  margin="normal"
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-popup__footer">
          <div className="modal-popup__btn">
            <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={this.handleClose}>
              <FormattedMessage id="app.close"/>
            </Button>
            <Button variant="contained" color="primary" className={"modal-popup__btn-i"}>
              <FormattedMessage id="app.save"/>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default AddProductPopup
