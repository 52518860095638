import {
  FETCH_SUPPLIER_START, FETCH_SUPPLIER_SUCCESS, FETCH_SUPPLIER_ERROR
} from "../action/actionTypes";

const initialState = {
  suppliers: [],
  loading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUPPLIER_START:
      return{
        ...state, loading: true
      }

    case FETCH_SUPPLIER_SUCCESS:
      return {
        ...state, loading: false, supplier: action.supplier
      }

    case FETCH_SUPPLIER_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    default: return state
  }
}
