import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {NavLink} from "react-router-dom";
import Button from "@material-ui/core/Button";
import TableHeader from "../TableHeader/TableHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {fetchProductWaybills} from "../../../store/action/productWaybills";
import connect from "react-redux/es/connect/connect";
import {
  completeSaleDocument,
  fetchSalePDF,
  fetchSalePDFfetchSalePDFNoCash,
  removeSaleDocument
} from "../../../store/action/products";
import {fetchOutfitsPDF} from "../../../store/action/outfits";
import TableHead from "@material-ui/core/TableHead";


class TableRows extends Component {


  state = {
    open: false,
  }

  handleToggleSubtable = () => {
    this.setState({
      open: !this.state.open
    })
  }


  render() {
    let that = this;
    console.log(this.props.row);
    // Object.values(this.props.row).reduce((a, b) => console.log(a, b))

    return (

      <React.Fragment>

        <TableRow>


          {this.props.undertable ?
            <TableCell style={{width: 50}}>
              <IconButton aria-label="expand row" size="small" onClick={() => that.handleToggleSubtable()}>
                {that.state.open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              </IconButton>
            </TableCell>
            :
            null
          }


          <TableCell align={'left'}>
            {this.props.date}
          </TableCell>

          <TableCell align={'left'}>
          {parseFloat(this.props.row.reduce((prev,next) => parseFloat(prev) + parseFloat(next.salary),0)).toFixed(2)} UAH
          </TableCell>

          {/*<TableCell align={'left'}>*/}
          {/*{parseFloat(this.props.row.service_income).toFixed(2)} UAH*/}
          {/*</TableCell>*/}

          {/*<TableCell align={'left'}>*/}
          {/*{this.props.row.state}*/}
          {/*</TableCell>*/}

        </TableRow>

        {this.props.undertable ?
          <TableRow>
            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
              <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Typography variant="h6" gutterBottom component="div">
                    <FormattedMessage id="app.masters"/>
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow className={'clients-table-head'}>
                        <TableCell align={'left'}>id</TableCell>
                        <TableCell align={'left'}><FormattedMessage id="app.masters"/></TableCell>
                        <TableCell align={'left'}><FormattedMessage id="app.masterSalary"/></TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>


                      {
                        this.props.row.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align={'left'}>
                                {item.id}
                              </TableCell>
                              <TableCell align={'left'}>
                                {item.master_name}
                              </TableCell>
                              <TableCell align={'left'}>
                                {item.salary}
                              </TableCell>
                            </TableRow>
                          )

                        })
                      }

                      {/*{this.props.row.hasOwnProperty('working_day_master') ?*/}
                      {/*this.props.row.working_day_master.map((item, index) => {*/}
                      {/*return (*/}
                      {/*<TableRow key={index}>*/}
                      {/*<TableCell component="th" scope="row">*/}
                      {/*{item.id}*/}
                      {/*</TableCell>*/}

                      {/*<TableCell component="th" scope="row">*/}
                      {/*{item.master_name}*/}
                      {/*</TableCell>*/}

                      {/*<TableCell component="th" scope="row">*/}
                      {/*{parseInt(item.salary)} UAH*/}
                      {/*</TableCell>*/}

                      {/*</TableRow>*/}
                      {/*)*/}
                      {/*})*/}
                      {/*:*/}
                      {/*null*/}
                      {/*}*/}


                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          :
          null
        }

      </React.Fragment>


    )
  }
}


function mapDispathToProps(dispath) {
  return {
    completeSaleDocument: (id) => dispath(completeSaleDocument(id)),
    removeSaleDocument: (id) => dispath(removeSaleDocument(id)),
    fetchSalePDF: (id) => dispath(fetchSalePDF(id)),
    fetchSalePDFfetchSalePDFNoCash: (id) => dispath(fetchSalePDFfetchSalePDFNoCash(id)),
  }
}


export default connect(null, mapDispathToProps)(TableRows);
