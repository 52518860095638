import {API} from "../../axios/axiosMainUrl";
import {FETCH_DELIVERY_START, FETCH_PRODUCTS_ALL_SUCCESS, FETCH_PRODUCTS_ERROR} from "./actionTypes";
import {FETCH_PRODUCTS_SUCCESS} from "./actionTypes";
import {FETCH_PRODUCTS_START} from "./actionTypes";
import {FETCH_DELIVERY_SUCCESS} from "./actionTypes";
import {fetchClientsError, fetchClientsStart, fetchClientsSuccess} from "./clients";


export function createDelivery(data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`delivery/store`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function completeDelivery(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`delivery/${id}/completed`, null, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function removeDelivery(id, data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`delivery/${id}/change-status`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function fetchDelivery(data = '') {
  return async dispatch => {
    try {
      dispatch(fetchDeliveryStart())
      const token = localStorage.getItem('token');
      const response = await API.get(`delivery${data}`, {headers: { Authorization: `Bearer ${token}` }});
      dispatch(fetchDeliverySuccess(response.data))
    } catch (e) {
      console.log(e);
    }

  }
}

export function fetchDeliverySuccess(delivery) {
  return {
    type: FETCH_DELIVERY_SUCCESS,
    delivery
  }
}
export function fetchDeliveryStart() {
  return {
    type: FETCH_DELIVERY_START,
  }
}

export function createSaleDocument() {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`sale-document/create`, null, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function updateSaleDocument(id, data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`sale-document/${id}/update`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function completeSaleDocument(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`sale-document/${id}/complete`, null, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function removeSaleDocument(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`sale-document/${id}/delete`, null, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function deleteSaleDocument(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`sale-document/${id}/delete`, null, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function addArticleSaleDocument(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`sale-document/${id}/part-row/create`, null, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}
export function updateArticleSaleDocument(id, idDocument, data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`sale-document/${idDocument}/part-row/${id}/update`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}
export function deleteArticleSaleDocument(id, idDocument) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.delete(`sale-document/${idDocument}/part-row/${id}/delete`, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function updateProduct(id, data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`article/update/${id}`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function createProduct(data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`article/store`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function fetchProductsInStock() {
  return async dispatch => {
    dispatch(fetchProductsStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`article/in-stock`, {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchProductsSuccess(response.data))
    } catch (e) {
      console.log(fetchProductsError(e));
    }
  }
}

export function fetchProducts(data = '') {
  return async dispatch => {
    dispatch(fetchProductsStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`article/index${data}`, {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchProductsSuccess(response.data))
    } catch (e) {
      console.log(fetchProductsError(e));
    }
  }
}


export function fetchProductsStart() {
  return {
    type: FETCH_PRODUCTS_START
  }
}

export function fetchProductsSuccess(products) {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    products
  }
}

export function fetchProductsError(e) {
  return {
    type: FETCH_PRODUCTS_ERROR,
    error: e
  }
}


export function fetchProductsAll(data = '') {
  return async dispatch => {
    // dispatch(fetchProductsStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`article/index${data}`, {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchProductsAllSuccess(response.data))
    } catch (e) {
      console.log(fetchProductsError(e));
    }
  }
}

export function updateProducts(id, data = '') {
  return async dispatch => {
    const token = localStorage.getItem('token');
    try {
      const response = await API.post(`/article/update-partly/${id}`, data, {headers: { Authorization: `Bearer ${token}` }})
    } catch (e) {
      console.log(fetchProductsError(e));
    }
  }
}


export function fetchProductsAllSuccess(productsAll) {
  return {
    type: FETCH_PRODUCTS_ALL_SUCCESS,
    productsAll
  }
}



export function fetchSalePDF(id) {
  return async dispatch => {
    // dispatch(fetchProductsStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`sale-document/${id}/no-cache-pdf`, {headers: { Authorization: `Bearer ${token}` }})

      // const linkSource = `data:application/pdf;base64,${response.data}`;
      // const downloadLink = document.createElement("a");
      // const fileName = "abc.pdf";
      // downloadLink.href = linkSource;
      // downloadLink.target = '_blank';
      // // downloadLink.download = fileName;
      // downloadLink.click();


      let pdfWindow = window.open("")
      pdfWindow.document.write("<style type='text/css'>body {margin: 0; overflow: hidden}</style> <iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(response.data) + "'></iframe>")

      // window.open("data:application/octet-stream;charset=utf-16le;base64,"+response.data);

      // dispatch(fetchProductsSuccess(response.data))
    } catch (e) {
      console.log(fetchProductsError(e));
    }
  }
}

export function fetchSalePDFfetchSalePDFNoCash(id) {
  return async dispatch => {
    // dispatch(fetchProductsStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`sale-document/${id}/product-receipt-pdf`, {headers: { Authorization: `Bearer ${token}` }})

      let pdfWindow = window.open("")
      pdfWindow.document.write("<style type='text/css'>body {margin: 0; overflow: hidden}</style> <iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(response.data) + "'></iframe>")

    } catch (e) {
      console.log(fetchProductsError(e));
    }
  }
}

export function exportProducts(data) {
  return async dispatch => {
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`article/export${data}`, {headers: { Authorization: `Bearer ${token}` }})

      return response.data;
    } catch (e) {
      console.log(fetchProductsError(e));
    }
  }
}

export function importProducts(file) {
  return async dispatch => {
    const token = localStorage.getItem('token');
    try {
      // const response = await API.get(`article/export`, {headers: { Authorization: `Bearer ${token}` }})
      const response = await API.post(`article/import`, file, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'multipart/form-data',
          'x-requested-with': 'XMLHttpRequest' ,
          Accept: '*/*'
        }
      });
      return response.data;
    } catch (e) {
      console.log(fetchProductsError(e));
    }
  }
}






