import {OPEN_MENU, CLOSE_MENU} from "../action/actionTypes";


const initialState = {
  main: [

  ],

  menu: false

}

export default function mainReducer (state = initialState, action) {
  switch (action.type) {

    case OPEN_MENU:
      return{
        ...state, menu: true
      }

    case CLOSE_MENU:
      return {
        ...state, menu: false
      }

    default:
      return {
        ...state
      }
  }
}
