import {FETCH_DASHBOARD_SUCCESS} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";


// export function createTask(data) {
//   return async dispatch => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await API.post('projects/create', data, {headers: { Authorization: `Bearer ${token}` }});
//       return response;
//     } catch (e) {
//       console.log(e);
//     }
//
//   }
//
// }

export function addCassa(data) {
  return async dispatch => {
    const token = localStorage.getItem('token');
    try {
      const response = await API.post('kassa/add', data, {headers: {Authorization: `Bearer ${token}`}})
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}

export function fetchDashboard() {
  return async dispatch => {
    const token = localStorage.getItem('token');
    try {
      const response = await API.get('dashboard/stat', {headers: {Authorization: `Bearer ${token}`}})

      dispatch(fetchDashboardSuccess(response.data))
    } catch (e) {
      console.log(e);
    }
  }
}


export function fetchDashboardSuccess(dashboard) {
  return {
    type: FETCH_DASHBOARD_SUCCESS,
    dashboard
  }
}

