import {
  FETCH_MASTERS_START,
  FETCH_MASTERS_SUCCESS,
  FETCH_MASTERS_ERROR,
  FETCH_MANAGERS_START,
  FETCH_MANAGERS_SUCCESS,
  FETCH_MANAGERS_ERROR, FETCH_SERVICE_SUCCESS, FETCH_SERVCE_ERROR, FETCH_SUPPLIER_SUCCESS, FETCH_SUPPLEIR_ERROR,
  FETCH_EXPENSES_TYPE_SUCCESS, FETCH_EXPENSES_TYPE_ERROR,
  FETCH_WORKERS_SUCCESS,
  FETCH_AVATAR_SUCCESS
} from "../action/actionTypes";

const initialState = {
  masters: [],
  avatar: [],
  managers: [],
  service: [],
  supplier: [],
  expenses: [],
  mastersLoading: false,
  managersLoading: false,
  supplierLoading: false,
  expensesLoading: false,
  serviceLoading: false,
  loading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MASTERS_START:
      return{
        ...state, loading: true
      }

    case FETCH_MASTERS_SUCCESS:
      return {
        ...state, loading: false, masters: action.masters
      }

    case FETCH_MASTERS_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    case FETCH_MANAGERS_START:
      return{
        ...state, loading: true
      }

    case FETCH_MANAGERS_SUCCESS:
      return {
        ...state, loading: false, managers: action.managers
      }

    case FETCH_MANAGERS_ERROR:
      return {
        ...state, loading: false, error: action.error
      }


    case FETCH_SERVICE_SUCCESS:
      return {
        ...state, loading: false, service: action.service
      }

    case FETCH_SERVCE_ERROR:
      return {
        ...state, loading: false, error: action.error
      }


    case FETCH_SUPPLIER_SUCCESS:
      return {
        ...state, loading: false, supplier: action.supplier
      }

    case FETCH_SUPPLEIR_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    case FETCH_EXPENSES_TYPE_SUCCESS:
      return {
        ...state, loading: false, expenses: action.expenses
      }

    case FETCH_AVATAR_SUCCESS:
      return {
        ...state, loading: false, avatar: action.avatar
      }

    case FETCH_WORKERS_SUCCESS:
      return {
        ...state, loading: false, workers: action.workers
      }

    case FETCH_EXPENSES_TYPE_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    default: return state
  }
}

