import React, {Component} from 'react'
import './AddCassaPopup.css'

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";
import {fetchClientCarsBrand} from "../../../store/action/clients";
import connect from "react-redux/es/connect/connect";
import {createMaster} from "../../../store/action/settings";
import {editMaster} from "../../../store/action/settings";
import {logout} from "../../../store/action/auth";
import {openMenu} from "../../../store/action/main";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../../sevices/validationService";
import InputMask from 'react-input-mask';
import {addCassa} from "../../../store/action/dashboard";


class AddCassaPopup extends Component {

  state = {
    count: '',
    cassa: '',
    desc: '',
    validation: false,
    date: ''
  }


  handleClose = () => {
    this.props.handleClose();
  }

  addCassa = () => {

    let today = new Date();
    let mounth = today.getMonth() + 1;
    mounth = mounth < 10 ? '0' + mounth : mounth;
    let day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    let date = today.getFullYear() + '-' + mounth + '-' + day + 'T' + today.getHours() + ":" + today.getMinutes();


    if(this.state.cassa === ''){
      this.setState({validation: true})
      return;
    } else {
      this.setState({validation: false})
    }

    let data = {
      description: this.state.desc,
      amount: this.state.cassa !== '' ? this.state.cassa : '',
      amount_income: this.state.count !== '' ? this.state.count: '',
      date: this.state.date !== '' ? this.state.date : date
    }


    this.props.addCassa(data).then(() => {
      this.props.handleClose();
      this.props.reRenderCassa();
    })
  }




  render() {


    return (


      <div className="modal-popup__window container">
        <div className="modal-popup__header">
                <span>
                  Пополнение кассы
                </span>
        </div>
        <div className="modal-popup__body">
          <div className="waybills-popup__inner">

            <div>
              Введите сумму котору хотите добавить
            </div>
            <div className="row">
              <div className={'col-md-6'}>
                <TextField
                  id="datetime-local"
                  type="datetime-local"
                  className={'modal-popup__input--time modal-field'}
                  label={<FormattedMessage id="app.currentDate"/>}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    'data-name': 'data_end'
                  }}
                  onChange={(event) => this.setState({date: event.target.value})}
                />
              </div>
              <div className={'col-md-6'}>
                <TextField
                  className={'modal-field'}
                  label={<FormattedMessage id="app.description"/>}
                  defaultValue=""
                  variant="outlined"
                  size="small"
                  inputProps={{'data-name': 'engine'}}
                  onChange={(event) => this.setState({desc: event.target.value})}
                />
              </div>

              <div className={'col-md-6'}>
                <TextField
                  className={'modal-field'}
                  label={<FormattedMessage id="app.netProfit"/>}
                  defaultValue=""
                  variant="outlined"
                  size="small"
                  inputProps={{'data-name': 'engine'}}
                  onChange={(event) => this.setState({count: event.target.value})}
                />
              </div>
              <div className={'col-md-6'}>
                <TextField
                  className={'modal-field'}
                  label={<FormattedMessage id="app.cassa"/>}
                  defaultValue=""
                  variant="outlined"
                  size="small"
                  inputProps={{'data-name': 'engine'}}
                  onChange={(event) => this.setState({cassa: event.target.value})}
                />
              </div>
            </div>

          </div>
          {this.state.validation ?
            <div className={'error-txt'}>
              Введите сумму
            </div>
            :
            null
          }

          <div className="modal-popup__footer">

            <div className="modal-popup__btn">
              <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={() => this.handleClose()}>
                <FormattedMessage id="app.close"/>
              </Button>

              <Button variant="contained" color="primary" className={"modal-popup__btn-i"} onClick={() => this.addCassa()}>
                <FormattedMessage id="app.add"/>
              </Button>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    masters: state.settings.masters,
  }
}

function mapDispathToProps(dispath) {
  return {
    addCassa: (data) => dispath(addCassa(data)),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(AddCassaPopup);

