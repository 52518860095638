import {
  FETCH_PRODUCT_WAYBILLS_START,
  FETCH_PRODUCT_WAYBILLS_SUCCESS,
  FETCH_PRODUCT_WAYBILLS_ERROR,
} from "../action/actionTypes";

const initialState = {
  productWaybills: {},
  loading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_WAYBILLS_START:
      return{
        ...state, loading: true
      }

    case FETCH_PRODUCT_WAYBILLS_SUCCESS:
      return {
        ...state, loading: false, productWaybills: action.productWaybills
      }

    case FETCH_PRODUCT_WAYBILLS_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    default: return state
  }
}
