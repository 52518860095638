import React, {Component} from 'react'
import './QuestionPopup.css'

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";
import {fetchClientCarsBrand} from "../../../store/action/clients";
import connect from "react-redux/es/connect/connect";
import {createMaster} from "../../../store/action/settings";
import {editMaster} from "../../../store/action/settings";
import {logout} from "../../../store/action/auth";
import {openMenu} from "../../../store/action/main";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../../sevices/validationService";
import InputMask from 'react-input-mask';


class QuestionPopup extends Component {

  state = {

  }


  handleClose = () => {
    this.props.handleClose();

    if(this.props.no){
      this.props.no()
    }
  }




  render() {


    return (


      <div className="modal-popup__window container">
        <div className="modal-popup__header">
                <span>
                  Подтверждение
                </span>
        </div>
        <div className="modal-popup__body">
          <div className="waybills-popup__inner">
            <div>
              {this.props.question}
            </div>
          </div>

          <div className="modal-popup__footer">

            <div className="modal-popup__btn">
              <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={() => this.handleClose()}>
                Нет
              </Button>

              <Button variant="contained" color="primary" className={"modal-popup__btn-i"} onClick={() => this.props.yes()}>
                Да
              </Button>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    masters: state.settings.masters,
  }
}

function mapDispathToProps(dispath) {
  return {
    createMaster: (data) => dispath(createMaster(data)),
    editMaster: (data, id) => dispath(editMaster(data, id))
  }
}


export default connect(mapStateToProps, mapDispathToProps)(QuestionPopup);

