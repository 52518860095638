import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import AddProductPopup from "../../components/ModalPopups/AddProductPopup/AddProductPopup";

import './Expenses.css'
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import TableHeader from "../../components/Tables/TableHeader/TableHeader.js";
import {fetchManagers, fetchMasters, fetchService, fetchSupplier} from "../../store/action/settings";
import connect from "react-redux/es/connect/connect";
import {fetchProducts} from "../../store/action/products";
import SellProductPopup from "../../components/ModalPopups/SellProductPopup/SellProductPopup";
import AddProductWaybillsPopup from "../../components/ModalPopups/AddProductWaybillsPopup/AddProductWaybillsPopup";
import {editExpenses, fetchExpanses, fetchExpenses, removeExpenses} from "../../store/action/expenses";
import AddExpensesPopup from "../../components/ModalPopups/AddExpensesPopup/AddExpensesPopup";
import TextField from "@material-ui/core/TextField";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {fetchRecipient} from "../../store/action/bills";

class Expenses extends Component {

  state = {
    isOpen: false,
    isEdit: false,
    perPage: 30,
    currentPage: 1,
    search: '',
    dateStart: '',
    dateEnd: '',
    item: '',
    recipient: []
  }

  componentDidMount() {
    this.fetchDataCreate()
    this.props.fetchRecipient().then((response) => {
      this.setState({
        recipient: response.data
      })
    });

    document.title = this.props.intl.formatMessage({
      id: 'app.expenses',
    });
  }

  reRender = () => {
    this.fetchDataCreate()
  }

  handleChange = (item) => {
    this.setState({
      isOpen: true,
      isEdit: true,
      item: item
    })
  }

  handleRemove = (id) => {
    this.props.removeExpenses(id).then((response) => {
      console.log(response);
      this.fetchDataCreate()
    });
  }

  addExpensesHandle = () => {
    this.setState({
      isOpen: true,
    })
  }

  closePopup = () => {
    this.setState({
      isOpen: false,
      isEdit: false,
    })
  }


  fetchDataCreate = (filter = false) => {
    let data = '',
      curPage = this.state.currentPage,
      perPage = this.state.perPage,
      dateStart = this.state.dateStart !== '' ? this.state.dateStart : null,
      dateEnd = this.state.dateEnd !== '' ? this.state.dateEnd : null,
      search = this.state.search !== '' ? this.state.search : null;

    if(filter) {
      curPage = 1;
      this.setState({currentPage: 1});
    }

    data = data + `?page=${curPage}&limit=${perPage}`;

    // between=created_at,2020-09-16:2020-09-16
    let date = '';

    if (dateStart !== null) date = date + `&between=date,${dateStart}`;

    if (dateEnd !== null){
      if(dateStart === null){
        date = date + `&between=date,:${dateEnd}`;
      } else {
        date = date + `:${dateEnd}`;
      }
    }

    data = data + date;

    if (search !== null) data = data + `&filterByRelation=name:${search}`;

    this.props.fetchExpenses(data);
  }

  filterReset = () => {
    this.setState({
      search: '',
      dateStart: '',
      dateEnd: ''
    })
    setTimeout(() => this.fetchDataCreate(true))
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.fetchDataCreate(true);
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({currentPage: newPage + 1})
    setTimeout(() => this.fetchDataCreate())
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({perPage: parseInt(event.target.value, 10)})
    this.setState({currentPage: 0})
    setTimeout(() => this.fetchDataCreate())
  }


  render() {


    let headerTable = [
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.id"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.name"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.date"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.type"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.price"/>
      },
      {
        colspan: '0',
        align: 'right',
        item: ''
      }
    ]

    // console.log(this.props.products);

    return (
      <div className="products">
        <div className="page-title">
           <span className={'header__name'}>
              <FormattedMessage id="app.expenses"/>
            </span>
        </div>

        <div className="filter-head">
          <div className="filter-head__left">
            <div className="site-filter">

              <TextField
                type="date"
                value={this.state.dateStart}
                label={<FormattedMessage id="app.selectDateFrom"/>}
                onChange={(event) => {
                  this.setState({dateStart: event.target.value})
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type="date"
                label={<FormattedMessage id="app.selectDateFor"/>}
                value={this.state.dateEnd}
                onChange={(event) => {
                  this.setState({dateEnd: event.target.value})
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>


            <div className="filter-client-wrap">
              <TextField
                className="filter-client-select"
                value={this.state.search}
                onChange={(event) => this.setState({search: event.target.value})}
                size="small"
                onKeyDown={this._handleKeyDown}
                label={<FormattedMessage id="app.search"/>}
                variant="outlined"/>
            </div>

            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.filterReset}>
              <FormattedMessage id="app.reset"/>
            </Button>
            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={() => this.fetchDataCreate( true)}>
              <FormattedMessage id="app.search"/>
            </Button>
          </div>

          <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.addExpensesHandle}>
            <FormattedMessage id="app.addNote"/>
          </Button>


          {this.state.isOpen ?
            <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopup}>
              <AddExpensesPopup isEdit={this.state.isEdit} item={this.state.item} reRender={this.reRender}/>
            </PopupWrapper>
            : ''}

        </div>

        <TableContainer className={'products-table' + (this.props.loading ? ' loading-table' : '')}>
          <Table size="small" aria-label="a dense table">
            <TableHeader rows={headerTable}/>
            <TableBody>
              {
                // this.props.expenses.length > 0 ?
                this.props.expenses.hasOwnProperty('data') ?
                Object.keys(this.props.expenses.data).map((item, index) => {
                // this.props.expenses.data.map((item, index) => {
                  if(item === 'totalPrice') return;
                  return (
                    <TableRow className={'products-table-row'} key={index}>
                      <TableCell align="left"> {this.props.expenses.data[item].id} </TableCell>
                      <TableCell align="left"> {this.props.expenses.data[item].expenses_type.name} </TableCell>
                      <TableCell align="left"> {this.props.expenses.data[item].date} </TableCell>
                      <TableCell align="left">
                        {this.state.recipient.length > 0 ?
                          <React.Fragment>
                            {this.state.recipient.map((recipient, index) => {
                              if(recipient.id === this.props.expenses.data[item].recipient_id){
                                return (
                                  recipient.name
                                )
                              }
                            })}
                          </React.Fragment>


                          :
                          null
                        }
                      </TableCell>
                      <TableCell align="left"> {this.props.expenses.data[item].price} </TableCell>
                      <TableCell align="right">
                        <Button variant="contained" color="primary" size="small" className={'outfit-remove-btn'} onClick={() => {
                          this.handleChange(this.props.expenses.data[item])
                        }}>
                          <FormattedMessage id="app.edit"/>
                        </Button>
                        <Button variant="contained" color="primary" size="small" onClick={() => {
                          this.handleRemove(this.props.expenses.data[item].id)
                        }}>
                          <FormattedMessage id="app.remove"/>
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })
                :
                null
              }


            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 30, 50, 100]}
                  colSpan={8}
                  count={this.props.expenses.total}
                  rowsPerPage={this.state.perPage}
                  page={this.state.currentPage - 1}
                  labelRowsPerPage={<FormattedMessage id="app.rowsPerPage"/>}
                  SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>

          </Table>
        </TableContainer>

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.expenses.loading,
    expenses: state.expenses.expenses,
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchRecipient: () => dispath(fetchRecipient()),
    fetchExpenses: (data) => dispath(fetchExpenses(data)),
    removeExpenses: (id) => dispath(removeExpenses(id)),
    editExpenses: (id, data) => dispath(editExpenses(id, data)),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(Expenses));
