import React, {Component} from 'react'
import FormattedMessage from "react-intl/lib/src/components/message";
import './Settings.css'
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import Man from "../../assets/img/man.jpg";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import AddMasterPopup from "../../components/ModalPopups/AddMasterPopup/AddMasterPopup";
import AddAdminPopup from "../../components/ModalPopups/AddAdminPopup/AddAdminPopup";
import connect from "react-redux/es/connect/connect";
import InputMask from 'react-input-mask';
import axios from 'axios'

import {
  deleteExpenses, deleteManager, deleteMasters,
  deleteService, deleteSupplier, editUser,
  fetchExpensesType,
  fetchMasters,
  fetchService,
  fetchSupplier, showAvatar, storeAvatar
} from "../../store/action/settings";
import {fetchManagers} from "../../store/action/settings";
import AddManagerPopup from "../../components/ModalPopups/AddManagerPopup/AddManagerPopup";
import AddServicePopup from "../../components/ModalPopups/AddServicePopup/AddServicePopup";
import AddSupplierPopup from "../../components/ModalPopups/AddSupplierPopup/AddSupplierPopup";
import AddExpensesPopup from "../../components/ModalPopups/AddExpensesPopup/AddExpensesPopup";
import {getCurrentUser} from "../../store/action/auth";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../sevices/validationService";
import AddExpensesTypePopup from "../../components/ModalPopups/AddExpensesTypePopup/AddExpensesTypePopup";
import {injectIntl} from "react-intl";
import {imageFile} from "../../sevices/imageFile";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


class Settings extends Component {


  state = {
    value: 0,
    setValue: 0,
    rowId: 0,
    setSuccess: false,
    isOpen: false,
    isOpenAdminPopup: false,
    isOpenManagerPopup: false,
    isOpenServicePopup: false,
    isOpenSupplierPopup: false,
    isOpenExpensesPopup: false,
    setLoading: false,

    rowIndex: '',
    isEdit: false,

    id: '',
    name: '',
    last_name: '',
    avatar: '',
    phone: '',
    email: '',
    password: '',

    validation: {
      name: '',
      last_name: '',
      phone: '',
      email: '',
      // password: '',
    }

  }

  componentDidMount() {
    this.props.showAvatar();

    this.props.getCurrentUser().then(() => {
      this.setState({
        id: this.props.userData.id,
        name: this.props.userData.name,
        last_name: this.props.userData.last_name,
        avatar: this.props.userData.avatar,
        phone: this.props.userData.phone,
        email: this.props.userData.email,
      })
    })

    document.title = this.props.intl.formatMessage({
      id: 'app.settings',
    });
  }

  addAdminPopup = () => {
    this.setState({
      isOpenAdminPopup: true
    })
  }

  addMasterPopup = () => {
    this.setState({
      isOpen: true
    })
  }
  addServicePopup = () => {
    this.setState({
      isOpenServicePopup: true
    })
  }

  addSupplierPopup = () => {
    this.setState({
      isOpenSupplierPopup: true
    })
  }
  addExpensesPopup = () => {
    this.setState({
      isOpenExpensesPopup: true
    })
  }

  addManagerPopup = () => {
    this.setState({
      isOpenManagerPopup: true
    })
  }
  closePopupAddMaster = () => {
    this.setState({
      isOpen: false,
      isEdit: false,
    })
  }
  closePopupAddAdmin = () => {
    this.setState({
      isOpenAdminPopup: false,
      isEdit: false,
    })
  }
  closePopupAddManager = () => {
    this.setState({
      isOpenManagerPopup: false,
      isEdit: false,
    })
  }
  closePopupAddService = () => {
    this.setState({
      isOpenServicePopup: false,
      isEdit: false,
    })
  }
  closePopupAddSupplier = () => {
    this.setState({
      isOpenSupplierPopup: false,
      isEdit: false,
    })
  }
  closePopupAddExpenses = () => {
    this.setState({
      isOpenExpensesPopup: false,
      isEdit: false,
    })
  }

  handleChange = (event, newValue) => {
    console.log(newValue);
    this.setState({value: newValue})
  };


  getMasters = () => {
    this.props.fetchMasters();
  }

  getManagers = () => {
    this.props.fetchManagers();
  }

  getService = () => {
    this.props.fetchService();
  }

  getSupplier = () => {
    this.props.fetchSupplier();
  }

  getExpenses = () => {
    this.props.fetchExpensesType();
  }


  handlerEditMasters = (event) => {
    let rowId = event;
    let rowMaster = this.props.masters[rowId]

    this.setState({
      rowId: rowId,
      isEdit: true,
      master: rowMaster
    });

    this.addMasterPopup();
  }

  handlerDeleteMasters = (index) => {
    this.props.deleteMasters(index).then((response) => {
      console.log(response);
      this.props.fetchMasters();
    });
  }

  handlerEditManagers = (event) => {
    let rowId = event;
    let rowManager = this.props.managers[rowId]

    this.setState({
      rowId: rowId,
      isEdit: true,
      manager: rowManager
    });

    this.addManagerPopup();
  }

  handlerEditService = (index) => {
    // let rowManager = this.props.managers[index]

    this.setState({
      index: index,
      isEdit: true,
      isOpenServicePopup: true
    });

    this.addServicePopup();
  }


  handlerDeleteService = (index) => {
    this.props.deleteService(index).then((response) => {
      console.log(response);
      this.props.fetchService();
    });
  }

  handlerEditSupplier = (index) => {
    // let rowManager = this.props.managers[index]

    this.setState({
      index: index,
      isEdit: true,
      isOpenSupplierPopup: true
    });

    this.addSupplierPopup();
  }


  handlerDeleteSupplier = (index) => {
    this.props.deleteSupplier(index).then((response) => {
      console.log(response);
      this.props.fetchSupplier();
    });
  }

  handlerEditExpenses = (index) => {
    // let rowManager = this.props.managers[index]

    this.setState({
      index: index,
      isEdit: true,
      isOpenExpensesPopup: true
    });

    this.addExpensesPopup();
  }

  handlerDeleteExpenses = (index) => {
    this.props.deleteExpenses(index).then((response) => {
      console.log(response);
      this.props.fetchExpensesType();
    });
  }

  handleButtonClick = () => {

  };


  handleChangePersonal = () => {


    let data = {
      id: this.state.id,
      name: this.state.name,
      last_name: this.state.last_name,
      phone: this.state.phone,
      email: this.state.email,
    }

    let validation = fieldEmpty(data);
    let validEmail = fieldEmail(data.email);
    // let validPassword = fieldPassword(data.password);

    if (!validation.isValidation || !validEmail) {
      if (!validEmail) {
        validation.validation.email = true;
      }

      // if(!validPassword){
      //   validation.validation.password = true;
      // }

      this.setState({
        validation: validation.validation
      });

      return;
    }

    if (!this.state.setLoading) {
      this.setState({setSuccess: false});
      this.setState({setLoading: true});
      let that = this;
      setTimeout(() => {
        that.setState({setSuccess: true});
        that.setState({setLoading: false});
      }, 2000);
    }

    if (this.state.password !== '') {
      data.password = this.state.password;
    }

    this.props.editUser(data, this.state.id).then(() => {
      this.props.getCurrentUser().then(() => {
        this.setState({
          id: this.props.userData.id,
          name: this.props.userData.name,
          last_name: this.props.userData.last_name,
          avatar: this.props.userData.avatar,
          phone: this.props.userData.phone,
          email: this.props.userData.email,
        })
      })
    });

  }

  changePhotoHandler = (event) => {
    let a = document.getElementById('form');

    let formData = new FormData();

    formData.append("avatar", event.target.files[0], event.target.files[0].name);

    this.props.storeAvatar(formData).then(() => this.props.showAvatar());
  }

  onFormSubmit = (e) => {
    e.preventDefault() // Stop form submit

    const url = 'http://auto-crm-back/api/avatar/store';
    const token = localStorage.getItem('token');
    const formData = new FormData();

    formData.append('file', this.state.file)
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'multipart/form-data',
        Accept: '*/*',
        'x-requested-with': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*'
      }
    }
    return axios.post(url, formData, config)
  }

  deleteManager = (id) => {
    this.props.deleteManager(id).then(() => {
      this.getManagers();
    })
  }


  render() {

    let that = this;


    return (
      <div className={'settings-page'}>

        <div className="page-title">
           <span className={'header__name'}>
              <FormattedMessage id="app.settings"/>
            </span>
        </div>

        <div className="settings">
          <div>
            <AppBar position="static">
              <Tabs value={this.state.value} onChange={this.handleChange} aria-label="simple tabs example">
                <Tab value={0} label={<FormattedMessage id="app.main"/>} {...a11yProps(0)} />
                <Tab value={1} onClick={this.getMasters} label={<FormattedMessage id="app.masters"/>} {...a11yProps(1)} />
                {this.props.userData.role === "ADMIN" ?
                  <Tab value={2} onClick={this.getManagers} label={<FormattedMessage id="app.managers"/>} {...a11yProps(2)} />
                  :
                  null
                }
                <Tab value={3} onClick={this.getService} label={<FormattedMessage id="app.worksType"/>} {...a11yProps(3)} />
                <Tab value={4} onClick={this.getSupplier} label={<FormattedMessage id="app.supplier"/>} {...a11yProps(4)} />
                <Tab value={5} onClick={this.getExpenses} label={<FormattedMessage id="app.expenses"/>} {...a11yProps(5)} />
              </Tabs>
            </AppBar>


            <TabPanel value={this.state.value} index={0}>
              <div className="settings-main">
                <div className="settings-main__photo">
                  {this.props.avatar.length > 0 ? <img src={'data:image/png;base64,' + this.props.avatar} alt="sada"/> :
                    <img src={Man} alt=""/>}

                  <form action="#" method={'post'} id={'form'} enctype="multipart/form-data">
                    <Button
                      variant="contained"
                      component="label"
                      size={'small'}
                    >
                      Upload photo
                      <input
                        type="file"
                        style={{display: "none"}}
                        onChange={(event) => this.changePhotoHandler(event)}
                      />
                    </Button>

                  </form>
                </div>
                <div className="settings-main__name settings-main__row">
                  <span className={'settings-main-label'}>
                      <FormattedMessage id="app.name"/>
                  </span>
                  <span>
                    <TextField variant="outlined" value={this.state.name}
                               error={this.state.validation.name}
                               onChange={(event) => this.setState({name: event.target.value})}/>
                  </span>
                </div>
                <div className="settings-main__name settings-main__row">
                  <span className={'settings-main-label'}>
                      <FormattedMessage id="app.lastName"/>
                  </span>
                  <span>
                    <TextField
                      variant="outlined"
                      value={this.state.last_name}
                      error={this.state.validation.last_name}
                      onChange={(event) => this.setState({last_name: event.target.value})}
                    />
                  </span>
                </div>
                <div className="settings-main__phone settings-main__row">
                  <span className={'settings-main-label'}>
                      <FormattedMessage id="app.phone"/>
                  </span>
                  <span>
                    <InputMask
                      mask="+380(99)9999999"
                      maskChar="_"
                      value={this.state.phone}
                      onChange={(event) => this.setState({phone: event.target.value})}
                    >
                                {() => <TextField
                                  variant="outlined"
                                  className={"task-create__name-input"}
                                  error={this.state.validation.phone}
                                />
                                }
                              </InputMask>
                  </span>
                </div>
                <div className="settings-main__login settings-main__row">
                  <span className={'settings-main-label'}>
                      <FormattedMessage id="app.emailAddress"/>
                  </span>
                  <span>
                    <TextField id="standard-basic" variant="outlined" value={this.state.email}
                               error={this.state.validation.email}
                               onChange={(event) => this.setState({email: event.target.value})}/>
                  </span>
                </div>
                <div className="settings-main__pass settings-main__row">
                  <span className={'settings-main-label'}>
                      <FormattedMessage id="app.password"/>
                  </span>
                  <span>
                    <TextField type={'password'} variant="outlined"
                               value={this.state.password}
                      // error={this.state.validation.password}
                               onChange={(event) => this.setState({password: event.target.value})}/>
                  </span>

                </div>
                <div className="settings-main__btn-right">
                  <div className={'wrapper-progress-btn '}>
                    <Button
                      color="secondary" size="small"
                      disabled={this.state.setLoading}
                      className={'progress-btn settings-main__btn'}
                      onClick={this.handleChangePersonal}
                    >
                      <FormattedMessage id="app.change"/>
                    </Button>
                    {this.state.setLoading && <CircularProgress size={24} className={'progress-btn-prog'}/>}
                  </div>
                </div>

              </div>
            </TabPanel>


            <TabPanel value={this.state.value} index={1}>
              <div className="settings-title-tab"><FormattedMessage id="app.masters"/></div>

              <div className="site-filter__btn">
                <Button variant="contained" color="primary" onClick={this.addMasterPopup}>
                  <FormattedMessage id="app.addMaster"/>
                </Button>

                {this.state.isOpen ?
                  <PopupWrapper classPopup={'add-master'} closePopup={this.closePopupAddMaster}>
                    <AddMasterPopup rowId={this.state.rowId}
                                    isEdit={this.state.isEdit}
                                    getMasters={this.getMasters}
                    />
                  </PopupWrapper>
                  : ''}
              </div>

              <TableContainer className={'products-table' + (this.props.loading ? ' loading-table' : '')}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow className={'products-table-head'}>
                      <TableCell>Id</TableCell>
                      <TableCell align="left"><FormattedMessage id="app.name"/></TableCell>
                      <TableCell align="left"><FormattedMessage id="app.lastName"/></TableCell>
                      <TableCell align="left"><FormattedMessage id="app.emailAddress"/></TableCell>
                      <TableCell align="left"><FormattedMessage id="app.phone"/></TableCell>
                      <TableCell align="right">

                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {this.props.masters.map(function (item, index) {
                      return (
                        <TableRow className={'products-table-row'} key={index}>
                          <TableCell align="left"> {item.id} </TableCell>
                          <TableCell align="left"> {item.name} </TableCell>
                          <TableCell align="left"> {item.last_name} </TableCell>
                          <TableCell align="left"> {item.email} </TableCell>
                          <TableCell align="left"> {item.phone} </TableCell>
                          <TableCell align="right">
                            <Button
                              color="primary" size="small" onClick={() => that.handlerEditMasters(item.id)}
                            >
                              <FormattedMessage id="app.edit"/>
                            </Button>
                            <Button
                              color="secondary" size="small" onClick={() => that.handlerDeleteMasters(item.id)}
                            >
                              <FormattedMessage id="app.remove"/>
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}


                  </TableBody>
                </Table>
              </TableContainer>

            </TabPanel>


            {this.props.userData.role === "ADMIN" ?
              <TabPanel value={this.state.value} index={2}>
                <div className="settings-title-tab"><FormattedMessage id="app.managers"/></div>

                <div className="site-filter__btn">
                  <Button variant="contained" color="primary" onClick={this.addManagerPopup}>
                    <FormattedMessage id="app.addManagers"/>
                  </Button>

                  {this.state.isOpenManagerPopup ?
                    <PopupWrapper classPopup={'add-master'} closePopup={this.closePopupAddManager}>
                      <AddManagerPopup rowId={this.state.rowId}
                                       isEdit={this.state.isEdit}
                                       getManagers={this.getManagers}/>
                    </PopupWrapper>
                    : ''}


                </div>

                <TableContainer className={'products-table' + (this.props.loading ? ' loading-table' : '')}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow className={'products-table-head'}>
                        <TableCell>Id</TableCell>
                        <TableCell align="left"><FormattedMessage id="app.name"/></TableCell>
                        <TableCell align="left"><FormattedMessage id="app.lastName"/></TableCell>
                        <TableCell align="left"><FormattedMessage id="app.emailAddress"/></TableCell>
                        <TableCell align="left"><FormattedMessage id="app.phone"/></TableCell>
                        <TableCell align="right">

                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {this.props.managers.map(function (item, index) {
                        return (
                          <TableRow className={'products-table-row'} key={index}>
                            <TableCell align="left"> {item.id} </TableCell>
                            <TableCell align="left"> {item.name} </TableCell>
                            <TableCell align="left"> {item.last_name} </TableCell>
                            <TableCell align="left"> {item.email} </TableCell>
                            <TableCell align="left"> {item.phone} </TableCell>
                            <TableCell align="right">
                              <Button
                                color="primary" size="small" onClick={() => that.handlerEditManagers(item.id)}
                              >
                                <FormattedMessage id="app.edit"/>
                              </Button>
                              <Button
                                color="secondary" size="small"
                                onClick={() => that.deleteManager(item.id)}
                              >
                                <FormattedMessage id="app.remove"/>
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      })}


                    </TableBody>
                  </Table>
                </TableContainer>

              </TabPanel>
              :
              null
            }


            <TabPanel value={this.state.value} index={3}>
              <div className="settings-title-tab"><FormattedMessage id="app.worksType"/></div>

              <div className="site-filter__btn">
                <Button variant="contained" color="primary" onClick={this.addServicePopup}>
                  <FormattedMessage id="app.addWorksType"/>
                </Button>

                {this.state.isOpenServicePopup ?
                  <PopupWrapper classPopup={'add-master'} closePopup={this.closePopupAddService}>
                    <AddServicePopup index={this.state.index}
                                     isEdit={this.state.isEdit}
                                     getService={this.getService}/>
                  </PopupWrapper>
                  : ''}


              </div>


              <TableContainer className={'products-table' + (this.props.loading ? ' loading-table' : '')}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow className={'products-table-head'}>
                      <TableCell>Id</TableCell>
                      <TableCell align="left"><FormattedMessage id="app.name"/></TableCell>
                      <TableCell align="left"><FormattedMessage id="app.price"/></TableCell>
                      <TableCell align="right">

                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {this.props.service.length > 0 ?
                      this.props.service.map(function (item, index) {
                        return (
                          <TableRow className={'products-table-row'} key={index}>
                            <TableCell align="left"> {item.id} </TableCell>
                            <TableCell align="left"> {item.name} </TableCell>
                            <TableCell align="left"> {item.price} </TableCell>
                            <TableCell align="right">
                              <Button
                                color="primary" size="small" onClick={() => that.handlerEditService(index)}
                              >
                                <FormattedMessage id="app.edit"/>
                              </Button>
                              <Button
                                color="secondary" size="small" onClick={() => that.handlerDeleteService(item.id)}
                              >
                                <FormattedMessage id="app.remove"/>
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      })
                      :

                      <TableRow className={'products-table-row'}>
                        <TableCell align="left" colSpan={4}><h2 className={'no-records'}><FormattedMessage
                          id="app.noRecords"/></h2></TableCell>
                      </TableRow>
                    }


                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>


            <TabPanel value={this.state.value} index={4}>
              <div className="settings-title-tab"><FormattedMessage id="app.supplier"/></div>

              <div className="site-filter__btn">
                <Button variant="contained" color="primary" onClick={this.addSupplierPopup}>
                  <FormattedMessage id="app.addSupplier"/>
                </Button>

                {this.state.isOpenSupplierPopup ?
                  <PopupWrapper classPopup={'add-master'} closePopup={this.closePopupAddSupplier}>
                    <AddSupplierPopup index={this.state.index}
                                      isEdit={this.state.isEdit}
                                      getSupplier={this.getSupplier}/>
                  </PopupWrapper>
                  : ''}


              </div>


              <TableContainer className={'products-table' + (this.props.loading ? ' loading-table' : '')}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow className={'products-table-head'}>
                      <TableCell>Id</TableCell>
                      <TableCell align="left"><FormattedMessage id="app.name"/></TableCell>
                      <TableCell align="left"><FormattedMessage id="app.description"/></TableCell>
                      <TableCell align="right">

                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {this.props.supplier.length > 0 ?
                      this.props.supplier.map(function (item, index) {
                        return (
                          <TableRow className={'products-table-row'} key={index}>
                            <TableCell align="left"> {item.id} </TableCell>
                            <TableCell align="left"> {item.name} </TableCell>
                            <TableCell align="left"> {item.details} </TableCell>
                            <TableCell align="right">
                              <Button
                                color="primary" size="small" onClick={() => that.handlerEditSupplier(index)}
                              >
                                <FormattedMessage id="app.edit"/>
                              </Button>
                              <Button
                                color="secondary" size="small" onClick={() => that.handlerDeleteSupplier(item.id)}
                              >
                                <FormattedMessage id="app.remove"/>
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      })

                      :
                      <TableRow className={'products-table-row'}>
                        <TableCell align="left" colSpan={4}><h2 className={'no-records'}><FormattedMessage
                          id="app.noRecords"/></h2></TableCell>
                      </TableRow>
                    }


                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>


            <TabPanel value={this.state.value} index={5}>
              <div className="settings-title-tab"><FormattedMessage id="app.expenses"/></div>

              <div className="site-filter__btn">
                <Button variant="contained" color="primary" onClick={this.addExpensesPopup}>
                  <FormattedMessage id="app.addExpenses"/>
                </Button>

                {this.state.isOpenExpensesPopup ?
                  <PopupWrapper classPopup={'add-master'} closePopup={this.closePopupAddExpenses}>
                    <AddExpensesTypePopup index={this.state.index}
                                          isEdit={this.state.isEdit}
                                          getExpenses={this.getExpenses}/>
                  </PopupWrapper>
                  : ''}


              </div>


              <TableContainer className={'products-table' + (this.props.loading ? ' loading-table' : '')}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow className={'products-table-head'}>
                      <TableCell>Id</TableCell>
                      <TableCell align="left"><FormattedMessage id="app.name"/></TableCell>
                      <TableCell align="right">

                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {this.props.expenses.length > 0 ?

                      this.props.expenses.map(function (item, index) {
                        return (
                          <TableRow className={'products-table-row'} key={index}>
                            <TableCell align="left"> {item.id} </TableCell>
                            <TableCell align="left"> {item.name} </TableCell>
                            <TableCell align="right">
                              <Button
                                color="primary" size="small" onClick={() => that.handlerEditExpenses(index)}
                              >
                                <FormattedMessage id="app.edit"/>
                              </Button>
                              {/*<Button*/}
                              {/*color="secondary" size="small" onClick={() => that.handlerDeleteExpenses(item.id)}*/}
                              {/*>*/}
                              {/*<FormattedMessage id="app.remove"/>*/}
                              {/*</Button>*/}
                            </TableCell>
                          </TableRow>
                        )
                      })

                      :
                      <TableRow className={'products-table-row'}>
                        <TableCell align="left" colSpan={5}><h2 className={'no-records'}><FormattedMessage
                          id="app.noRecords"/></h2></TableCell>
                      </TableRow>
                    }


                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.settings.loading,
    masters: state.settings.masters,
    managers: state.settings.managers,
    service: state.settings.service,
    supplier: state.settings.supplier,
    expenses: state.settings.expenses,
    avatar: state.settings.avatar,
    userData: state.auth.userData,
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchMasters: () => dispath(fetchMasters()),
    showAvatar: () => dispath(showAvatar()),
    getCurrentUser: () => dispath(getCurrentUser()),
    fetchManagers: () => dispath(fetchManagers()),
    deleteManager: (id) => dispath(deleteManager(id)),
    fetchService: () => dispath(fetchService()),
    deleteService: (index) => dispath(deleteService(index)),
    fetchSupplier: () => dispath(fetchSupplier()),
    deleteSupplier: (index) => dispath(deleteSupplier(index)),
    fetchExpensesType: () => dispath(fetchExpensesType()),
    deleteExpenses: (index) => dispath(deleteExpenses(index)),
    editUser: (data, id) => dispath(editUser(data, id)),
    storeAvatar: (data) => dispath(storeAvatar(data)),
    deleteMasters: (id) => dispath(deleteMasters(id)),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(Settings));

