import {AUTH_LOGOUT, AUTH_SUCCESS, AUTH_USER_DATA, AUTH_USER_ID} from "../action/actionTypes";

const initialState = {
  token: null,
  isAuthenticated: true,
  id: null,
  userData: {
    name: '',
    last_name: ''
  }
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_SUCCESS:
      return{
        ...state, token: action.token, isAuthenticated: true
      }

    case AUTH_USER_ID:
      return{
        ...state, id: action.id
      }

    case AUTH_USER_DATA:
      return{
        ...state, userData: action.userData
      }

    case AUTH_LOGOUT:
      return {
        ...state, token: null, isAuthenticated: false
      }

    default: return state
  }
}
