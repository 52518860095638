import React, {Component} from 'react'
import './NewPass.css'


import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {connect} from "react-redux";
import {auth, newPass} from "../../../store/action/auth";
import {NavLink, withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../../sevices/validationService";
import LanguagesHead from "../../../components/Languages/LanguagesHead/LanguagesHead";

class NewPass extends Component {

  loginHandler = () => {
    // this.props.auth(
    //   'email',
    //   'pass',
    //   true
    // )
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const email = params.get('email');
    this.setState({email: email})
  }

  state = {
    email: '',
    pass: '',
    passSecond: '',
    error: true,
    success: true,
    passError: false,

    validation: {
      email: false,
      passSecond: false,
      pass : false
    }
  }

  handleLogin = () => {

    if(this.state.passSecond !== this.state.pass){
      this.setState({passError: true})
      return;
    } else {
      this.setState({passError: false})
    }

    let dataForm = {
      email : this.state.email,
      password : this.state.pass,
    };


    let validation = fieldEmpty(dataForm);
    let validEmail = fieldEmail(dataForm.email);
    let validPassword = fieldPassword(dataForm.password);


    if (!validation.isValidation || !validEmail || !validPassword) {

      if(!validEmail){
        validation.validation.email = true;
      }

      if(!validPassword){
        validation.validation.pass = true;
      }

      this.setState({
        validation: validation.validation
      });

      return;
    }


    dataForm.token = this.props.match.params.id;

    this.props.newPass(dataForm).then((response) => {
      if (response === undefined) {
        this.setState({success: false})
      }
      this.props.history.push('/')
    })

  }

  handleChangeEmail = (event) => {
    this.setState({email: event.target.value});
  }

  handleChangePass = (event) => {
    console.log(event);
    this.setState({pass: event.target.value});
  }

  handleChangePassSecond = (event) => {
    console.log(event);
    this.setState({passSecond: event.target.value});
  }

  render() {
    const params = new URLSearchParams(this.props.location.search);
    const email = params.get('email');

    console.log(this.props.match.params.id);
    console.log(email);

    return (
      <div className={'login-page'}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div >
            <div className="fixed-lang">
              <LanguagesHead/>
            </div>


            <Typography component="h1" variant="h5">
              <FormattedMessage id="app.signIn"/>
            </Typography>
            {/*<form  noValidate onSubmit={this.handleLogin}>*/}
            <form>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                value={email}
                InputLabelProps={{
                  readOnly: Boolean(true),
                }}
                label={<FormattedMessage id="app.emailAddress"/>}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={this.handleChangeEmail}
                error={this.state.validation.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                value={this.state.pass}
                label={<FormattedMessage id="app.password"/>}
                type="password"
                name="password"
                onChange={this.handleChangePass}
                error={this.state.validation.pass}
                autocomplete='new-password'
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={'Повторите пароль'}
                value={this.state.passSecond}
                type="password"
                id="password"
                autocomplete='new-password'
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={this.handleChangePassSecond}
                error={this.state.validation.passSecond}
              />
              {/*<FormControlLabel*/}
                {/*control={<Checkbox value="remember" color="primary" />}*/}
                {/*label={<FormattedMessage id="app.rememberMe"/>}*/}
              {/*/>*/}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.handleLogin}
              >
                Обновить пароль
              </Button>
              {!this.state.success ?
                <div className="login-page__error">
                  <FormattedMessage id="app.dataIncorrectly"/>
                </div>
                : null
              }
              {this.state.passError ?
                <div className="login-page__error">
                 Пароли не совпадают
                </div>
                : null
              }

            </form>
          </div>

        </Container>
      </div>

    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newPass: (data) => dispatch(newPass(data))
  }
}

export default connect(null, mapDispatchToProps)(withRouter(NewPass))
