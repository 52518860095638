import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";


class ErrorPage extends Component {

  componentDidMount() {
    document.title = this.props.intl.formatMessage({
      id: 'app.pageNotFound',
    });
  }

  render() {

    return (
      <div className="wrapper-404">
        <span className="p-404"> <FormattedMessage id="app.pageNotFound"/> <span> 404</span></span>
        <a href="/" className="back-to-main"><FormattedMessage id="app.goToMainPage"/></a>
      </div>
    )
  }
}

export default injectIntl(ErrorPage)
