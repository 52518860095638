import {combineReducers} from "redux";
import { intlReducer } from 'react-intl-redux'
import authReducer from "./authReducer";
import mainReducer from "./mainReducer";
import projectsReducer from "./projectsReducer";
import draftsReducer from "./draftsReducer";
import supplierReducer from "./supplierReducer";
import settingsReducer from "./settingsReducer";
import clientsReducer from "./clientsReducer";
import outfitsReducer from "./outfitsReducer";
import appointmentReducer from "./appointmentReducer";
import productsReducer from "./productsReducer";
import productWaybillsReducer from "./productWaybillsReducer";
import billsReducer from "./billsReducer";
import expensesReducer from "./expensesReducer";
import dashboardReducer from "./dashboardReducer";
import workersStatisticReducer from "./workersStatisticReducer";


export default combineReducers({
  // mainReducer: mainReducer,
  intl: intlReducer,
  main: mainReducer,
  projects: projectsReducer,
  supplier: supplierReducer,
  settings: settingsReducer,
  drafts: draftsReducer,
  clients: clientsReducer,
  outfits: outfitsReducer,
  appointment: appointmentReducer,
  products: productsReducer,
  productWaybills: productWaybillsReducer,
  bills: billsReducer,
  expenses: expensesReducer,
  dashboard: dashboardReducer,
  workersStatistic: workersStatisticReducer,
  auth: authReducer
})
