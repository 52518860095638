import {
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_ERROR,
  FETCH_PROJECTS_COMPLETED_SUCCESS
} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";


export function createTask(data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post('projects/create', data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }

}

export function performTask(id, data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`task/${id}/status`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.log(e);
    }

  }
}

export function fetchProjects() {
  return async dispatch => {
    dispatch(fetchProjectsStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get('task/active', {headers: { Authorization: `Bearer ${token}` }})
      dispatch(fetchProjectsSuccess(response.data))
    } catch (e) {
      console.log(fetchProjectsError(e));
    }
  }
}

export function fetchCompletedProjects() {
  return async dispatch => {
    const token = localStorage.getItem('token');
    try {
      const response = await API.get('task/completed', {headers: { Authorization: `Bearer ${token}` }})
      dispatch(fetchCompletedProjectsSuccess(response.data))
    } catch (e) {
      console.log(fetchProjectsError(e));
    }
  }
}


export function fetchProjectsStart() {
  return {
    type: FETCH_PROJECTS_START
  }
}

export function fetchProjectsSuccess(projects) {
  return {
    type: FETCH_PROJECTS_SUCCESS,
    projects
  }
}

export function fetchCompletedProjectsSuccess(completed) {
  return {
    type: FETCH_PROJECTS_COMPLETED_SUCCESS,
    completed
  }
}

export function fetchProjectsError(e) {
  return {
    type: FETCH_PROJECTS_ERROR,
    error: e
  }
}
