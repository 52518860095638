import React, {Component} from 'react'
import './AddMasterPopup.css'

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";
import {fetchClientCarsBrand} from "../../../store/action/clients";
import connect from "react-redux/es/connect/connect";
import {createMaster} from "../../../store/action/settings";
import {editMaster} from "../../../store/action/settings";
import {logout} from "../../../store/action/auth";
import {openMenu} from "../../../store/action/main";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../../sevices/validationService";
import InputMask from 'react-input-mask';


class AddMasterPopup extends Component {

  state = {
    name: '',
    last_name : '',
    phone : '',
    role: 'MASTER',
    email : '',
    state: 'ACTIVE',
    password : 'QWERTYqwerty123456',

    validation: {
      name: false,
      last_name : false,
      phone : false,
      email : false,
      password : false,
    },
    validError: false,
  }



  handleClose = () => {
    this.props.handleClose();
  }

  handlerSaveForm =() =>{
    let dataForm = {
      name: this.state.name,
      last_name : this.state.last_name,
      phone : this.state.phone,
      role : this.state.role,
      email : this.state.email,
      state : this.state.state,
      password : this.state.password,
    };

    let validation = fieldEmpty(dataForm);
    let validEmail = fieldEmail(dataForm.email);
    let validPassword = fieldPassword(dataForm.password);


    if (!validation.isValidation || !validEmail || !validPassword) {

      if(!validEmail){
        validation.validation.email = true;
      }

      if(!validPassword){
        validation.validation.password = true;
      }

      this.setState({
        validation: validation.validation
      });

      return;
    }


    this.props.createMaster(dataForm).then((response) => {
      console.log(response);
      if(response.status == 200){
        this.props.handleClose();
        this.props.getMasters();
      }
      if(response.errors){
        this.setState({validError: true})
      }
    });
  }

  handlerEditForm =() =>{

    let dataForm = {
      name: this.refs.masterName.getElementsByTagName('input')[0].value,
      last_name : this.refs.masterLastName.getElementsByTagName('input')[0].value,
      phone : this.refs.masterPhone.value,
      role: 'MASTER',
      email : this.refs.masterEmail.getElementsByTagName('input')[0].value,
      state: 'ACTIVE',
      // password : this.refs.masterPass.getElementsByTagName('input')[0].value,
      password : "QWERTYqwerty123456",
    }

    let validation = fieldEmpty(dataForm);
    let validEmail = fieldEmail(dataForm.email);
    let validPassword = fieldPassword(dataForm.password);

    if (!validation.isValidation) {

      if(!validEmail){
        validation.validation.email = true;
      }

      if(!validPassword){
        validation.validation.password = true;
      }

      this.setState({
        validation: validation.validation
      });

      return;
    }

    this.props.editMaster(dataForm, this.props.rowId).then((response) => {
      if(response.status == 200){
        this.props.handleClose();
        this.props.getMasters();
      }
      if(response.errors){
        this.setState({validError: true})
      }
    });
  }


  render() {

    // console.log(this.props.masters);


    return (


      <div className="modal-popup__window container">
        <div className="modal-popup__header">
                <span>
                  {this.props.isEdit ? <FormattedMessage id="app.edit"/> : <FormattedMessage id="app.addMaster"/>}
                </span>
        </div>
        <div className="modal-popup__body">
          <div className="waybills-popup__inner">
            {this.props.isEdit ?
              <React.Fragment>

                {this.props.masters.map((master, index) => {
                  if(master.id === this.props.rowId){
                    return (
                      <div className="row" key={index} >
                        <div className="col-12">

                          <div className="row">
                            <div className={'col-12'}>
                              <TextField className={"task-create__name-input"} id="standard-basic"
                                         defaultValue={master.name}
                                         error={this.state.validation.name}
                                         inputProps={{'data-name': 'name'}}
                                         onChange={(event) => this.setState({name: event.target.value})}
                                         ref={'masterName'}
                                         label={<FormattedMessage id="app.name"/>}/>
                            </div>

                            <div className={'col-12'}>
                              <TextField className={"task-create__name-input"} id="standard-basic"
                                // value={this.props.master.lastName}
                                         defaultValue={master.last_name}
                                         error={this.state.validation.last_name}
                                         inputProps={{'data-name': 'lastName'}}
                                         onChange={(event) => this.setState({last_name: event.target.value})}
                                         ref={'masterLastName'}
                                         label={<FormattedMessage id="app.lastName"/>}/>

                            </div>

                            <div className={'col-12'}>
                              <InputMask
                                mask="+380(99)9999999"
                                disabled={false}
                                maskChar="_"
                                defaultValue={master.phone}
                                onChange={(event) => this.setState({phone: event.target.value})}
                                inputProps={{'data-name': 'phone'}}
                                ref={'masterPhone'}
                              >
                                {() => <TextField
                                  className={"task-create__name-input"}
                                  label={<FormattedMessage id="app.phone"/>}
                                  error={this.state.validation.phone}/>
                                }
                              </InputMask>

                            </div>

                            <div className={'col-12'}>
                              <TextField className={"task-create__name-input"} id="standard-basic"
                                // value={this.props.master.emailAddress}
                                         defaultValue={master.email}
                                         error={this.state.validation.email}
                                         inputProps={{'data-name': 'emailAddress'}}
                                         onChange={(event) => this.setState({email: event.target.value})}
                                         ref={'masterEmail'}
                                         label={<FormattedMessage id="app.emailAddress"/>}/>

                            </div>

                            {/*<div className={'col-12'}>*/}
                              {/*<TextField className={"task-create__name-input"} id="standard-basic"*/}
                                {/*// value={this.state.password}*/}
                                         {/*inputProps={{'data-name': 'password'}}*/}
                                         {/*error={this.state.validation.password}*/}
                                         {/*onChange={(event) => this.setState({password: event.target.value})}*/}
                                         {/*type="password"*/}
                                         {/*ref={'masterPass'}*/}
                                         {/*label={<FormattedMessage id="app.password"/>}/>*/}

                            {/*</div>*/}
                          </div>
                        </div>

                      </div>
                    )
                  }

                })}


                              </React.Fragment>

              :

              <React.Fragment>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"}
                                   error={this.state.validation.name}
                                   value={this.state.name}
                                   inputProps={{'data-name': 'name'}}
                                   onChange={(event) => this.setState({name: event.target.value})}
                                   label={<FormattedMessage id="app.name"/>}/>
                      </div>

                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"}
                                   error={this.state.validation.last_name}
                                   value={this.state.last_name}
                                   inputProps={{'data-name': 'lastName'}}
                                    onChange={(event) => this.setState({last_name: event.target.value})}
                                   label={<FormattedMessage id="app.lastName"/>}/>

                      </div>

                      <div className={'col-12'}>
                        <InputMask
                          mask="+380(99)9999999"
                          disabled={false}
                          maskChar="_"
                          value={this.state.phone}
                          onChange={(event) => this.setState({phone: event.target.value})}
                          inputProps={{'data-name': 'phone'}}
                        >
                          {() => <TextField
                            className={"task-create__name-input"}
                            label={<FormattedMessage id="app.phone"/>}
                            error={this.state.validation.phone}/>
                          }
                        </InputMask>
                      </div>

                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"}
                                   error={this.state.validation.email}
                                   value={this.state.email}
                                   inputProps={{'data-name': 'emailAddress'}}
                                   onChange={(event) => this.setState({email: event.target.value})}
                                   label={<FormattedMessage id="app.emailAddress"/>}/>

                      </div>

                      {/*<div className={'col-12'}>*/}
                        {/*<TextField className={"task-create__name-input"}*/}
                                   {/*error={this.state.validation.password}*/}
                                   {/*value={this.state.password}*/}
                                   {/*inputProps={{'data-name': 'password'}}*/}
                                   {/*onChange={(event) => this.setState({password: event.target.value})}*/}
                                   {/*type="password"*/}
                                   {/*label={<FormattedMessage id="app.password"/>}/>*/}

                      {/*</div>*/}
                    </div>
                  </div>

                </div>

              </React.Fragment>
            }


          </div>

          {this.state.validError ?
            <div className={'error-txt'}>
              Такой Email уже используется
            </div>
            :
            null
          }

          <div className="modal-popup__footer">
            <div className="modal-popup__btn">
              <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={this.handleClose}>
                <FormattedMessage id="app.close"/>
              </Button>

              {this.props.isEdit ?
                <Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                        onClick={this.handlerEditForm}>
                  <FormattedMessage id="app.change"/>
                </Button>

                :

                < Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                         onClick={this.handlerSaveForm}>
                  <FormattedMessage id="app.save"/>
                </Button>
              }
            </div>
          </div>

        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    masters: state.settings.masters,
  }
}

function mapDispathToProps(dispath) {
  return {
    createMaster: (data) => dispath(createMaster(data)),
    editMaster: (data, id) => dispath(editMaster(data, id))
  }
}


export default connect(mapStateToProps, mapDispathToProps)(AddMasterPopup);

