import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import AddProductPopup from "../../components/ModalPopups/AddProductPopup/AddProductPopup";

import './Products.css'
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import TableHeader from "../../components/Tables/TableHeader/TableHeader.js";
import {fetchManagers, fetchMasters, fetchService, fetchSupplier} from "../../store/action/settings";
import connect from "react-redux/es/connect/connect";
import {exportProducts, fetchProducts, importProducts, updateProducts} from "../../store/action/products";
import SellProductPopup from "../../components/ModalPopups/SellProductPopup/SellProductPopup";
import AddProductWaybillsPopup from "../../components/ModalPopups/AddProductWaybillsPopup/AddProductWaybillsPopup";
import TextField from "@material-ui/core/TextField";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import {API} from "../../axios/axiosMainUrl";
import {fetchRecipient} from "../../store/action/bills";
import QuestionPopup from "../../components/ModalPopups/QuestionPopup/QuestionPopup";


class Products extends Component {

  state = {
    isOpen: false,
    isOpenSell: false,
    sellIndex: null,
    perPage: 30,
    currentPage: 1,
    search: '',
    alert: false,
    isOpenQuestion: false,

    productChangeName: {
      id: '',
      name: '',
      oldName: '',
    },


    importCount: 0
  }

  componentDidMount() {
    this.fetchDataCreate()

    document.title = this.props.intl.formatMessage({
      id: 'app.products',
    });


  }

  reRender = () => {
    this.fetchDataCreate()
  }

  addProductWaybillsHandle = () => {
    this.setState({
      isOpen: true
    })
  }

  closePopupProductWaybills = () => {
    this.setState({
      isOpen: false
    })
  }

  addProductHandle = () => {
    this.setState({
      isOpen: true
    })
  }
  handleSell = (index) => {
    this.setState({
      isOpenSell: true,
      sellIndex: index
    })
  }
  closePopupAddProduct = () => {
    this.setState({
      isOpen: false
    })
  }
  closePopupSellProduct = () => {
    this.setState({
      isOpenSell: false
    })
  }

  handleSearch = () => {
    this.fetchDataCreate();
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.fetchDataCreate(true);
    }
  }

  filterReset = () => {
    this.setState({
      search: '',
    })

    setTimeout(() => this.fetchDataCreate(true))
  }

  fetchDataCreate = (filter = false) => {

    let data = '',
        curPage = this.state.currentPage,
        perPage = this.state.perPage,
        search = this.state.search !== '' ? this.state.search : null;

    if(filter) {
      curPage = 1;
      this.setState({currentPage: 1});
    }

    data = data + `?page=${curPage}&limit=${perPage}`;

    if (search !== null) data = data + `&filter=name:${search},article:${search},supplier_name:${search}`;


    this.props.fetchProducts(data)
  }


  handleChangePage = (event, newPage) => {
    this.setState({ currentPage: newPage + 1})
    setTimeout(() => this.fetchDataCreate())
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ perPage: parseInt(event.target.value, 10) })
    this.setState({ currentPage: 1 })
    setTimeout(() => this.fetchDataCreate())
  }

  changeImportHandler = () => {
    // let a = document.getElementById('form');

    // let formData = new FormData();
    //
    // formData.append("avatar", event.target.files[0], event.target.files[0].name);
    //
    // this.props.storeAvatar(formData).then(() => this.props.showAvatar());
  }

  exportProducts = () => {
    let data = '',
      search = this.state.search !== '' ? this.state.search : null;

    if (search !== null) data = data + `?filter=name:${search},article:${search},supplier_name:${search}`;

    this.props.exportProducts(data).then((response) => {

      function exportToCsv(filename, rows) {
        var blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, filename);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {

            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            // document.body.removeChild(link);
          }
        }
      }

      exportToCsv('export.csv');
    });



  }

  importProducts = (event) => {
    let formData = new FormData();

    formData.append("import-file", event.target.files[0], event.target.files[0].name);

    this.props.importProducts(formData).then((response) => {
      if(response.errors === undefined) {
        this.setState({alert: true, importCount: response.newRowCount});
        this.fetchDataCreate();
        console.log(this.refs.file.value = '');
      }
    });
  }

  changeProductName = (event, id, value) => {

    if(event.target.value === value){
      return;
    }

    if(event.target.value.length > 20 ){
      this.setState({alert2: true})
      return;
    }

    let productChangeName = {
      id: id,
      name: event.target.value,
      oldName: value,
      event: event.target,
    };

    this.setState({
      productChangeName: productChangeName
    });

    this.setState({isOpenQuestion: true})
  }

  closePopup = () => {
    this.setState({isOpenQuestion: false})
  }

  confirmChangeProductName = (is) => {
    if (is) {
      let data = {
        name: this.state.productChangeName.name
      }
      this.props.updateProducts(this.state.productChangeName.id, data).then(() => {
        this.reRender();
        this.closePopup();
      });
    } else {
      let input = this.state.productChangeName.event;
      input.value = this.state.productChangeName.oldName;
    }
    // this.setState({isOpenQuestion: false})
  }

  render() {



    let headerTable = [
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.id"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.name"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.article"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.supplier"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.count"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.purchasePrice"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.sellingPrice"/>
      },
      {
        colspan: '0',
        align: 'right',
        item: ''
      }
    ]

    const importExampleUrl = API.defaults.baseURL.substring(0, API.defaults.baseURL.length-4) + 'files/import-template.csv';

    // console.log(this.props.products);

    return (
      <div className="products">
        <div className="page-title">
           <span className={'header__name'}>
              <FormattedMessage id="app.products"/>
            </span>
        </div>

        <div>
          <Collapse in={this.state.alert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({alert: false})
                  }}
                >
                  <FormattedMessage id="app.close"/>
                </IconButton>
              }
            >
              <FormattedMessage id="app.successImport"/>
              Количество импортированых записей
            </Alert>
          </Collapse>
        </div>

        <div>
          <Collapse in={this.state.alert2}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({alert2: false})
                  }}
                >
                  <FormattedMessage id="app.close"/>
                </IconButton>
              }
            >
              Максимальное количество в названии товара 20 символов
            </Alert>
          </Collapse>
        </div>

        <div className="site-filter__btn">

          <div className="filter-head__left">
            <div className="filter-client-wrap">
              <TextField className="filter-client-select"
                         onChange={(event) => this.setState({search: event.target.value})}
                         value={this.state.search}
                         size="small" label={<FormattedMessage id="app.search"/> }
                         onKeyDown={this._handleKeyDown}
                         variant="outlined"
              />
            </div>
            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.filterReset}>
              <FormattedMessage id="app.reset"/>
            </Button>
            <Button variant="contained"
                    className="yellow-btn recording__add-btn"
                    onClick={() => this.fetchDataCreate( true)}
            >
              <FormattedMessage id="app.search"/>
            </Button>
          </div>

          <form action="#" method={'post'} id={'form'} encType="multipart/form-data">
            <Button
              color="primary"
              variant="contained"
              component="label"
            >
              <FormattedMessage id="app.import"/>
              <input
                type="file"
                style={{display: "none"}}
                onChange={(event) => this.importProducts(event)}
                ref={'file'}
              />
            </Button>

          </form>

          {/*<a href={importExampleUrl} className={'ml-2'}>*/}
            {/*<Button variant="contained" color="primary" >*/}
              {/*Импорт пример*/}
            {/*</Button>*/}
          {/*</a>*/}


          <Button variant="contained" color="secondary" onClick={this.exportProducts}>
            <FormattedMessage id="app.export"/>
          </Button>



          <Button variant="contained" className="yellow-btn " onClick={this.addProductWaybillsHandle}>
            <FormattedMessage id="app.addNote"/>
          </Button>


          {this.state.isOpen ?
            <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopupProductWaybills}>
              <AddProductWaybillsPopup reRender={this.reRender} />
            </PopupWrapper>
            : ''}


          {this.state.isOpenSell ?
            <PopupWrapper classPopup={'add-product'} closePopup={this.closePopupSellProduct}>
              <SellProductPopup sellIndex={this.state.sellIndex} reRender={this.reRender} />
            </PopupWrapper>
            : ''}

          {this.state.isOpenQuestion ?
            <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopup}>
              {/*<div>*/}
              <QuestionPopup no={() => this.confirmChangeProductName(false)} yes={() => this.confirmChangeProductName(true)}
                             question={'Вы уверены что хотите Изменить название товара?'}/>
              {/*</div>*/}
            </PopupWrapper>
            : ''}

        </div>

        <TableContainer className={'products-table' + (this.props.loading ? ' loading-table' : '')}>
          <Table size="small" aria-label="a dense table">
            <TableHeader rows={headerTable}/>
            <TableBody>

              {

                this.props.products.hasOwnProperty('data') ?
                Object.keys(this.props.products.data).map((item, index) => {
                // this.props.products.map((product, index) => {
                return (
                  <TableRow className={'products-table-row'} key={index}>
                    <TableCell align="left"> { this.props.products.data[item].id } </TableCell>
                    <TableCell align="left">
                      {/*{ this.props.products.data[item].name }*/}
                      <TextField className="filter-client-select"
                                 // onChange={(event) => this.changeProductName(event, this.props.products.data[item].id)}
                                 defaultValue={this.props.products.data[item].name}
                                 size="small"
                                 // label={<FormattedMessage id="app.search"/> }
                                 // onKeyDown={this._handleKeyDown}
                                 onBlur={(event) => this.changeProductName(event, this.props.products.data[item].id, this.props.products.data[item].name)}
                                 variant="outlined"
                      />
                    </TableCell>
                    <TableCell align="left"> { this.props.products.data[item].article } </TableCell>
                    <TableCell align="left"> { this.props.products.data[item].supplier_name !== null ? this.props.products.data[item].supplier_name : null} </TableCell>
                    <TableCell align="left"> { parseInt(this.props.products.data[item].stock_real) } </TableCell>
                    <TableCell align="left"> { this.props.products.data[item].last_net_price} </TableCell>
                    <TableCell align="left"> { this.props.products.data[item].price} </TableCell>
                    <TableCell align="right">
                      {parseInt(this.props.products.data[item].stock_real) <= 0 ? null :
                        <Button variant="contained" color="primary" size="small" onClick={() => {this.handleSell(index)}}>
                          <FormattedMessage id="app.sell"/>
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                )
              })
                :
                null
              }


            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 30, 50, 100]}
                  colSpan={8}
                  count={this.props.products.total}
                  rowsPerPage={this.state.perPage}
                  page={this.state.currentPage - 1}
                  labelRowsPerPage={<FormattedMessage id="app.rowsPerPage"/>}
                  SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.products.loading,
    products: state.products.products,
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchProducts: (data) => dispath(fetchProducts(data)),
    exportProducts: (data) => dispath(exportProducts(data)),
    importProducts: (data) => dispath(importProducts(data)),
    updateProducts: (id, data) => dispath(updateProducts(id, data)),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(Products));
