import {
  FETCH_CLIENTS_START,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_ERROR,
  FETCH_CLIENTS_CAR_BRAND,
  FETCH_CLIENTS_CAR_BRAND_ERROR, FETCH_CLIENTS_CAR_MODELS_ERROR, FETCH_CLIENTS_CAR_MODELS, CLEAR_CLIENTS_CAR_MODELS
} from "../action/actionTypes";

const initialState = {
  clients: {},
  carsBrand: [],
  carModels: [],
  loading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLIENTS_START:
      return{
        ...state, loading: true
      }

    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state, loading: false, clients: action.clients
      }

    case FETCH_CLIENTS_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    case FETCH_CLIENTS_CAR_BRAND:
      return{
        ...state, carsBrand: action.carsBrand
      }

    case FETCH_CLIENTS_CAR_BRAND_ERROR:
      return {
        ...state, error: action.error
      }

    case FETCH_CLIENTS_CAR_MODELS:
      return{
        ...state, carModels: action.carModels
      }

    case CLEAR_CLIENTS_CAR_MODELS:
      return{
        ...state, carModels: []
      }

    case FETCH_CLIENTS_CAR_MODELS_ERROR:
      return {
        ...state, error: action.error
      }


    default: return state
  }
}
