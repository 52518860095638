import {
  FETCH_APPOINTMENT_START,
  FETCH_APPOINTMENT_SUCCESS,
  FETCH_APPOINTMENT_ERROR
} from "../action/actionTypes";

const initialState = {
  appointment: {},
  loading: false,
  error: null
}

export default  function authReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_APPOINTMENT_START:
      return{
        ...state, loading: true
      }

    case FETCH_APPOINTMENT_SUCCESS:
      return {
        ...state, loading: false, appointment: action.appointment
      }

    case FETCH_APPOINTMENT_ERROR:
      return {
        ...state, loading: false, error: action.error
      }

    default: return state
  }
}

