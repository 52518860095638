import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import './AddRecordingPopup.css';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import connect from "react-redux/es/connect/connect";
import {createAppointment, updateAppointment} from "../../../store/action/appointment";
import {fetchClients} from "../../../store/action/clients";
import {fetchMasters} from "../../../store/action/settings";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../../sevices/validationService";


class AddRecordingPopup extends Component {

  state = {
    id: '',
    date: '',
    task_name: '',
    client_name: '',
    user_name: '',
    client_phone: '',
    client: '',

    validation: {
      date: false,
      task_name: false,
      client_name: false,
      user_name: false,
      client_phone: false,
    }
  }

  componentDidMount() {
    this.props.fetchMasters();

    if (this.props.isEdit) {
      console.log(this.props.itemEdit);
      this.setState({
        id: this.props.itemEdit.id,
        date: this.props.itemEdit.date.replace(' ', 'T'),
        task_name: this.props.itemEdit.task_name,
        client_name: this.props.itemEdit.client_name,
        user_name: this.props.itemEdit.user_name,
        client_phone: this.props.itemEdit.client_phone,
      })

      this.props.fetchClients().then(() => {
        this.props.clients.map(item => {
          if (item.phone === this.props.itemEdit.client_phone) {
            this.setState({
              client: item,
            })
          }
        })
      });
    } else {
      this.props.fetchClients()
    }


  }

  handleClose = () => {
    this.props.handleClose();
  }

  handleAutocompleteChangeInputClient = (event, val) => {
    console.log(event, val);
    if (event === null) return;

    let clientPhone = '';
    this.props.clients.map(function (item, index) {
      if (item.first_name === event) {
        clientPhone = item.phone;
      }
    });

    this.setState({
      client_phone: event.phone,
      client_name: event.first_name,
      client: event
    })


  }

  handleAutocompleteChangeInputMaster = (event) => {
    if (event === null) return;

    this.setState({
      user_name: event
    })
  }

  createRecord = () => {
    let data = {
      date: this.state.date,
      task_name: this.state.task_name,
      client_name: this.state.client_name,
      user_name: this.state.user_name,
      client_phone: this.state.client_phone,
    }

    let validation = fieldEmpty(data);

    if (!validation.isValidation) {
      this.setState({
        validation: validation.validation
      });
      return;
    }

    this.props.createAppointment(data).then((response) => {
      console.log(response);
      this.props.reRender();
      this.props.handleClose();
    })
  }

  updateRecord = () => {
    let data = {
      id: this.state.id,
      date: this.state.date,
      task_name: this.state.task_name,
      client_name: this.state.client_name,
      user_name: this.state.user_name,
      client_phone: this.state.client_phone,
    }

    let validation = fieldEmpty(data);

    if (!validation.isValidation) {
      this.setState({
        validation: validation.validation
      });
      return;
    }

    this.props.updateAppointment(this.state.id, data).then((response) => {
      console.log(response);
      this.props.reRender();
      this.props.handleClose();
    })
  }

  render() {

    let masters = [];
    this.props.masters.map(function (item, index) {
      masters[index] = item.name
    })

    let clients = [];
    // this.props.clients.map(function (item, index) {
    //   clients[index] = (item.first_name)
    // })

    return (

      <div className="modal-popup__window">
        <div className="modal-popup__header">
                <span>
                  {this.props.isEdit ? <FormattedMessage id="app.edit"/> : <FormattedMessage id="app.addNote"/>}
                </span>
        </div>
        <div className="modal-popup__body">

          {this.props.isEdit ?
            <div className="row">
              <div className="col-md-12">
                <div className="modal-popup__row">
                  <TextField
                    value={this.state.task_name}
                    onChange={(event) => this.setState({task_name: event.target.value})}
                    error={this.state.validation.task_name}
                    label={<FormattedMessage id="app.task"/>}
                    className={"modal-popup__input"}
                    fullWidth
                    margin="normal"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="modal-popup__row">
                    <Autocomplete
                      className="filter-client-select"
                      id="filter-client"
                      value={this.state.client}
                      error={this.state.validation.client_name}
                      onChange={(event, newValue) => {
                        this.handleAutocompleteChangeInputClient(newValue, event)
                      }}
                      onInputChange={(event) => event !== null ? this.setState({client_name: event.target.value}) : null}
                      options={this.props.clients}
                      getOptionLabel={(option) => option.first_name}
                      renderInput={(params) => <TextField {...params}
                                                          label={<FormattedMessage id="app.clientName"/>}
                                                          className={"modal-popup__input--name"}

                      />}
                    />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <TextField
                    value={this.state.client_phone}
                    error={this.state.validation.client_phone}
                    onChange={(event) => this.setState({client_phone: event.target.value})}
                    label={<FormattedMessage id="app.clientPhone"/>}
                    className={"modal-popup__input"}
                    fullWidth
                    margin="normal"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <TextField
                    className={"modal-popup__input--date"}
                    type="datetime-local"
                    value={this.state.date}
                    onChange={(event) => this.setState({date: event.target.value})}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="modal-popup__row">
                  <Autocomplete
                    className="filter-client-select"
                    value={this.state.user_name}
                    onChange={(event, newValue) => {
                      this.handleAutocompleteChangeInputMaster(newValue, event)
                    }}
                    options={masters}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params}
                                                        label={<FormattedMessage id="app.responsible"/>}
                                                        className={"modal-popup__input--date"}
                                                        error={this.state.validation.user_name}

                    />}
                  />
                </div>
              </div>
            </div>

            :

            <div className="row">
              <div className="col-md-12">
                <div className="modal-popup__row">
                  <TextField
                    value={this.state.task_name}
                    error={this.state.validation.task_name}
                    onChange={(event) => this.setState({task_name: event.target.value})}
                    label={<FormattedMessage id="app.task"/>}
                    className={"modal-popup__input"}
                    fullWidth
                    margin="normal"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="modal-popup__row">
                  <Autocomplete
                    className="filter-client-select"
                    id="filter-client"
                    // value={this.state.client_name}
                    onChange={(event, newValue) => {
                      this.handleAutocompleteChangeInputClient(newValue, event)
                    }}
                    onInputChange={(event) => this.setState({client_name: event.target.value})}
                    options={this.props.clients}
                    getOptionLabel={(option) => option.first_name}
                    renderInput={(params) => <TextField {...params}
                                                        label={<FormattedMessage id="app.clientName"/>}
                                                        className={"modal-popup__input--name"}
                                                        error={this.state.validation.client_name}

                    />}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <TextField
                    value={this.state.client_phone}
                    error={this.state.validation.client_phone}
                    onChange={(event) => this.setState({client_phone: event.target.value})}
                    label={<FormattedMessage id="app.clientPhone"/>}
                    className={"modal-popup__input"}
                    fullWidth
                    margin="normal"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <TextField
                    className={"modal-popup__input--date"}
                    type="datetime-local"
                    defaultValue={this.state.date}
                    error={this.state.validation.date}
                    onChange={(event) => this.setState({date: event.target.value})}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-popup__row">
                  <Autocomplete
                    className="filter-client-select"
                    id="filter-responsible"
                    onChange={(event, newValue) => {
                      this.handleAutocompleteChangeInputMaster(newValue, event)
                    }}
                    options={masters}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params}
                                                        label={<FormattedMessage id="app.responsible"/>}
                                                        className={"modal-popup__input--date"}
                                                        error={this.state.validation.user_name}

                    />}
                  />
                </div>
              </div>
            </div>
          }
        </div>
        <div className="modal-popup__footer">
          <div className="modal-popup__btn">
            <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={this.handleClose}>
              <FormattedMessage id="app.close"/>
            </Button>
            {this.props.isEdit ?
              <Button variant="contained" color="primary" className={"modal-popup__btn-i"} onClick={this.updateRecord}>
                <FormattedMessage id="app.change"/>
              </Button>
              :
              <Button variant="contained" color="primary" className={"modal-popup__btn-i"} onClick={this.createRecord}>
                <FormattedMessage id="app.save"/>
              </Button>
            }
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    appointment: state.appointment.appointment,
    clients: state.clients.clients,
    masters: state.settings.masters,
  }
}

function mapDispathToProps(dispath) {
  return {
    createAppointment: (data) => dispath(createAppointment(data)),
    fetchClients: () => dispath(fetchClients()),
    fetchMasters: () => dispath(fetchMasters()),
    updateAppointment: (id, data) => dispath(updateAppointment(id, data)),
    // editManager: (data, id) => dispath(editManager(data, id))
  }
}


export default connect(mapStateToProps, mapDispathToProps)(AddRecordingPopup);

